import { FC } from 'react';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import IconButton from '@mui/material/IconButton';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Avatar, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    container: {
        minHeight: '50px',
        justifyContent: 'space-evenly',
        alignContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 10,
        justifyItems: 'center',
        marginTop: 10,
        paddingTop: '10px',
        paddingBottom: '10px',
    },
});

interface InvitationItemProps {
    id?: number;
    active?: boolean;
    fullName?: string;
    description?: string;
    name?: string;
    photo?: string;
    onPress?: Function;
    onClick?: Function;
    firstName?: string;
}

const InvitationItem: FC<InvitationItemProps> = (props) => {
    const { description, name, firstName, active, photo, id, onPress, onClick } = props;
    const classes = useStyles();
    const bgColor = active ? baseColors.green.primary : baseColors.green.light;
    const textColor = active ? baseColors.white : baseColors.green.primary;

    return (
        <Grid
            style={{
                background: bgColor,
                borderColor: bgColor,
            }}
            className={classes.container}
            container
        >
            <Grid item justifyContent={'left'} alignContent={'left'} alignItems={'left'} justifyItems={'left'} flex={1}>
                <Avatar
                    alt="Remy Sharp"
                    style={{ alignSelf: 'center', marginLeft: '18%' }}
                    src={photo ? photo : 'https://i.pravatar.cc/300?img=' + id}
                    sx={{ width: '30px', height: '30px' }}
                />
            </Grid>
            <Grid item style={{ marginLeft: 15 }} flex={4}>
                <Typography
                    fontFamily={baseFontFamily.OpenSansRegular}
                    fontSize={13}
                    color={active ? baseColors.white : baseColors.black}
                >
                    {`${name} ${firstName}`}
                </Typography>
                <Typography fontFamily={baseFontFamily.OpenSansRegular} fontSize={12} color={textColor}>
                    {description}
                </Typography>
            </Grid>
            <Grid item style={{ marginLeft: 1 }} flex={1}>
                <IconButton
                    color="primary"
                    aria-label="upload video"
                    component="span"
                    onClick={() => {
                        onPress && onPress();
                    }}
                >
                    <CheckCircleRoundedIcon style={{ color: baseColors.green.primary }} />
                </IconButton>
            </Grid>
            <Grid item style={{ marginLeft: 1 }} flex={1}>
                <IconButton
                    color="primary"
                    aria-label="upload video"
                    component="span"
                    onClick={() => {
                        onClick && onClick();
                    }}
                >
                    <CancelRoundedIcon style={{ color: 'red' }} />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default InvitationItem;
