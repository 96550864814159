import { Paper } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { InputField } from 'src/components/InputField/InputField';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { ConclusionPayloadType } from 'src/interfaces/types';
import { baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import CarePlanInformations from './CarePlanInformations';
import { DraftConclusion, PreselectedPatientProgram, ProgramSkill } from 'src/interfaces/interfaces';
import { OpenSansRegularText } from 'src/components/Text/OpenSansRegularText';

interface ConclusionFormCardItemProps {
    draftConclusion: DraftConclusion;
    label: string;
    keyValue: string;
    defaultValue: string;
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
    preSelectedPatientProgram: PreselectedPatientProgram;
    payload?: ConclusionPayloadType;
    isCarePlan?: boolean;
    isEditMode?: boolean;
    skills?: ProgramSkill[];
}

const ConclusionFormCardItem: FC<ConclusionFormCardItemProps> = ({
    preSelectedPatientProgram,
    defaultValue,
    label,
    keyValue,
    setPayload,
    payload,
    isCarePlan,
    isEditMode,
    skills,
    draftConclusion,
}) => {
    const [value, setValue] = useState('');
    const handleChange = (keyValue: string) => (newValue: string) => {
        setValue(newValue);
        setPayload((prev: any) => ({ ...prev, [keyValue]: newValue }));
    };

    useEffect(() => {
        defaultValue && setValue(defaultValue);
    }, [defaultValue]);

    const hasCheckedSkills = payload?.skills?.length! > 0;

    return (
        <Paper
            elevation={3}
            style={{
                padding: '10px 30px 0 30px',
                margin: '5px 10px',
            }}
        >
            <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} style={{ fontSize: baseFontSize.sm }}>
                {label}
            </CustomText>

            <Spacer height={10} />

            {isCarePlan && hasCheckedSkills && (
                <>
                    <CarePlanInformations
                        skills={skills}
                        draftConclusion={draftConclusion}
                        payload={payload ?? ({} as ConclusionPayloadType)}
                        preSelectedPatientProgram={preSelectedPatientProgram}
                    />

                    <Spacer height={10} />

                    <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} style={{ fontSize: baseFontSize.sm }}>
                        Informations supplémentaires
                    </CustomText>

                    <Spacer height={10} />
                </>
            )}

            {!isEditMode ? (
                <div style={{ overflowY: 'scroll', maxHeight: 250 }}>
                    <OpenSansRegularText>{defaultValue}</OpenSansRegularText>
                    <Spacer />
                </div>
            ) : (
                <InputField
                    multiline
                    label=""
                    errorMessage="Informations supplémentaires invalide"
                    placeholder="Écrire un texte ..."
                    height={70}
                    width="100%"
                    onChange={handleChange(keyValue)}
                    value={value}
                />
            )}
        </Paper>
    );
};

export default ConclusionFormCardItem;
