import { Typography, Box, Divider, Checkbox } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import CustomEmptyData from 'src/components/CustomEmptyData';
import useFetchUpstairs from 'src/hooks/useFetchUpstairs';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { allOptions, checkValueExistance, handleAddAllValue, handleSearchItem } from '../FilterLogic';
import useStyles from './styles';
import { SearchInputField } from 'src/components/InputField/SearchInputField';

type RoomContentType = {
    selected: number[];
    setSelected: Function;
    selectedItems: number[];
};

const RoomFilterContent: FC<RoomContentType> = ({ selected, setSelected, selectedItems }) => {
    const classes = useStyles();
    const { setCheckedRooms } = useContext(AgendaContext);
    const [filteredItems, setFilteredItems] = useState<Array<any>>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const { handleFecthLocations, customLocations } = useFetchUpstairs();

    const handleClick = (value: string) =>
        setSelected((prev: any) =>
            checkValueExistance({
                prev,
                programId: value,
                setCheckedPrograms: setCheckedRooms,
                allItems: customLocations,
            }),
        );

    useEffect(() => {
        handleFecthLocations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasSearchkey = searchQuery?.trim()?.length > 0;
    const combinedData = hasSearchkey
        ? [allOptions, ...filteredItems]
        : customLocations?.length
        ? [allOptions, ...customLocations]
        : [];

    useEffect(() => {
        if (selected?.length <= selectedItems?.length) {
            handleAddAllValue({ setSelected, selectedItems, combinedData });
        }
    }, [selected]);

    const showSecondTitle = selected?.includes(-1);

    return (
        <>
            <SearchInputField
                onChange={(value: any) => {
                    setSearchQuery(value);
                    handleSearchItem({ items: customLocations, searchkey: value, setItems: setFilteredItems });
                }}
                placeholder="Rechercher par nom"
                value={searchQuery}
            />

            {hasSearchkey && !filteredItems?.length ? (
                <CustomEmptyData labelFontSize={12} marginTop={10} height={40} width={40} />
            ) : (
                customLocations?.length &&
                combinedData?.map((location: any, i: number) => (
                    <Box key={location.id}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Checkbox
                                onClick={() => handleClick(location.id)}
                                sx={{
                                    color: baseColors.green.primary,
                                    '&.Mui-checked': {
                                        color: baseColors.green.primary,
                                    },
                                }}
                                size="small"
                                checked={selected?.length > 0 && selected?.includes(location.id)}
                            />
                            <Typography
                                style={{
                                    fontFamily: baseFontFamily.OpenSansSemiBold,
                                }}
                                fontSize={baseFontSize.mini}
                                color={baseColors.black}
                                marginBottom={1}
                                marginTop={1}
                            >
                                {showSecondTitle && location.id === -1 ? location.secondTitle : location.name}
                            </Typography>
                        </Box>

                        {i === 0 && <Divider className={classes.divider} />}
                    </Box>
                ))
            )}
        </>
    );
};

export default RoomFilterContent;
