import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={41} fill="none" {...props}>
        <rect width={40} height={40} y={0.5} fill="#fff" rx={10} />
        <path
            fill="#0087CB"
            d="M16.813 13.375a.562.562 0 1 0-1.125 0v1.184c-1.08.087-1.789.299-2.31.82-.52.52-.732 1.229-.819 2.309h14.882c-.087-1.08-.299-1.789-.82-2.31-.52-.52-1.229-.732-2.308-.819v-1.184a.562.562 0 1 0-1.125 0v1.135c-.5-.01-1.059-.01-1.688-.01h-3c-.63 0-1.189 0-1.688.01v-1.135Z"
        />
        <path
            fill="#0087CB"
            fillRule="evenodd"
            d="M12.5 20.5c0-.63 0-1.189.01-1.688h14.98c.01.5.01 1.059.01 1.688V22c0 2.828 0 4.243-.879 5.121C25.743 28 24.328 28 21.5 28h-3c-2.828 0-4.243 0-5.121-.879-.879-.878-.879-2.293-.879-5.121v-1.5ZM23.75 22a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 3a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm-3-3.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm0 3a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.25 22a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 3a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
            clipRule="evenodd"
        />
    </svg>
);
export { SvgComponent as CalendarProfilIcon };
