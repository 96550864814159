import { Box } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { CustomCheckBox } from '../InputField/CustomCheckBox';
import { SearchInputField } from '../InputField/SearchInputField';
import { CustomText } from '../Text/CustomText';
import { checkboxLabel, handleSetSelectedItems } from './ListItemFormCheckBoxLogic';
import useStyles from './styles';
import CustomCircleLoader from '../CustomLoader/CustomCircleLoader';
import { useFetchUserPrograms } from 'src/hooks/useFetchUserPrograms';

interface ListItemFormCheckBoxProps {
    items?: Array<any>;
    labelKey?: string;
    searchData?: Function;
    onClick?: Function;
    secondLabelKey?: string;
    loading?: boolean;
    subMenuKey?: string;
    placeholder?: string;
    handleChange?: Function;
    selectedRows?: Set<number>;
    maxItems?: number;
    contentHeight?: number | string;
    handleSearch?: any;
    disableCheckBox?: boolean;
    isPatients?: boolean;
    isIntervenants?: boolean;
    noSearchHeader?: boolean;
    setSelectedIntervenants?: any;
    isForProgram?: boolean;
    minHeight?: number | string;
    filterItems?: { id: number; name: string }[];
    selectedFilterIds?: number[];
    setSelectedFilterIds?: Dispatch<SetStateAction<number[]>>;
    withFilter?: boolean;
}

export const ListItemFormCheckBox: FC<ListItemFormCheckBoxProps> = (props) => {
    const {
        withFilter,
        handleChange,
        items,
        loading,
        placeholder,
        labelKey,
        secondLabelKey,
        subMenuKey,
        selectedRows,
        contentHeight,
        handleSearch,
        disableCheckBox,
        isPatients,
        isIntervenants,
        noSearchHeader,
        setSelectedIntervenants,
        isForProgram,
        minHeight,
        filterItems,
        selectedFilterIds,
        setSelectedFilterIds,
    } = props;

    const classes = useStyles();
    const [selected, setSelected] = useState<any>(selectedRows);
    const [forceRerender, setForceRerender] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');

    const handleSetSelected = () => {
        if (Array.from(selectedRows ?? [])?.length /*!== 0 && Array.from(selected ?? [])?.length === 0*/) {
            setSelected(selectedRows); 
        }
    };

    useEffect(() => {
        handleSetSelected();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);

    const labelValue = (item: any) => {
        if (isIntervenants && isForProgram) {
            return item?.name;
        }

        return checkboxLabel({
            isPatients,
            item,
            labelKey,
            isIntervenants,
            secondLabelKey,
            subMenuKey,
        });
    };

    const { pathologies } = useFetchUserPrograms({});

    const pathologyItems = pathologies ?? [];

    const filterData = filterItems ?? pathologyItems;

    return (
        <Box className={classes.container}>
            {!noSearchHeader && (
                <SearchInputField
                    value={query}
                    placeholder={placeholder ? placeholder : 'Rechercher par nom'}
                    onChange={(value: string) => {
                        setQuery(value);
                        handleSearch?.(value);
                    }}
                    withFilter={withFilter}
                    filterItems={filterData}
                    selectedFilterIds={selectedFilterIds}
                    setSelectedFilterIds={setSelectedFilterIds}
                />
            )}

            <Box sx={{ minHeight: minHeight ?? undefined }}>
                {loading ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 20 }}>
                        <CustomCircleLoader height={30} width={30} />
                    </div>
                ) : items?.length ? (
                    <Box
                        sx={{
                            overflow: 'hidden',
                            overflowY: 'auto',
                            height: contentHeight ? contentHeight : 200,
                            minHeight: minHeight ?? 200,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {items?.map((item: any, i: number) => (
                            <Box key={i} style={{ alignSelf: 'flex-start' }}>
                                <CustomCheckBox
                                    disabled={disableCheckBox}
                                    onClick={handleSetSelectedItems({
                                        selected,
                                        item,
                                        setSelectedIntervenants,
                                        setSelected,
                                        handleChange,
                                        setForceRerender,
                                        forceRerender,
                                        items,
                                    })}
                                    checked={disableCheckBox ? disableCheckBox : selected?.has(item.id)}
                                    label={labelValue(item)}
                                    icon={item?.icon}
                                />
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <CustomText style={{ textAlign: 'center' }}> Aucun contenu trouvé.</CustomText>
                )}
            </Box>
        </Box>
    );
};
