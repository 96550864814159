import EventService from 'src/services/Calendar/events.service';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getAttendeesSpeciality } from 'src/services/Attendees';
import { PatientService } from 'src/services/Patients';
import { getCustomLocationItems } from 'src/services/Rooms';

function useFetchEventTypesRQHooks() {
    return useQuery({
        queryKey: ['event-types'],
        queryFn: async () => await EventService.getEventTypes(),
        onSuccess: () => {},
        onError: () => {},
        select: (response) => {
            const data = response?.data?.data?.items ?? [];
            return data;
        },
    });
}

function useFetchSpecialitiesRQHooks() {
    return useQuery({
        queryKey: ['attendees-specialities'],
        queryFn: async () => await getAttendeesSpeciality(),
        onSuccess: () => {},
        onError: () => {},
        select: (response) => {
            const data = response?.data?.data?.items ?? [];
            return data;
        },
    });
}

function useFetchPatientsInEventRQHooks() {
    return useQuery({
        queryKey: ['patients-in-events'],
        queryFn: async () => await PatientService.getPatients(),
        onSuccess: () => {},
        onError: () => {},
        select: (response) => {
            const data = response?.data?.data ?? [];
            return data;
        },
    });
}

function useFetchEventLocationRQHooks() {
    return useQuery({
        queryKey: ['event-locations'],
        queryFn: async () => await getCustomLocationItems(),
        onSuccess: () => {},
        onError: () => {},
        select: (response) => {
            const data = response?.data?.data ?? [];
            return data;
        },
    });
}

// ******************* PREFETCH *******************
function usePreFetchEventTypesRQHooks() {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery({
        queryKey: ['event-types'],
        queryFn: async () => await EventService.getEventTypes(),
    });
}

function usePreFetchSpecialitiesRQHooks() {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery({
        queryKey: ['attendees-specialities'],
        queryFn: async () => await getAttendeesSpeciality(),
    });
}

function usePreFetchPatientsInEventRQHooks() {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery({
        queryKey: ['patients-in-events'],
        queryFn: async () => await PatientService.getPatients(),
    });
}

function usePreFetchEventLocationRQHooks() {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery({
        queryKey: ['event-locations'],
        queryFn: async () => await getCustomLocationItems(),
    });
}

export {
    useFetchEventTypesRQHooks,
    useFetchSpecialitiesRQHooks,
    useFetchPatientsInEventRQHooks,
    useFetchEventLocationRQHooks,
    usePreFetchEventTypesRQHooks,
    usePreFetchSpecialitiesRQHooks,
    usePreFetchPatientsInEventRQHooks,
    usePreFetchEventLocationRQHooks,
};
