import { useContext, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { ROUTES } from 'src/routes/routes';
import useCheckUserRole from './useCheckUserRole';
import { UserContext } from 'src/providers/UserProvider';
import { SALUTANCE } from 'src/utils/constants/constants';

const useProtectedPageRedirection = () => {
    const { roles } = useCheckUserRole();
    const history = useHistory();
    const { url } = useRouteMatch();
    const { hasEtp } = useContext(UserContext);

    const protectedSettings = [
        ROUTES.users,
        ROUTES.modules,
        ROUTES.SkillSettings,
        ROUTES.GoalSettings,
        ROUTES.workshops,
        ROUTES.generalSettings,
        ROUTES.contactSettings,
        ROUTES.articleSettings,
        ROUTES.quizSettings,
        ROUTES.specialities,
        ROUTES.ThemeSettings,
        ROUTES.pathologySettings
    ];

    const protectedSalutanceSetting = [
        ROUTES.Exports
    ]

    const unAuthorizedPagesByRole = {
        doctor: [...protectedSettings, ROUTES.ETFSettings,...protectedSalutanceSetting],
        doctorNonETP: [ROUTES.patientsAdd],
        admin: [
            ROUTES.modules,
            ROUTES.workshops,
            ROUTES.agendaSettings,
            ROUTES.generalSettings,
            ROUTES.articleSettings,
            ROUTES.quizSettings,
            ROUTES.ThemeSettings,
            ROUTES.ETFSettings,
            ROUTES.programmes,
            ROUTES.specialities,
            ROUTES.pathologySettings,
            ...protectedSalutanceSetting
        ],
        coordination: [],
    };

    const unAuthorizedSettings = (unAuthorizedPage: string[]) => unAuthorizedPage.some((item) => item === url);
    const unAuthorizedAdminPage = url.includes(ROUTES.adminSecurity);
    const unAuthorizedAdminMainMenus =
        [ROUTES.dashboard, ROUTES.agenda, ROUTES.programmes].includes(url) ||
        url.startsWith('/programmes') ||
        url.startsWith('/dahshboard');
    const containsPatientUrl = url.startsWith('/patients');
    const isSalutancePage = protectedSalutanceSetting.includes(url);

    useEffect(() => {
        if (
            roles?.isDoctor &&
            (unAuthorizedSettings(unAuthorizedPagesByRole.doctor) ||
                (!hasEtp && unAuthorizedSettings(unAuthorizedPagesByRole.doctorNonETP)))
        ) {
            history.replace(ROUTES.dashboard);
        }

        if (
            roles?.isAdmin &&
            (unAuthorizedSettings(unAuthorizedPagesByRole.admin) || unAuthorizedAdminMainMenus || containsPatientUrl)
        ) {
            history.replace(ROUTES.settings);
        }

        if ((roles?.isDoctor || roles?.isCoordinator) && unAuthorizedAdminPage) {
            history.replace(ROUTES.dashboard);
        }

        if(roles?.isCoordinator && isSalutancePage && !SALUTANCE){
            history.replace(ROUTES.dashboard);
        }
    }, [roles]);

    return null;
};

export default useProtectedPageRedirection;
