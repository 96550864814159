import { makeStyles } from '@mui/styles';
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { EventPlanningViewType, Patient, Professional } from 'src/interfaces/interfaces';
import { PatientDetailsTab } from './PatientDetailsTab';
import { PatientDetailsHeader } from './PatientsDetailsHeader';
import { Profil } from './Profile/Profile';
import { useHistory } from 'react-router-dom';
import { Programs } from './Programs/Programs';
import PatientPageLoader from 'src/components/PatientPageLoader/PatientPageLoader';
import { Box } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { Observations } from './Observations/Observations';
import PatientAgenda from './PatientAgenda';
import { Evaluations } from './Evaluations/Evaluations';
import { PatientService } from 'src/services/Patients';
import { UserContext } from 'src/providers/UserProvider';
import { baseFontSize } from 'src/utils/constants/baseStyles';
import { KeepCurrentScreenAfterPageRefresh } from '../PatientsUtils';
import { PatientContext } from 'src/providers/PatientProvider';
import Diagnostic from './EducatifDiagnostic/Diagnostic';
import ContactModal from '../ContactModal/ContactModal';
import { useFetchPatientRQHooks } from 'src/hooks/ReactQueryHooks/usePatientRQHooks';

interface PatientDetailsCptsProps {
    patientInfo?: any;
    defaultPatient?: any;
    isActor?: any;
    loading?: any;
    defaultActivePatientId?: number;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setFetchingPatientData: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles({
    container: {
        background: 'white',
        borderRadius: 10,
    },
});

export const PatientDetailsCpts: FC<PatientDetailsCptsProps> = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { activeLoadingIndicator, selectedPatient, setFetchingPatientData, setSelectedPatient } =
        useContext(PatientContext);

    const { defaultPatient, isActor, loading, setLoading, setFetchingPatientData: setFetchingPatientDetails } = props;
    const { isFetching } = useFetchPatientRQHooks({});

    const [openContactModal, setOpenContactModal] = useState(false);
    const { isDoctor } = useContext(UserContext);

    const patientInfo = selectedPatient;

    const [mail, setMail] = useState(patientInfo?.email ?? '');

    const [currentPatientId, setCurrentPatientId] = useState(selectedPatient?.id ?? 0);

    const [doctor, setDoctor] = useState<Professional | null>(null);
    const { path } = useRouteMatch();
    const [userFeched, setUserFeched] = useState<Patient | null>(null);
    const [patientDefaultStatus, setPatientDefaultStatus] = useState<boolean>();

    const patientId = patientInfo?.id ?? 0;
    const [viewType, setViewType] = useState<EventPlanningViewType['viewType']>('');
    const [isFromInfos, setIsFromInfos] = useState<boolean>(false);

    const toggleContactModal = () => setOpenContactModal((prev) => !prev);

    const onContactMedcin = (doc: Professional | null) => {
        setMail(doc?.email ?? '');
        setDoctor(doc);
        if (doc?.email) {
            setOpenContactModal(true);
        }
    };

    useEffect(() => {
        if (isDoctor) {
            const isAcceptedByProfessional =
                isActor.length === 0 ? defaultPatient[0]?.isAcceptedByProfessional : isActor?.isAcceptedByProfessional;
            setPatientDefaultStatus(isAcceptedByProfessional);
        }
    }, [patientId, isActor, isDoctor, defaultPatient]);

    const handleFetchPatientData = async () => {
        setFetchingPatientData(true);
        await PatientService.getPatient(currentPatientId)
            .then((res) => {
                const result = res?.data?.data;
                setSelectedPatient((prev: any) => {
                    if (!prev) {
                        return result;
                    }
                    return prev;
                });
                setUserFeched(result);
                setFetchingPatientData(false);
                setLoading(false);
                setFetchingPatientDetails(false);
            })
            .catch(() => {
                setFetchingPatientData(false);
                setLoading(false);
                setFetchingPatientDetails(false);
            });
    };

    useEffect(() => {
        const getPatientDetails = setTimeout(() => {
            if (currentPatientId > 0) {
                handleFetchPatientData();
            }
        }, 700);

        if (KeepCurrentScreenAfterPageRefresh({ history })) {
            return;
        } else {
            history.push('/patients/list/' + currentPatientId + '/profile');
        }

        return () => clearTimeout(getPatientDetails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPatientId, history]);

    useEffect(() => {
        const patientId = patientInfo?.id ?? 0;
        setCurrentPatientId(patientId);
    }, [patientInfo]);

    if (loading || (isFetching && activeLoadingIndicator)) {
        return <PatientPageLoader />;
    }

    if (!patientInfo) {
        return (
            <Box
                style={{
                    height: `calc(100vh - 90px)`,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                className={classes.container}
            >
                <CustomText style={{ textAlign: 'center', fontSize: baseFontSize.title }}>
                    Aucun patient trouvé
                </CustomText>
            </Box>
        );
    }

    return (
        <Box className={classes.container}>
            <PatientDetailsHeader
                loading={loading}
                patientId={patientId}
                patientInfo={userFeched}
                onContact={() => {
                    setMail(patientInfo?.email ?? '');
                    setDoctor(null);
                    setOpenContactModal(true);
                }}
                defaultPatient={patientDefaultStatus}
                isActor={isActor}
            />

            <PatientDetailsTab />

            <Switch>
                <Route path={`${path}/profile`}>
                    <Profil onContactClick={onContactMedcin} patientInfo={userFeched} />
                </Route>
                <Route path={`${path}/program`}>
                    <Programs defaultPatient={patientDefaultStatus} patientInfo={patientInfo} isActor={isActor} />
                </Route>
                <Route path={`${path}/agenda`}>
                    <PatientAgenda
                        patientId={patientId ?? 0}
                        setViewType={setViewType}
                        viewType={viewType}
                        isFromInfos={isFromInfos}
                        setIsFromInfos={setIsFromInfos}
                        isActor={isActor}
                    />
                </Route>
                <Route path={`${path}/diagnostic`}>
                    <Diagnostic patientInfo={patientInfo} defaultPatient={patientDefaultStatus} isActor={isActor} />
                </Route>
                <Route path={`${path}/observation`}>
                    <Observations
                        patientId={patientInfo?.id ?? 0}
                        defaultPatient={patientDefaultStatus}
                        isActor={isActor}
                    />
                </Route>

                <Route path={`${path}/evaluation`}>
                    <Evaluations defaultPatient={defaultPatient} isActor={isActor} />
                </Route>

                <Route path={`${path}/interview`}>
                    <Evaluations defaultPatient={defaultPatient} isActor={isActor} isInterview={true} />
                </Route>
            </Switch>

            <ContactModal
                doctor={doctor}
                userFeched={userFeched}
                mail={mail}
                openContactModal={openContactModal}
                toggleContactModal={toggleContactModal}
            />
        </Box>
    );
};
