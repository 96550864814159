import * as React from 'react';
import { FC, useState, useContext } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';
import Switch from '@mui/material/Switch';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import { updateETP } from 'src/services/User/user.service';
import { Box, Grid, Collapse, IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { toast } from 'react-toastify';
import { OperationMessage } from 'src/utils/constants/constants';
import { tableCellHeadText, tableCellText } from 'src/views/Security/SecurityManagement/SecurityManagement';
import { InsertDriveFile } from '@mui/icons-material';
import { CustomText } from 'src/components/Text/CustomText';
import { CustomButton } from 'src/components/Button/CustomButton';
import CustomModal from 'src/components/CustomModal';
import { baseFontFamily } from 'src/utils/constants/baseStyles';
import { EtpListContext } from 'src/providers/EtpProvider';

interface UsersTableHeader {
    name: string;
}

interface UsersDataRow {
    id: number;
    firstName: string;
    lastName: string;
    hasEtp: boolean;
    activated: boolean;
    action: any;
}

interface ETFTableActionButtonProps {
    setSelectedUserData: any;
    userData: any;
    setFetchingUsers?: any;
}

export const ETFActionButtons: FC<ETFTableActionButtonProps> = (props) => {
    const { setFetchingUsers, userData } = props;
    const [checked, setChecked] = useState<boolean>(userData?.hasEtp);
    const [modalConfirmationVisible, setModalConfirmationVisible] = useState<boolean>(false);
    const { updateList, setUpdateList } = useContext(EtpListContext);
    const [modalPostTraitementVisible, setModalPostTraitementVisible] = useState(false);
    const toggleModalConfirmation = () => {
        userData?.etpCertificate === null || userData?.etpCertificate.name == null
            ? setModalPostTraitementVisible(!modalPostTraitementVisible)
            : setModalConfirmationVisible(!modalConfirmationVisible);
    };

    const handleChange = () => {
        setFetchingUsers(true);
        updateETP(userData?.id, { hasEtp: !userData?.hasEtp, checkEtp: 1 })
            .then((response) => {
                toast.success(OperationMessage.success);
                setChecked(!userData?.hasEtp);
                let user = userData;
                user.hasEtp = !userData?.hasEtp;
                setFetchingUsers(false);
                setModalConfirmationVisible(false);
                setUpdateList((prev: any) => !prev);
            })
            .catch((error) => {
                toast.error(OperationMessage.error);
                setFetchingUsers(false);
            });
    };

    const backProfil = () => {
        setModalPostTraitementVisible(false);
    };

    const lecteurMessage = `${
        checked
            ? 'Souhaitez-vous désactiver la fonctionnalité ETP pour ce professionnel ?'
            : "Merci de vérifier les certificats des professionnels de santé avant de les activer pour l'ETP"
    }`;

    return (
        <>
            <Switch
                size="small"
                checked={userData?.hasEtp}
                onClick={toggleModalConfirmation}
                sx={{ color: baseColors.green.primary }}
                disabled={userData?.isExternal === 0}
            />

            <CustomConfirm
                modalVisible={modalConfirmationVisible}
                updateModalVisible={setModalConfirmationVisible}
                callBackconfirm={handleChange}
                message={lecteurMessage}
            />

            <CustomModal open={modalPostTraitementVisible} toggleModal={() => setModalPostTraitementVisible(false)}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid
                        container
                        style={{ marginTop: 10, textAlign: 'center' }}
                        justifyContent={'center'}
                        alignContent={'center'}
                        alignItems={'center'}
                        direction={'column'}
                    >
                        <Grid item>
                            <CustomText style={{ marginBottom: 20 }} fontFamily={baseFontFamily.BreeSerif}>
                                Veuillez importer le certificat ETP pour activer ce professionnel de santé.
                            </CustomText>
                        </Grid>
                        <Grid item>
                            <CustomButton
                                width={280}
                                size={'small'}
                                onClick={() => backProfil()}
                                label={'Ok'}
                            ></CustomButton>
                        </Grid>
                    </Grid>
                </div>
            </CustomModal>
        </>
    );
};

interface RowProps {
    row?: any;
    setSelectedUserData?: any;
    userData?: any;
    setFetchingUsers?: any;
    onListUsers?: any;
}

export const Row = (props: RowProps) => {
    const defaultValue: any = {
        hasEtp: false,
        etpCertificateFileContent: '',
        checkEtp: 0,
        deletEtp: 1,
    };
    const [open, setOpen] = React.useState(false);
    const { row, setFetchingUsers, userData, onListUsers } = props;
    const [docName, setDocName] = useState<string>('');
    const [file, setFile] = useState<string>('');
    const [professional, setProfessional] = useState<any>(defaultValue);
    const { updateList, setUpdateList } = useContext(EtpListContext);
    let docType = '';
    const [loadingProfessionalForm, setLoadingProfessionalForm] = useState(false);

    const allowedExtension = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/pdf',
        'application/msword',
        'text/csv',
        'text/txt',
    ];

    const handleChangeETP = () => {
        //setFetchingUsers(true);
        updateETP(row?.id, professional).then((response) => {
            if (response.data?.code === 200) {
                toast.success(OperationMessage.success);
                let user = row;
                row.hasEtp = !row?.hasEtp;
                console.log(user);
                onListUsers();
                setUpdateList((prev: any) => !prev);
            } else {
                toast.error(OperationMessage.error);
                setUpdateList((prev: any) => !prev);
                onListUsers();
            }
        });
    };

    const _handleReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        if (docType.length > 0) {
            let fileContent = `data:${docType};base64,${btoa(binaryString)}`;
            setFile(`data:${docType};base64,${btoa(binaryString)}`);
            setProfessional((old: any) => ({
                ...old,
                filecontent: fileContent,
                etpCertificateFileContent: fileContent,
            }));
        }
    };

    const validPatientETP = () => docName !== '';

    const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.currentTarget.files;
        if (files && files?.length > 0) {
            let file = files[0];
            docType = file?.type;
            setDocName(file.name);

            if (!allowedExtension.includes(file.type)) {
                alert('fichier non supportée , veuillez importer des .word, .jpeg, .jpg, .png, .pdf, .csv, .txt');
                setDocName('');
            } else {
                const reader = new FileReader();
                reader.onload = _handleReaderLoaded;
                reader.readAsBinaryString(file);
            }
        }
    };

    const handleDeleteETP = () => {
        updateETP(row?.id, defaultValue).then((response) => {
            if (response.data?.code === 200) {
                toast.success(OperationMessage.success);
                onListUsers();
                setUpdateList((prev: any) => !prev);
            } else {
                toast.error(OperationMessage.error);
                setUpdateList((prev: any) => !prev);
                onListUsers();
            }
        });
    };

    return (
        <React.Fragment>
            <TableRow
                sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '& .css-1ex1afd-MuiTableCell-root': {
                        padding: '5px 15px 5px 15px',
                    },
                }}
            >
                <TableCell component="th" scope="row">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {tableCellText(row.lastName)}
                </TableCell>
                <TableCell component="th" scope="row">
                    {tableCellText(row.firstName)}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.action}
                </TableCell>
            </TableRow>

            <TableRow key={row?.id}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                {row?.isExternal === 1 ? (
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: '700' }}>Certificat</TableCell>
                                        </TableRow>
                                    </TableHead>
                                ) : (
                                    ''
                                )}
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            {row?.isExternal === 1 &&
                                            (row?.content !== undefined || row?.content !== null) ? (
                                                <div style={{ flexDirection: 'row' }}>
                                                    <a
                                                        href={row?.content}
                                                        download={row?.name}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ paddingRight: 30 }}
                                                    >
                                                        Voir le contenu
                                                    </a>

                                                    <CustomButton
                                                        lower
                                                        width={'20%'}
                                                        disabled={
                                                            row?.content === null || row?.content === undefined
                                                                ? true
                                                                : false
                                                        }
                                                        loading={loadingProfessionalForm}
                                                        onClick={() => handleDeleteETP()}
                                                        label="Rejeter le certificat"
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            {(row?.name === null || row?.content === undefined) &&
                                            row?.isExternal === 1 ? (
                                                <Grid
                                                    lg={12}
                                                    container
                                                    direction="row"
                                                    alignItems={'center'}
                                                    marginBottom={'20px'}
                                                >
                                                    <Grid item>
                                                        <CustomText style={{ minWidth: '300px' }}>
                                                            Importer le fichier :
                                                        </CustomText>
                                                    </Grid>
                                                    <div>
                                                        <input
                                                            accept="*/*"
                                                            id="icon-button-file"
                                                            type="file"
                                                            style={{ display: 'none' }}
                                                            onChange={handleChangeFile}
                                                        />
                                                        <label htmlFor="icon-button-file">
                                                            <IconButton
                                                                color="primary"
                                                                aria-label="upload picture"
                                                                component="span"
                                                            >
                                                                {file?.length !== 0 ? (
                                                                    <CustomText
                                                                        style={{ textAlign: 'center' }}
                                                                        fontSize={11}
                                                                    >
                                                                        {docName === ''
                                                                            ? 'Cliquer pour joindre un fichier'
                                                                            : docName}
                                                                    </CustomText>
                                                                ) : (
                                                                    <InsertDriveFile />
                                                                )}
                                                            </IconButton>
                                                        </label>
                                                    </div>
                                                    <CustomButton
                                                        lower
                                                        width={'20%'}
                                                        disabled={!validPatientETP()}
                                                        loading={loadingProfessionalForm}
                                                        onClick={() => handleChangeETP()}
                                                        label="Uploader le certificat"
                                                    />
                                                </Grid>
                                            ) : (
                                                ''
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

interface ETFTableProps {
    tableHeaders: UsersTableHeader[];
    dataRows: UsersDataRow[];
    handleFetchUsers?: Function;
    onListUsers: Function;
}

export const ETFTable: FC<ETFTableProps> = (props) => {
    const { tableHeaders, dataRows, onListUsers } = props;

    return (
        <TableContainer
            component={Paper}
            style={{ overflow: 'auto', maxHeight: baseScreenHeight.tableContainerHeight }}
        >
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="role table">
                <TableHead>
                    <TableRow>
                        {tableHeaders.map((header, index) => {
                            return (
                                <TableCell sx={{ color: baseColors.green.primary }}>
                                    {tableCellHeadText(header.name)}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody sx={{}}>
                    {dataRows.map((row, index) => (
                        <Row key={index} row={row} onListUsers={onListUsers} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
