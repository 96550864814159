import * as React from 'react';

function CsvIcon(props: any) {
    const {style} = props;
    return (
        <img style={style} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACOElEQVR4nO2XwUtVURDGf5ZRECnVplIhdGHLQIhoZQRRiVCEbWuXS8E2aULtokWLlv0HyYtWrYKiWufKhRs3thJS8gViGR4Z+C4cDje98s659724HwycNzN3znxzZ865D2rUqFEEA0ADaAIukjylxOTXIiZeOolGouRLIxGzbSoh4QKJHc9JnkeIXWjD2PFcahJlEnApSJRNwMUmUQUBF5NEVQRcLBJVEnAR9qsJHBSu3d9A0v0GEnx1tipt8dVZCoFGGyTrWiHQ7HQCLkdWgLvACcltYCnw2QYeAeeAY8BVYNGzDyr+V0/3UbqhlAQs+VM5fidly/xeSH8eGAO6tP4r+0PZn3nPPJFuMiUBq7zhFvBdclO6Cc/P7IbP+n09qLjNlmHUe+aKdG9TErCWQYn7b8XQ4+keSHcf2MyJYyfbYeCo7DZrR6RbT0ngX/q8VuuT71ngNbAT+FyS/QPwXuvLqYe4KIGsylOqsuFxYJ+VfgaY1nouNYG9WqjX030DFrS2E+qMBnnZ8/nkVf2i1l9SE7AjEw3uiuSGdPc8P2ufQ8Cqfl/z2iXz+Q0cB7rla8X5k5rAko7MEKeDt2JtYbgA3FH1bch/BfGyE8wwXjB51wqBrGUmlFCPKu8nb7LlXWQ2AyNqmTDWS2+vVykINA8QtEwpjHedTmC44MXStgSy/wTzwEanEshD9ID7wNUEAtRvoOqCNSsc4J8xCFR5P7yJQaCq++EH0E8klHk/bKjy0ZKvUeN/xi5I9qArpGwfowAAAABJRU5ErkJggg=="></img>
    );
}

export default CsvIcon;
