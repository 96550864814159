import { FC } from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useStyles from './styles';
import InvitationItem from './InvationItem';
import { useState, useContext } from 'react';
import { useEffect } from 'react';
import { UserContext } from 'src/providers/UserProvider';
import { getinvitationByProfessional, postAgreementByProfessional } from 'src/services/Patients';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import { baseColors } from 'src/utils/constants/baseStyles';
import { CustomText } from 'src/components/Text/CustomText';

const InvitationList: FC = (props) => {
    const classes = useStyles();
    const { userInfos: userInfosFromContext } = useContext(UserContext);
    const [field, setField] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const professionalId = userInfosFromContext?.id;
    const [modalConfirmationVisible, setModalConfirmationVisible] = useState<boolean>(false);
    const [modalConfirmationVisibleDelete, setModalConfirmationVisibleDelete] = useState<boolean>(false);
    const toggleModalConfirmation = () => setModalConfirmationVisible(!modalConfirmationVisible);
    const toggleModalConfirmationRemove = () => setModalConfirmationVisibleDelete(!modalConfirmationVisibleDelete);
    const [guestInvitation, setGuestInvitation] = useState('');

    useEffect(() => {
        fetchInvitationPatient();
    }, []);

    const acceptItem = (data: any) => {
        setModalConfirmationVisible(false);
        const listData = [...field];
        let idx = listData.indexOf(data);
        const patientId = data?.patient?.id;
        const acceptCoactor = 1;
        postAgreementByProfessional({ patientId, acceptCoactor }).then((res) => {
            if (res.data.code === 200) {
                listData.splice(idx, 1);
                setField(listData);
            } else {
                if (res.data.code === 400) {
                    alert('Vous avez atteint le nombre maximum');
                }
            }
        });
    };

    const removeItem = (data: any) => {
        setModalConfirmationVisibleDelete(false);
        let listData = [...field];
        let idx = listData.indexOf(data);
        const patientId = data?.patient?.id;
        const acceptCoactor = 0;
        postAgreementByProfessional({ patientId, acceptCoactor }).then((res) => {
            if (res.data.code === 200) {
                listData.splice(idx, 1);
                setField(listData);
            } else {
                if (res.data.code === 400) {
                    alert('Vous avez atteint le nombre maximum');
                }
            }
        });
    };

    const fetchInvitationPatient = () => {
        setLoading(true);
        getinvitationByProfessional(professionalId)
            .then((reponse) => {
                const patientData = reponse.data.data;
                if (field) {
                    setField(patientData);
                }
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    return (
        <div>
            <Accordion>
                <AccordionSummary
                    style={{ backgroundColor: baseColors.orange.light }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography fontSize={14} className={classes.title}>
                        Invitation des patients
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    {field.length ? (
                        field.map((item) => (
                            <div key={item?.patient?.id}>
                                <InvitationItem
                                    onPress={() => {
                                        toggleModalConfirmation();
                                        setGuestInvitation(item);
                                    }}
                                    onClick={() => {
                                        toggleModalConfirmationRemove();
                                        setGuestInvitation(item);
                                    }}
                                    name={item?.patient?.lastName}
                                    firstName={item?.patient?.firstName}
                                    id={item?.patient?.id}
                                />
                            </div>
                        ))
                    ) : (
                        <CustomText style={{ textAlign: 'center' }}>Aucune invitation présente</CustomText>
                    )}

                    <CustomConfirm
                        modalVisible={modalConfirmationVisible}
                        updateModalVisible={setModalConfirmationVisible}
                        callBackconfirm={() => acceptItem(guestInvitation)}
                        message="Voulez-vous vraiment accepter de devenir Co-Acteur ?"
                    />

                    <CustomConfirm
                        modalVisible={modalConfirmationVisibleDelete}
                        updateModalVisible={setModalConfirmationVisibleDelete}
                        callBackconfirm={() => removeItem(guestInvitation)}
                        message="Voulez-vous vraiment refuser de devenir Co-Acteur ?"
                    />
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default InvitationList;
