import { Grid } from '@mui/material';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { InputField } from 'src/components/InputField/InputField';
import { ProfessionalCpts } from 'src/interfaces/interfaces';
import { isNotBlank } from 'src/utils/helpers/InputValidator';

interface RegistrationInputProps {
    label: string;
    placeholder: string;
    keyValue: string;
    regexLabel: string;
    lockProfessionalInput?: boolean;
    validator?: Function;
    setProfessional: Dispatch<SetStateAction<ProfessionalCpts>>;
    maxLength?: number;
}

const RegistrationInput: FC<RegistrationInputProps> = ({
    label,
    regexLabel,
    keyValue,
    placeholder,
    setProfessional,
    lockProfessionalInput,
    validator,
    maxLength,
}) => {
    const [value, setValue] = useState('');
    const handleChange = (text: string) => {
        const stringValue = text === 'NaN' ? '0' : text;
        setValue(stringValue);
        setProfessional((prev) => ({ ...prev, [keyValue]: stringValue }));
    };

    return (
        <Grid item flexGrow={1} justifyContent="center" style={{ paddingLeft: '3%' }}>
            <InputField
                value={value}
                width="97%"
                maxLength={maxLength ?? undefined}
                placeholder={placeholder}
                label={label + ' :'}
                regexLabel={regexLabel ?? undefined}
                disabled={lockProfessionalInput}
                onChange={handleChange}
                validator={validator ?? isNotBlank}
            />
        </Grid>
    );
};

export default RegistrationInput;
