import { FC } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { UserAxisItem } from 'src/interfaces/interfaces';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

interface CheckboxAnswerContentProps {
    label: string;
    content: UserAxisItem;
}

const CheckboxAnswerContent: FC<CheckboxAnswerContentProps> = ({ label, content }) => {
    const responses = content?.survey?.responseItems;
    const noResponse = responses?.length === 0 || !responses;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <CustomText
                style={{ color: baseColors.green.primary, width: 80 }}
                fontSize={14}
                fontFamily={baseFontFamily.OpenSansSemiBold}
            >
                {label} :
            </CustomText>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-around',
                    paddingTop: 12,
                }}
            >
                {noResponse ? (
                    <CustomText
                        style={{ marginLeft: 10, marginBottom: 10, fontStyle: 'italic' }}
                        fontSize={12}
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                        color="#15254599"
                    >
                        (Pas de réponse)
                    </CustomText>
                ) : (
                    responses?.map((item: any) => (
                        <CustomText
                            key={item.id}
                            style={{ marginLeft: 10, marginBottom: 10 }}
                            fontSize={12}
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                            color="#15254599"
                        >
                            ✔ {item?.nameProposal ?? '---'}
                        </CustomText>
                    ))
                )}
            </div>
        </div>
    );
};

export default CheckboxAnswerContent;
