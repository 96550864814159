import { Typography,Grid, Box, Stack, Button } from "@mui/material"
import { FC, useState } from "react"
import CustomLoadingButton from "src/components/Button/CustomLoadingButton"
import IconCheckList from "src/components/CustomIcon/IconCheckList"
import { OpenSansBoldText } from "src/components/Text/OpenSansBoldText"
import { OpenSansSemiBold } from "src/components/Text/OpenSansSemiBold"
import { baseColors } from "src/utils/constants/baseStyles"
import { makeStyles } from '@mui/styles';
import { exportUserSurveyResponse } from "src/services/Patients"
import CsvIcon from "src/components/CustomIcon/CsvIcon"
import { transformDbDateTimeLocal } from "src/utils/utils"
import { toast } from "react-toastify"

const useStyles = makeStyles({
    container: {
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 15px 5px 15px',
        marginBottom: 10,
    },
    left: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }
});

const SurveyResponse : FC = () => {
    const [loading,setLoading] = useState<boolean>(false);
    const classes = useStyles();
    const backgroundColor = baseColors.green.light;
    const color = baseColors.green.primary;
    const iconStyle = {
        backgroundColor: color, 
        padding: '7px', 
        borderRadius: 10, 
        color: baseColors.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const stringToCsv = (data: any, filename: string) => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const exportToCSV = () => {
        setLoading(true);
        exportUserSurveyResponse().then((res: any)=>{
            stringToCsv(res.data,"export_"+transformDbDateTimeLocal(new Date()))
            setLoading(false);
            toast.success('Exportation réussie en CSV');
        }).catch((err)=>{
            toast.error('Erreur dans l\'exportation des réponses de questionnaire');
            setLoading(false);
            console.log(err)
        });
    }

    return (
        <>
        <Box
            style={{ backgroundColor: backgroundColor }}
            className={classes.container}
            sx={{ alignItems: 'center' }}
            flexDirection={{ xs: 'column', lg: 'row' }}
        >
        <Box className={classes.left}>
            <Box sx={iconStyle}>
                <CsvIcon style={{filter: 'invert(100%)'}}/>
            </Box>
            <OpenSansSemiBold style={{ marginLeft: 20 }}>
                Exporter les questions et réponses de tous les utilisateurs en CSV
            </OpenSansSemiBold>
        </Box>

        <Stack spacing={5} direction={{ xs: 'column', sm: 'row' }}>
            <CustomLoadingButton
                handleClick={exportToCSV}
                size="large"
                disabled={loading}
                loading={loading}
                style={{
                    backgroundColor: color,
                    color: baseColors.white,
                }}
                label='Exporter en CSV'
            />
        </Stack>
        </Box>
        </>
    )
}

export default SurveyResponse