import { Grid } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { UserAxisItem } from "src/interfaces/interfaces"
import { baseColors, baseFontSize, baseFontWeight } from "src/utils/constants/baseStyles"
import CustomSlider from "../CustomSlider/Slider"
import QuestionPrecision from "./QuestionPrecision"

interface QuestionSliderProps {
    userDEResponse: UserAxisItem;
    responseValue: any;
    precisionAnswer: string | number | undefined;
    setPayload : Function;
    questionTitle: JSX.Element;
    index: number;
}

const QuestionSlider : FC<QuestionSliderProps> = ({userDEResponse, responseValue, precisionAnswer, setPayload, questionTitle, index}) => {
    const hasPrécision = userDEResponse.survey.hasPrecision === 1;


    const [activeMark, setActiveMark] = useState<number>(0);

    useEffect(() => {
        setActiveMark(responseValue)
    },[responseValue])

    const marks = [
        {label : '0', value: 0},
        {label: activeMark?.toString(), value: activeMark },
        {label : '10', value: 10}
    ]

    const updatePayload = (value: number | string) => {
        setPayload((prev: any) => {
            const updatedPayload = { ...prev };
            if (updatedPayload.responses && updatedPayload.responses.length > index) {
                updatedPayload.responses[index].responseValue = value;
            }
        
            return updatedPayload; // Return the updated state
        });
    }

    const handleChange = (event: Event, newValue: number | number[]) => {
        const val = Array.isArray(newValue) && newValue.length > 0 ? newValue[0] : 0;
        updatePayload(val);
        setActiveMark(newValue as number);
    };

    //const valuetext = (value: number) => value
    const Footer = <div style={{position: 'relative',marginBottom: '20px'}}>
                        <div style={{position: 'absolute', width: '100px', left: '-20%', fontSize: baseFontSize.mini, color:baseColors.orange.secondary}}>Pas du tout</div>
                        <div style={{position: 'absolute', width: '100px', right: '-20%', fontSize: baseFontSize.mini, color:baseColors.orange.secondary}}>Le plus que vous puissiez imaginer</div>
                    </div>
    return (
        <Grid
            container
            direction="column"
            alignItems={'start'}
            justifyContent="start"
            >
            <Grid item>
                {questionTitle}
            </Grid>
            <Grid style={{ display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection:'row', marginTop: '15px', marginLeft: '50px' }} item>
                <CustomSlider
                    width={'300px'}
                    //getAriaValueText= {valuetext}
                    marks= {marks}
                    onChange={handleChange}
                    valueLabelDisplay="off"
                    value={[responseValue]}
                    min={0}
                    max={10}
                    step={1}
                    defaultValue={responseValue}
                    sx={sx}
                    footer={Footer}
                />
            </Grid>
            {hasPrécision &&
                <Grid item style={{marginTop:30}}>
                    <QuestionPrecision
                        precisionAnswer={precisionAnswer}
                        index={index} 
                        setPayload={setPayload} 
                        userDEResponse={userDEResponse}
                    />
                </Grid>
            }
        </Grid>
    )
}

const sx = {
    color: baseColors.green.primary,
    baseBorderRadius: '100%',
    '& .MuiSlider-thumb': {
        //color: baseColors.green.primary,
        width: 20,
        height: 20,
        color: 'white',
        border: '5px solid '+baseColors.green.primary, // Color of the thumb
    },
    '& .MuiSlider-mark': {
        backgroundColor: baseColors.green.primary, // Color of the marks
        height: 0,
        width: 0,
        //baseBorderRadius: '100%'
    },
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
    },
    '& .MuiSlider-markLabel': { 
        top: '25px', // Position of the label relative to the slider
        whiteSpace: 'break-spaces',
        display: 'inline-block',
        width: 'auto',
        fontSize: '13px',
        fontWeight: 'bold',
        textAlign: 'center'
    }
}

export default QuestionSlider