import { FC, useContext, useEffect, useState } from 'react';
import useStyles from './styles';
import { Box, Grid } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { Spacer } from 'src/components/Spacer/Spacer';
import { MultiChoice } from 'src/components/Mulitchoice/MultiChoice';
import CustomDatePicker from 'src/components/CustomDatePicker';
import { ProgramContext } from 'src/providers/ProgramProvider';
import ProgramService from 'src/services/Program/program.service';
import { toast } from 'react-toastify';
import { ServerError } from 'src/utils/constants/constants';
import { MultiChoicePathology } from 'src/components/Mulitchoice/MultiChoicePathology';
import { useFetchUserPrograms } from 'src/hooks/useFetchUserPrograms';

interface ProgramFilterProps {}

export const ProgramFilter: FC<ProgramFilterProps> = (props) => {
    const classes = useStyles();
    const {
        programState,
        programLevel,
        setFilter,
        filter,
        filteredProgramList,
        setLoadingProgramList,
        loadingProgramList,
        setFilteredProgramList,
        setProgramId,
    } = useContext(ProgramContext);
    const { pathologies, fetchingPathologies } = useFetchUserPrograms({});
    const [rerender, setRerender] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(filter.date.start);
    const [endDate, setEndDate] = useState<Date | null>(filter.date.end);
    const sortedProgramLevel = programLevel.sort((a, b) => {
        // Compare the values of the elements in lowercase
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
        }
        return 0;
    });
    const onChangeLevel = (values: any[]) => {
        let oldFilter = filter;
        oldFilter.level = values;
        setFilter(oldFilter);
        getFiltered();
        setProgramId(-1);
    };

    const onChangeType = (values: any[]) => {
        let oldFilter = filter;
        oldFilter.type = values;
        setFilter(oldFilter);
        getFiltered();
        setProgramId(-1);
    };

    const onChangeState = (value: any) => {
        let oldFilter = filter;
        oldFilter.state = value;
        setFilter(oldFilter);
        getFiltered();
        setProgramId(-1);
    };

    const handleStartDateChange = (date: Date | null) => {
        setStartDate(date);
        let oldFilter = filter;
        oldFilter.date.start = date;
        if (filter.date.end && date && filter.date.end.getTime() < date.getTime()) {
            setStartDate(null);
            setEndDate(null);
            oldFilter.date.start = null;
            oldFilter.date.end = null;
            setFilter(oldFilter);
            toast.error('La date de début ne peut pas être supérieure à la date de fin.');
        } else {
            setFilter(oldFilter);

            getFiltered();
            setProgramId(-1);
        }
    };

    const handleEndDateChange = (date: Date | null) => {
        let oldFilter = filter;
        if (filter.date.start && date && filter.date.start > date) {
            toast.error('La date de début ne peut pas être supérieure à la date de fin');
            return;
        }
        oldFilter.date.end = date;
        setFilter(oldFilter);
        getFiltered();
        setProgramId(-1);
    };

    useEffect(() => {
        setRerender(!rerender);
    }, [filteredProgramList]);

    useEffect(() => {
        return () => {
            setFilter({ type: [], level: [], state: 0, date: { start: null, end: null } });
        };
    }, []);

    const getFiltered = () => {
        setLoadingProgramList(true);
        ProgramService.getProgramsFiltered(filter)
            .then((response) => {
                const programs = response?.data?.data;
                if (programs) {
                    setFilteredProgramList(programs.items);
                }
                setLoadingProgramList(false);
            })
            .catch((e) => {
                toast.error(ServerError.unknown);
                setLoadingProgramList(false);
            });
    };

    return (
        <Box className={classes.container}>
            <Grid style={{ padding: 30, overflowY: 'auto' }}>
                <CustomText isBreefSerif isTitle>
                    Filtres
                </CustomText>

                <Spacer height={20} />
                {
                    <>
                        <MultiChoicePathology
                            disabled={loadingProgramList}
                            onChange={onChangeType}
                            title="Pathologie : "
                            choices={pathologies}
                            type="checkbox"
                            forceRerender={rerender}
                            loading={fetchingPathologies}
                        />

                        <Spacer height={10} />

                        <MultiChoice
                            disabled={loadingProgramList}
                            onChange={onChangeLevel}
                            title="Avancement : "
                            choices={sortedProgramLevel}
                            type="checkbox"
                            forceRerender={rerender}
                        />

                        <Spacer height={10} />

                        <MultiChoice
                            defaultSelected={filter.state}
                            disabled={loadingProgramList}
                            onChange={onChangeState}
                            title={' Etat : '}
                            choices={programState}
                            type={'radio'}
                        />

                        <Spacer height={10} />

                        <CustomText
                            fontSize={baseFontSize.md}
                            fontFamily={baseFontFamily.BreeSerif}
                            color={baseColors.black}
                        >
                            Date du Programme :
                        </CustomText>

                        <Spacer height={15} />

                        <div className={classes.dateContainer}>
                            <div style={{ minWidth: 50, marginRight: 10 }}>
                                <CustomText
                                    fontSize={baseFontSize.mini}
                                    fontFamily={baseFontFamily.OpenSansSemiBold}
                                    color={baseColors.black}
                                >
                                    Début :
                                </CustomText>
                            </div>

                            <CustomDatePicker
                                width={180}
                                disabled={loadingProgramList}
                                validator={true}
                                likeSelect
                                setValue={handleStartDateChange}
                                value={filter.date.start}
                            />
                        </div>

                        <div className={classes.dateContainer}>
                            <div style={{ minWidth: 50, marginRight: 10 }}>
                                <CustomText
                                    fontSize={baseFontSize.mini}
                                    fontFamily={baseFontFamily.OpenSansSemiBold}
                                    color={baseColors.black}
                                >
                                    Fin :
                                </CustomText>
                            </div>

                            <CustomDatePicker
                                width={180}
                                disabled={loadingProgramList}
                                validator={true}
                                likeSelect
                                setValue={handleEndDateChange}
                                value={filter.date.end}
                            />
                        </div>
                    </>
                }
            </Grid>
        </Box>
    );
};
