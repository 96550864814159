import { baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';

const sxStyles: any = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        paddingX: '1rem',
        paddingTop: 2,
        ...baseScreenHeight.defaultHeight,
    },
    content: {
        height: '50px',
        marginTop: '25px',
        marginBottom: '15px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
    },
    loadingLabel: { color: baseColors.green.primary, fontSize: '12pt', marginTop: 5 },
};

export { sxStyles };
