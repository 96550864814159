import { useContext, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { baseColors, baseFontFamily, baseFontSize } from '../../utils/constants/baseStyles';
import { InputFieldPassword } from '../../components/InputField/InputFieldPassword';
import { loginCheck } from '../../services/Login';
import { useHistory } from 'react-router';
import { ActionType } from '../../reducer/Auth/Actions';
import { loadState } from '../../reducer/Auth/AuthReducer';
import { useAppDispatch } from '../../reducer/hooks';
import TokenService from 'src/services/Token.service';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/routes/routes';
import { InputField } from 'src/components/InputField/InputField';
import useKeyboardHandler from 'src/hooks/useKeyboardHandler';
import { Spacer } from 'src/components/Spacer/Spacer';
import IvehteCptsLogo from 'src/components/IvehteCptsLogo/IvehteCptsLogo';
import { URER_ROLES } from 'src/utils/constants/enums';
import jwtDecode from 'jwt-decode';
import { PlateformTypeContext } from 'src/providers/PlateformTypeProvider';
import { CustomText } from 'src/components/Text/CustomText';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import UserService from 'src/services/User/user.service';
import { toast } from 'react-toastify';

const defaultValue = {
    password: '', //azerty123
    username: '', //rafalimananahs@gmail.com
    showPassword: false,
};

export const Login = () => {
    const dispatch = useAppDispatch();
    const isLogged = loadState()?.isLogged;
    const history = useHistory();
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [checkLoading, setCheckLoading] = useState<boolean>(false);
    const [values, setValues] = useState(defaultValue);
    const { setIsCoordonatorFromPlateform } = useContext(PlateformTypeContext);

    const performLogin = async () => {
        setHasError(false);
        setCheckLoading(true);
        loginCheck(values.username, values.password)
            .then((response) => {
                setHasError(false);
                dispatch({
                    type: ActionType.LOGIN,
                    payload: response.data.token,
                });

                TokenService.setUser(response.data);

                const decode: {
                    roles: string[];
                } = jwtDecode(response?.data?.token);

                const userRole = decode?.roles?.filter((role: string) => role !== URER_ROLES.USER);

                sessionStorage.setItem('user-role', userRole?.[0] || '');

                if (userRole?.[0] === URER_ROLES.COORDINATOR) {
                    setIsCoordonatorFromPlateform(true);
                }

                UserService.connectedUser().then((res: any) => {
                    res?.data?.data?.isFirstLogin && toast.warning('Veuillez modifier votre mot de passe par défaut');
                    setCheckLoading(false);
                    history.push('/loadingInitialPage');
                });
            })
            .catch((err) => {
                setHasError(true);
                setCheckLoading(false);
                if (err?.response?.status === 403) {
                    setErrorMsg('Ce compte est désactivé');
                } else {
                    setErrorMsg(`Mot de passe ou nom d'utilisateur incorrect`);
                }
            });
    };

    const disableBtn = values?.username?.trim()?.length < 11 || values?.password?.trim()?.length < 8;

    useKeyboardHandler({ handler: !disableBtn ? performLogin : undefined });

    if (isLogged && !checkLoading) {
        history.push('/loadingInitialPage');
    }

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" style={{ paddingTop: 0 }}>
            <IvehteCptsLogo useAuthenticationLogo width={300} height={300} />

            <Typography
                style={{ fontSize: '22px', marginTop: -5, marginBottom: 20, fontFamily: baseFontFamily.BreeSerif }}
                color={baseColors.green.primary}
            >
                Application d'éducation thérapeutique
            </Typography>

            <Spacer height={5} />

            <InputField
                width={360}
                onChange={(value: any) => setValues((old) => ({ ...old, username: value }))}
                value={values?.username}
                label="Numéro RPPS"
                placeholder="Entrer votre numéro RPPS"
            />

            <Spacer height={5} />

            <InputFieldPassword
                width={360}
                type="password"
                onChange={(value: any) => setValues((old) => ({ ...old, password: value }))}
                label="Mot de passe"
                value={values?.password}
                placeholder="Minimum 8 caractères"
            />

            {hasError && (
                <CustomText style={{ fontSize: baseFontSize.sm }} color={baseColors.orange.primary}>
                    {errorMsg}
                </CustomText>
            )}

            <Spacer />

            <CustomLoadingButton
                disabled={disableBtn}
                loading={checkLoading}
                handleClick={performLogin}
                width={360}
                label="Se connecter"
            />

            <Spacer />

            <Link to={ROUTES.passwordForgot} style={{ textDecoration: 'none' }}>
                <CustomText
                    style={{ fontSize: baseFontSize.sm, marginBottom: 10, textDecoration: 'none' }}
                    color={baseColors.green.primary}
                >
                    Mot de passe oublié
                </CustomText>
            </Link>

            <CustomText style={{ fontSize: baseFontSize.sm }} color={baseColors.green.primary}>
                Pas encore de compte ?
            </CustomText>

            <Link to={ROUTES.signInCpts} style={{ textDecoration: 'none', marginTop: 10 }}>
                <CustomText
                    style={{
                        fontSize: baseFontSize.sm,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    }}
                    color={baseColors.green.primary}
                >
                    Inscrivez-vous !
                </CustomText>
            </Link>
        </Grid>
    );
};
