import React, { FC, useContext, useEffect, useState } from 'react';
import useStyles from './styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import NotificationActif from 'src/components/CustomIcon/NotificationActif';
import Separator from 'src/components/CustomIcon/Separator';
import { Avatar } from '@mui/material';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ROUTES } from 'src/routes/routes';
import { LogoutOutlined } from '@mui/icons-material';
import { useAppDispatch } from 'src/reducer/hooks';
import { ActionType } from 'src/reducer/Auth/Actions';
import NotificationInactif from 'src/components/CustomIcon/NotificationInactif';
import TokenService from 'src/services/Token.service';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveConnectedUserInfos } from 'src/actions/user/user';
import { fullName } from 'src/utils/utils';
import CustomCircleLoader from '../CustomLoader/CustomCircleLoader';
import { initUser, UserContext } from 'src/providers/UserProvider';
import { RootState } from 'src/stores/store';
import NotificationService from 'src/services/Notifications';
import { retrieveNotifications } from 'src/actions/notification/notification';
import FirebaseNotification from 'src/views/PushNotification/FirebaseNotification';
import { SoignantDetail } from './Soigant/SoignantDetail';
import { retrieveAuthorizedContents } from 'src/actions/authorization/authorization';
import { PermissionContext } from 'src/providers/PermissionProvider';
import { getProfilePicture } from 'src/services/Patients';
import { logout } from 'src/services/Login';
import IvehteCptsLogo from '../IvehteCptsLogo/IvehteCptsLogo';
import { CustomConfirm } from '../CustomConfirm/CustomConfirm';
import { useIdleTimer } from 'react-idle-timer';
import { isNumber } from 'src/utils/helpers/InputValidator';

const AppBarHeader: FC<RouteComponentProps> = (props) => {
    const EXPIRATION_TOKEN: number =
        process.env.REACT_APP_EXPIRATION_TOKEN && isNumber(process.env.REACT_APP_EXPIRATION_TOKEN)
            ? parseInt(process.env.REACT_APP_EXPIRATION_TOKEN)
            : 1_000 * 60 * 10; // 10 minutes idle

    const { history } = props;
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const reduxDispatch = useDispatch();
    const { userInfos: userInfosFromContext, setUserInfos } = useContext(UserContext);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [loadingUserInfos, setLoadingUserInfos] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const [disconnecting, setDisconnecting] = useState<boolean>(false);
    const handleFetchUserInfos = () => reduxDispatch(retrieveConnectedUserInfos(setLoadingUserInfos, setUserInfos));
    const hasNonFocusedNotifications = useSelector(
        (state: RootState) => state.notifications.hasNonFocusedNotifications,
    );
    const [profIcon, setProfIcon] = useState<string>();
    const notificationIds = useSelector((state: RootState) => state.notifications.notificationIds);
    const { setPermissions } = useContext(PermissionContext);
    const [update, setUpdate] = useState<boolean>(false);

    const [remaining, setRemaining] = useState<number>(0);

    const onIdle = () => {
        performLogout();
    };

    const onActive = () => {};

    const onAction = () => {};

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout: EXPIRATION_TOKEN, // 15 minutes inactivity
        throttle: 500,
        crossTab: true,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    });

    useEffect(() => {
        reduxDispatch(retrieveAuthorizedContents(null, () => {}, setPermissions));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const userId = userInfosFromContext?.id || 0;

    useEffect(() => {
        handleFetchUserInfos();
        if (+userId > 0) {
            getProfilePicture(+userId).then((res) => {
                const profilData = res?.data?.data;
                setProfIcon(profilData?.[0]?.imageurl ?? '');
            });
        }
    }, [userId, update]);

    const performLogout = () => {
        setDisconnecting(true);
        logout()
            .then((res) => {
                if (res) {
                    setUserInfos(initUser);
                    setShowConfirm(false);
                    dispatch({
                        type: ActionType.LOGOUT,
                    });
                    TokenService.removeUser();
                    sessionStorage.clear();
                    history.push(ROUTES.login);
                }
                setDisconnecting(false);
            })
            .catch((e) => {
                setDisconnecting(false);
            });
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClickNotification = () => {
        NotificationService.updateNotificationsToFocused({ notificationIds: notificationIds })
            .then((response) => {
                dispatch(retrieveNotifications());
            })
            .catch((reason) => {
                console.error('Could not update notifications to focused', reason);
            });
        history.push(ROUTES.notifications);
    };

    const userInfos = useSelector((state: any) => state.user.data);
    const firstName = userInfosFromContext?.firstName ?? userInfos?.firstName;
    const lastName = userInfosFromContext?.lastName ?? userInfos?.lastName;

    return (
        <AppBar position="fixed" style={styles.appBar}>
            <SoignantDetail open={open} setOpen={setOpen} setUpdate={setUpdate} />

            <CustomConfirm
                title="Déconnexion"
                defaultLoading={disconnecting}
                modalVisible={showConfirm}
                updateModalVisible={setShowConfirm}
                message={disconnecting ? 'Déconnexion en cours...' : 'Etes-vous sûr de vouloir vous déconnecter ?'}
                callBackconfirm={performLogout}
                disable={disconnecting}
            />

            <Toolbar className={classes.flexContentSpaceBetween}>
                <Box style={{ cursor: 'pointer' }} onClick={() => history.push('/')}>
                    <IvehteCptsLogo />
                </Box>
                <FirebaseNotification />
                <Box style={{ alignItems: 'center' }} className={classes.flexContentAround}>
                    <IconButton onClick={handleClickNotification} size="large" color="inherit">
                        {!hasNonFocusedNotifications ? <NotificationInactif /> : <NotificationActif />}
                    </IconButton>
                    <Separator />
                    {loadingUserInfos && userId === 0 ? (
                        <Box paddingLeft={5} paddingRight={5}>
                            <CustomCircleLoader height={25} width={25} />
                        </Box>
                    ) : (
                        <>
                            <IconButton size="large" onClick={handleProfileMenuOpen} color="inherit">
                                <Avatar variant="circular" alt="Remy Sharp" src={profIcon} />
                            </IconButton>
                            <Typography
                                style={{ fontSize: baseFontSize.sl, fontFamily: baseFontFamily.OpenSansSemiBold }}
                                className={classes.userName}
                            >
                                {fullName(firstName, lastName.toUpperCase()) ?? ''}
                            </Typography>
                        </>
                    )}

                    <IconButton style={styles.logout} onClick={() => setShowConfirm(true)}>
                        <LogoutOutlined />
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

const styles = {
    appBar: {
        backgroundColor: baseColors.white,
        paddingLeft: 15,
        paddingRight: 5,
    },
    logout: {
        marginLeft: 20,
    },
};

export default withRouter(AppBarHeader);
