import { Box } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import UsersPanel from '../../Settings/Users/List/UsersPanel';
import { ModulesPanel } from '../Modules/ModulesPanel';
import { WorkshopsPanel } from '../Workshops/WorkshopsPanel';
import { PathologyPanel } from '../Pathology/PathologyPanel';
import styles from './styles';
import AgendaSettings from '../AgendaSettings/AgendaSettings';
import GeneralSettings from '../GeneralSettings/GeneralSettings';
import ContactSettings from '../ContactSettings/List/ContactPanel';
import ArticlePanel from '../ArticleSettings/List/ArticlePanel';
import QuizTabs from '../QuizSettings/QuizTabs/QuizTabs';
import { MenuAuthorizationContext } from 'src/providers/MenuAuthorizationProvider';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { ROUTES } from 'src/routes/routes';
import { useLocation } from 'react-router';
import useCheckUserRole from 'src/hooks/useCheckUserRole';
import { URER_ROLES } from 'src/utils/constants/enums';
import ETFList from '../ETFSettings/ETFList';
import UserTheme from '../Theme/UserTheme';
import SettingsSubMenuBar from 'src/components/SettingsSubMenuBar/SettingsSubMenuBar';
import useProtectedPageRedirection from 'src/hooks/useProtectedPageRedirection';
import { GoalsPanel } from '../Goals/GoalsPanel';
import { SkillsPanel } from '../Skills/SkillsPanel';
import { SpecialityPanel } from '../Specialities/SpecialityPanel';
import ExportPanel from '../Exports/ExportPanel';

const SettingsManagement = () => {
    const location = useLocation();
    const { userRole } = useCheckUserRole();
    const { fetchingAuthorizedMenus } = useContext(MenuAuthorizationContext);
    const [currentPathname, setCurrentPathname] = useState<string>('');

    useEffect(() => {
        setCurrentPathname(location?.pathname);
    }, [location?.pathname]);

    useProtectedPageRedirection();

    const defaultTabCb = () => {
        switch (userRole) {
            case URER_ROLES.DOCTOR:
                return <AgendaSettings />;

            case URER_ROLES.COORDINATOR:
                return <UsersPanel />;

            case URER_ROLES.ADMIN:
                return <UsersPanel />;

            default:
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CustomCircleLoader />
                    </div>
                );
        }
    };

    const tabToRender = () => {
        switch (currentPathname) {
            case ROUTES.users:
                return <UsersPanel />;

            case ROUTES.modules:
                return <ModulesPanel />;

            case ROUTES.workshops:
                return <WorkshopsPanel />;

            case ROUTES.agendaSettings:
                return <AgendaSettings />;

            case ROUTES.generalSettings:
                return <GeneralSettings />;

            case ROUTES.SkillSettings:
                return <SkillsPanel />;

            case ROUTES.GoalSettings:
                return <GoalsPanel />;

            case ROUTES.contactSettings:
                return <ContactSettings />;

            case ROUTES.articleSettings:
                return <ArticlePanel />;
                
            case ROUTES.pathologySettings:
                return <PathologyPanel />;

            case ROUTES.ETFSettings:
                return <ETFList />;

            case ROUTES.quizSettings:
                return <QuizTabs />;

            case ROUTES.ThemeSettings:
                return <UserTheme />;

            case ROUTES.specialities:
                return <SpecialityPanel />;

            case ROUTES.Exports:
                    return <ExportPanel />;
            default:
                return defaultTabCb();
        }
    };

    return (
        <Box sx={styles.container}>
            <Box style={{ overflow: 'scroll' }} sx={styles.content}>
                <SettingsSubMenuBar />
            </Box>

            <Box sx={styles.panel}>
                {fetchingAuthorizedMenus ? (
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 200,
                        }}
                    >
                        <CustomCircleLoader />
                    </Box>
                ) : (
                    tabToRender()
                )}
            </Box>
        </Box>
    );
};

export default SettingsManagement;
