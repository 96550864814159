import { Grid } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import CustomModal from 'src/components/CustomModal';
import EventNameAndType from './EventForms/EventNameAndType';
import EventHours from './EventForms/EventHours';
import EventIntervenant from 'src/views/MyAgenda/EventModal/EventForms/EventIntervenant';
import EventRoomAndRemark from './EventForms/EventRoomAndRemark';
import EventService from 'src/services/Calendar/events.service';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import EventPatient from './EventForms/EventPatient';
import EventConsultationType from './EventForms/EventConsultationType';
import { WorkshopIntervenants } from 'src/views/MyAgenda/EventModal/EventForms/WorkshopIntervenants';
import {
    checkDefaultAttendees,
    checkEventFormValues,
    checkIfCorrectStartTime,
    checkIfValidDate,
    DnDEventRequest,
    finalAttendees,
    manageLocationId,
    setEventPayloadValues,
} from './EventModalLogic';
import { EventModalProps } from 'src/interfaces/interfaces';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { createEventDate, isFalse } from 'src/utils/utils';
import useKeyboardHandler from 'src/hooks/useKeyboardHandler';
import moment from 'moment';
import 'moment-timezone';
import EventProgram from './EventForms/EventProgram';
import useCheckUserRole from 'src/hooks/useCheckUserRole';
import { UserContext } from 'src/providers/UserProvider';
import { baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';
import { CustomText } from 'src/components/Text/CustomText';
import { isDivisibleByDuration } from 'src/utils/constants/constants';

const EventModal: FC<EventModalProps> = (props) => {
    const {
        modalOpen,
        modalToggle,
        specialityId,
        setUpdateList,
        setSpecialityId,
        selectedTime,
        eventData,
        edition,
        toggleEventModal,
        createEventFromBtnAction,
        viewType,
        dataForWeeklyEvent,
        setCreateEventFromBtnAction,
        droppedEventId,
        newSpecialityInfos,
        setNewSpecialityInfos,
        setDroppedEventId,
        setIsFromInfos,
        newEventEndDate,
        newEventDate,
        setNewEventDate,
        setNewEventEndDate,
        droppedEventDateTime,
        isPatientAgenda,
        patientId,
    } = props;

    const { currentDate } = useContext(AgendaContext);
    const { roles } = useCheckUserRole();
    const { userInfos, hasEtp } = useContext(UserContext);

    const [creating, setCreating] = useState<boolean>(false);
    const [choosedPatient, setChoosedPatient] = useState<Object | null>(null);
    const [droppedEvent, setDroppedEvent] = useState<any>(null);
    const [loadingDroppedEvent, setLoadingDroppedEvent] = useState<boolean>(false);
    const [calendarId, setCalendarId] = useState<number>(0);
    const [eventDate, setEventDate] = useState<Date>(currentDate);
    const [professionalIds, setProfessionalIds] = useState<any>([]);

    const [allWorkshopData, setAllWorkshopData] = useState<any>([]);
    const [selectedWorkshopData, setSelectedWorkshopData] = useState<any>({});
    const [canDivisibleByDuration, setCanDivisibleByDuration] = useState<boolean>(false);
    const event = droppedEventId && droppedEventId > 0 ? droppedEvent : eventData;
    const editFromDnd = Boolean(newSpecialityInfos?.specialityTypeId > 0);
    const defaultEventValue = {
        summary: event?.summary ?? '',
        calendarId: event?.calendarId ?? calendarId ?? 1,
        attendees: event?.attendees ?? [],
        consultationTypeId: event?.consultationType?.id ?? -1,
        customLocation: -1,
        specialityTypeId: specialityId && specialityId > 0 ? specialityId : 1,
        isPublic: event?.isPublic ?? -1,
        professionals: event?.professionals ?? [],
        isInSitu: event?.isInSitu ?? true,
        workshopId: event?.workshop?.id ?? -1,
    };

    const [valideEndTime, setValideEndTime] = useState<boolean>(false);
    const [eventPayload, setEventPayload] = useState<any>(defaultEventValue);
    const [attendeeTypeId, setAttendeeTypeId] = useState<number>(0);
    useEffect(() => {
        setEventPayload((prev: any) => ({ ...prev, specialityTypeId: specialityId }));
    }, [specialityId]);

    useEffect(() => {
        handleSetPayload();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event, newSpecialityInfos]);

    const handleFetchEvent = () => {
        setLoadingDroppedEvent(true);
        EventService.getEvent(droppedEventId ?? 0).then((res: any) => {
            setDroppedEvent(res?.data?.data);
            setLoadingDroppedEvent(false);
        });
    };

    const handleFetchCalendar = () => {
        EventService.getCalendars().then((res: any) => {
            const data = res?.data?.data?.items?.[0]?.id ?? 1;
            setCalendarId(data);
        });
    };

    useEffect(() => {
        handleFetchCalendar();
    }, []);

    useEffect(() => {
        if (droppedEventId && droppedEventId > 0) {
            handleFetchEvent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [droppedEventId]);

    const defaultAttendees = checkDefaultAttendees(event, editFromDnd);

    function handleSetPayload() {
        if (event?.id > 0) {
            const presentiel = event?.isInSitu ? 1 : 0;
            setEventPayloadValues({
                setEventPayload,
                event,
                newSpecialityInfos,
                defaultAttendees,
                presentiel,
            });
        }
    }

    const isWorkshop = eventPayload?.typeId === 1;
    const isSlotDE = eventPayload?.typeId === 9;

    useEffect(() => {
        if (isSlotDE) {
            setEventPayload((prev: any) => ({
                ...prev,
                attendees: [{ id: userInfos?.id, type: 2 }],
            }));
        }
    }, [eventPayload?.typeId]);

    const isVisio = isFalse(eventPayload?.isInSitu) ?? true;

    const isValideStartTime = checkIfCorrectStartTime({
        eventPayload,
        eventDate,
        createEventFromBtnAction,
    });

    const isValidDate = checkIfValidDate({ eventDate });

    const disableBtn = checkEventFormValues({
        eventPayload,
        choosedPatient,
        isWorkshop,
        isSlotDE,
        inCorrectStartTime: !isValideStartTime,
        validatedDate: !isValidDate,
        canDivisibleByDuration: canDivisibleByDuration
    });

    const loadingData = <CustomCircleLoader height={25} width={25} />;

    const workshopPatients = event?.patients?.map((workshopPatients: any) => ({
        id: workshopPatients.attendeeUser.id,
        type: 1,
    }));
    const patient = (!isWorkshop ? choosedPatient : workshopPatients) ?? [];
    const clearFormData = () => {
        !event && setEventPayload(defaultEventValue);
        setCreateEventFromBtnAction?.(false);
        setNewSpecialityInfos?.(null);
        setDroppedEventId?.(0);
        setIsFromInfos?.(false);
        setNewEventDate?.('');
        setNewEventEndDate?.('');
    };
    const patientValues = patient?.length > 0 ? patient : [patient];

    function convertToTimeZoneDate(date: string) {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const addTimeZoneToDate = moment.tz(date, timezone).format();
        return addTimeZoneToDate;
    }

    const addNewEvent = async () => {
        const attendees =
            patientValues[0]?.id > 0 ? [...eventPayload.attendees, ...patientValues] : [...eventPayload.attendees];

        const isCreneau = eventPayload?.typeId === 9;

        let finalPayload = {
            ...eventPayload,
            attendees: finalAttendees(attendees),
            summary: isCreneau ? 'Créneau de disponibilité pour entretien individuel' : eventPayload.summary,
            startDate: convertToTimeZoneDate(createEventDate(eventPayload?.startDate, eventDate)),
            endDate: convertToTimeZoneDate(createEventDate(eventPayload?.endDate, eventDate)),
        };

        manageLocationId(isVisio, finalPayload, eventPayload);
        const canSendTheRequest = !disableBtn && finalPayload?.typeId > 0;

        const { CreateNewEvent, UpdateEvent } = DnDEventRequest({
            defaultEventValue,
            event,
            finalPayload,
            modalToggle,
            setCreating,
            setSpecialityId,
            setUpdateList,
            setEventPayload,
            toggleEventModal,
            clearFormData,
        });

        if (canSendTheRequest) {
            setCreating(true);
            if (edition || editFromDnd || (droppedEventId && droppedEventId > 0)) {
                await UpdateEvent();
            } else {
                await CreateNewEvent();
            }
        }
    };

    const droppedEventSpecialityId = {
        ...event,
        specialityType: {
            ...event?.specialityType,
            id: newSpecialityInfos?.specialityTypeId,
        },
    };

    const eventDataForIntervenant = newSpecialityInfos?.specialityTypeId > 0 ? droppedEventSpecialityId : event;

    useKeyboardHandler({ handler: !disableBtn ? addNewEvent : undefined });

    const cantSubmit = roles?.isDoctor && !hasEtp;
    const isExternalDoctor = Boolean(userInfos?.isExternal);

    return (
        <CustomModal
            withMinHeight
            width={600}
            height={baseScreenHeight.newEventModal}
            title={event || loadingDroppedEvent ? "Modifier l'événement" : 'Nouvel événement'}
            toggleModal={() => modalToggle(false)}
            clearFormData={clearFormData}
            open={modalOpen}
            footer={
                <Grid container justifyContent="center">
                    {isExternalDoctor && (
                        <div style={{ padding: 10 }}>
                            <CustomText
                                style={{ textAlign: 'center', color: baseColors.orange.primary, fontStyle: 'italic' }}
                            >
                                Un soignant externe ne peut pas créer/modifier un événement.
                            </CustomText>
                        </div>
                    )}
                    <CustomLoadingButton
                        width="70%"
                        label="Valider"
                        loading={creating}
                        disabled={disableBtn || cantSubmit || isExternalDoctor}
                        handleClick={addNewEvent}
                    />
                </Grid>
            }
        > 
            { loadingDroppedEvent ? 
                <Grid container justifyContent='center' alignItems='center' marginTop='20px'>
                    { loadingData }
                </Grid>
                :
                <div style={{ marginLeft: 10, paddingRight: 15 }}>
                    <EventNameAndType
                        isWorkshop={isWorkshop}
                        eventData={event}
                        loadingData={loadingData}
                        allWorkshopData={allWorkshopData}
                        setAllWorkshopData={setAllWorkshopData}
                        setSelectedWorkshopData={setSelectedWorkshopData}
                        setEventPayload={setEventPayload}
                        payload={eventPayload}
                        attendeeTypeId={attendeeTypeId}
                        professionalIds={professionalIds}
                    />

                    <EventHours
                        eventPayload={eventPayload}
                        createEventFromBtnAction={createEventFromBtnAction}
                        editEventData={event}
                        setEventPayload={setEventPayload}
                        eventData={selectedTime}
                        viewType={viewType}
                        dataForWeeklyEvent={dataForWeeklyEvent}
                        editFromDnd={editFromDnd}
                        droppedEventId={droppedEventId}
                        newEventDate={newEventDate}
                        eventDate={eventDate}
                        newEventEndDate={newEventEndDate}
                        setEventDate={setEventDate}
                        valideStartTime={!isValideStartTime}
                        validatedDate={!isValidDate}
                        valideEndTime={valideEndTime}
                        droppedEventDateTime={droppedEventDateTime}
                        selectedWorkshopData={selectedWorkshopData}
                        isSlotDE={isSlotDE}
                        canDivisibleByDuration={canDivisibleByDuration}
                        setCanDivisibleByDuration={setCanDivisibleByDuration}
                    />

                    {!isWorkshop && !isSlotDE && (
                        <EventPatient
                            isPatientAgenda={isPatientAgenda}
                            patientId={patientId}
                            setChoosedPatient={setChoosedPatient}
                            eventData={event}
                        />
                    )}

                    {isWorkshop && !isSlotDE && (
                        <WorkshopIntervenants
                            loadingData={loadingData}
                            setAttendeeTypeId={setAttendeeTypeId}
                            setPayload={setEventPayload}
                            lockWorkshopInput={false}
                            payload={eventPayload}
                            attendeeTypeId={attendeeTypeId}
                            workshopData={event}
                            selectedWorkshopData={selectedWorkshopData}
                            professionalIds={professionalIds}
                            setProfessionalIds={setProfessionalIds}
                        />
                    )}

                    {!isWorkshop && !isSlotDE && (
                        <EventIntervenant
                            loadingData={loadingData}
                            defaultSpecialityId={specialityId}
                            setEventPayload={setEventPayload}
                            eventData={eventDataForIntervenant}
                            createEventFromBtnAction={createEventFromBtnAction}
                            editFromDnd={editFromDnd}
                        />
                    )}

                    {!isSlotDE && (
                        <EventProgram loadingData={loadingData} setEventPayload={setEventPayload} eventData={event} />
                    )}

                    <EventConsultationType
                        isVisio={isVisio}
                        loadingData={loadingData}
                        payload={eventPayload}
                        setEventPayload={setEventPayload}
                        eventData={event}
                        selectedWorkshopData={selectedWorkshopData}
                    />

                    <EventRoomAndRemark
                        isVisio={isVisio}
                        loadingData={loadingData}
                        payload={eventPayload}
                        setEventPayload={setEventPayload}
                        eventData={event}
                        selectedWorkshopData={selectedWorkshopData}
                    />
                </div>
            }
        </CustomModal>
    );
};

export default EventModal;
