import { makeStyles } from '@mui/styles';
import { baseColors, baseFontFamily } from '../../../../utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        // marginTop: 18,
        flexDirection: 'row',
    },
    content: {},
    header: {
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20,
    },
    title: {
        color: baseColors.black,
        fontFamily: baseFontFamily.BreeSerif,
        fontSize: '16px',
        marginBottom: 10,
    },
    subtitle: {
        color: baseColors.green.primary,
        fontSize: '12px',
    },
});

export default useStyles;
