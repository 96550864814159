import { ProfessionalCpts } from 'src/interfaces/interfaces';
import {
    isNotBlank,
    isNumber,
    isPhoneNumber,
    isSamePassword,
    isValidMail,
    isValidPassword,
    isValidRpps,
} from 'src/utils/helpers/InputValidator';

export type SignOutInputValidation = {
    specialityTypeId: number;
    firstName: string;
    lastName: string;
    rpps: string;
    email: string;
    password: any;
    phoneNumber: any;
};

const defaultValue: ProfessionalCpts = {
    firstName: '',
    lastName: '',
    rpps: '',
    hasEtp: false,
    email: '',
    category: 'PROFESSIONAL',
    role: 'ROLE_DOCTOR',
    sex: 1,
    birthDate: '',
    password: {
        first: '',
        second: '',
    },
    filecontent: '',
    phoneNumber: '',
    specialityTypeId: 0,
    etpCertificateFileContent: '',
    checkEtp: 0,
};

const allowedExtension = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'application/pdf',
    'application/msword',
    'text/csv',
    'text/txt',
];

const validPatientData = (professional: SignOutInputValidation) =>
    isNotBlank(professional?.firstName) &&
    isNotBlank(professional?.lastName) &&
    isNumber(professional?.rpps) &&
    isValidMail(professional?.email) &&
    professional?.specialityTypeId !== 0 &&
    isPhoneNumber(professional?.phoneNumber) &&
    isValidRpps(professional?.rpps) &&
    isValidPassword(professional?.password?.first) &&
    isSamePassword(professional?.password?.first, professional?.password?.second);

export { defaultValue, allowedExtension, validPatientData };
