import { baseScreenHeight } from 'src/utils/constants/baseStyles';

const styles: any = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        paddingX: '1rem',
        ...baseScreenHeight.defaultHeight,
    },
    header: {
        // height: '50px',
        marginTop: '25px',
        marginBottom: '15px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    }
};

export default styles;
