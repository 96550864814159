import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';
import { ArticlePayload, Posts } from 'src/interfaces/interfaces';
import { sxStyles } from '../styles';
import { defaultArticlePayload, tableHeaders } from '../ArticleLogic';
import CustomNewUserBtn from '../../Users/Content/CustomNewUserBtn';
import { ArticleActionButtons, ArticleTable } from './ArticleTable';
import { ArticleForm } from '../Form/ArticleForm';
import { getPostsList } from '../../../../services/Posts';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { CustomText } from 'src/components/Text/CustomText';
import SubMenusLoader from 'src/components/SettingsSubMenuBar/SubMenusLoader/SubMenusLoader';
import { sortByIds } from 'src/utils/utils';

const ArticlePanel = () => {
    const [dataRows, setDataRows] = useState<any[]>([]);
    const [updateList, setUpdateList] = useState<boolean>(false);
    const [modalArticleFormVisible, setModalArticleFormVisible] = useState<boolean>(false);
    const [selectedPostData, setSelectedPostData] = useState<Posts | null>(null);
    const toggleModal = () => {
        setModalArticleFormVisible(!modalArticleFormVisible);
        setSelectedPostData(null);
        clearInput();
    };
    const [fetchingArticles, setFetchingArticles] = useState<boolean>(false);
    const [article, setArticle] = useState<ArticlePayload>(defaultArticlePayload);
    const [vidsource, setVidsource] = useState<undefined | string>();
    const [videoLoader, setVideoLoader] = useState<boolean | undefined>();
    const [posst, setPosst] = useState<any>();

    const parsePostsData = (posts: any[]) => {
        const rows: any[] = Array.from(posts, (post) => ({
            id: post.id,
            title: post.title,
            content: post?.content,
            axis: post.axis.name,
            axisId: post.axis.id,
            postCategory: post.postCategory.name,
            postCategoryId: post.postCategory.id,
            postType: post.postType.name,
            postTypeId: post.postType.id,
            video: post?.video?.url,
            image: post?.image?.url,
            goals: post?.goals,
            action: (
                <ArticleActionButtons
                    postData={post}
                    toggleModal={toggleModal}
                    setSelectedPostData={setSelectedPostData}
                    setUpdateList={setUpdateList}
                />
            ),
        }));
        setDataRows(sortByIds(rows));
    };

    const handleFetchPosts = () => {
        setFetchingArticles(true);
        getPostsList()
            .then((response) => {
                const posts = response;
                parsePostsData(posts);
                setFetchingArticles(false);
                setPosst(posts);
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        handleFetchPosts();
    }, [updateList]);

    const clearInput = () => {
        setArticle(defaultArticlePayload);
        setVidsource(undefined);
        setVideoLoader(false);
    };

    return (
        <Box sx={sxStyles.container}>
            <Box sx={sxStyles.content}>
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Liste des contenus
                </CustomText>

                <CustomNewUserBtn btnLabel="Créer un nouveau contenu" toggleModal={toggleModal} />

                <ArticleForm
                    article={article}
                    setArticle={setArticle}
                    vidsource={vidsource}
                    setVidsource={setVidsource}
                    videoLoader={videoLoader}
                    setVideoLoader={setVideoLoader}
                    selectedPostData={selectedPostData}
                    modalOpen={modalArticleFormVisible}
                    toggleModal={toggleModal}
                    setUpdateList={setUpdateList}
                    clearInput={clearInput}
                />
            </Box>

            {fetchingArticles ? (
                <SubMenusLoader />
            ) : dataRows?.length === 0 ? (
                <div style={{ paddingBottom: 150 }}>
                    <CustomEmptyData label="Aucun contenu disponible." />
                </div>
            ) : (
                <ArticleTable tableHeaders={tableHeaders} dataRows={dataRows} />
            )}
        </Box>
    );
};

export default ArticlePanel;
