import moment from 'moment';
import { toast } from 'react-toastify';
import { Users } from 'src/interfaces/interfaces';
import MailJetableService from 'src/services/MailJetable/mailJetable.service';
import { postNewUser, updateUser } from 'src/services/User/user.service';
import { CATEGORIES, URER_ROLES } from 'src/utils/constants/enums';
import { isNotBlank, isValidMail, isValidRpps } from 'src/utils/helpers/InputValidator';

const defaultUsersValue: Users = {
    firstName: '',
    lastName: '',
    email: '',
    sex: 1,
    category: '',
    activated: true,
    roles: '',
    birthDate: moment(new Date()).format('YYYY-MM-DD'),
    password: {
        first: '',
        second: '',
    },
    filecontent: '',
    isExternal: 0,
    hasEtp: 1,
};

const tableHeaders = [
    {
        name: 'Nom',
    },
    {
        name: 'Prénom',
    },
    {
        name: 'Rôle',
    },
    {
        name: 'Catégorie',
    },
    {
        name: 'Action',
    },
];

const categories = (connectedUserRole: string, disableCategoryOption?: boolean) => {
    const firstOption = { id: 0, label: 'Choisir la catégorie', value: '' };
    const professionnalOption = { id: 3, label: 'Professionnel', value: CATEGORIES.PROFESSIONAL };
    const patientOption = [{ id: 5, label: 'Patient', value: CATEGORIES.PATIENT }];

    if (disableCategoryOption) {
        return patientOption;
    } else {
        switch (connectedUserRole) {
            case URER_ROLES.COORDINATOR:
                return [firstOption, professionnalOption];

            case URER_ROLES.DOCTOR:
                return [firstOption, professionnalOption];

            case URER_ROLES.PATIENT:
                return [{ id: 5, label: 'Patient', value: CATEGORIES.PATIENT }];

            default:
                return [firstOption, { id: 2, label: 'Autre', value: CATEGORIES.OTHER }, professionnalOption];
        }
    }
};

const roles = ({ category, isPatient }: any) => {
    const firstOption = { id: 0, label: 'Choisir le rôle', value: '' };
    const commonOptions = [
        { id: 1, label: 'Soignant', value: URER_ROLES.DOCTOR },
        { id: 8, label: 'Soignant administrateur de personnel', value: URER_ROLES.DOCTOR_ADMIN_PATIENT },
        { id: 3, label: 'Coordinateur', value: URER_ROLES.COORDINATOR },
    ];

    if (isPatient) {
        return [{ id: 5, label: 'Patient', value: URER_ROLES.PATIENT }];
    } else {
        switch (category) {
            case CATEGORIES.OTHER:
                return [firstOption, { id: 2, label: 'Administrateur', value: URER_ROLES.ADMIN }];

            default:
                return [firstOption, ...commonOptions];
        }
    }
};

const isUserValid = (user: any) =>
    user.firstName.trim().length !== 0 &&
    user.lastName.trim().length !== 0 &&
    isValidMail(user.email) &&
    user.password.first.trim().length !== 0 &&
    user.password.second.trim().length !== 0 &&
    user.password.second === user.password.first &&
    user.category.trim().length !== 0;

const filteredCategories = (categories: any[], connectedUserRole: string) =>
    categories.filter((cat: any) => {
        if (connectedUserRole === URER_ROLES.ADMIN) {
            return cat.value !== URER_ROLES.PATIENT;
        }
        return true;
    });

const filteredRoles = (rolesData: any[], category: string) =>
    rolesData.filter((role: any) => {
        switch (category) {
            case CATEGORIES.PATIENT:
                return role.value === URER_ROLES.PATIENT;

            case CATEGORIES.PROFESSIONAL:
                return role.value === URER_ROLES.DOCTOR || role.value === URER_ROLES.COORDINATOR;

            case CATEGORIES.OTHER:
                return role.value === URER_ROLES.ADMIN;

            default:
                return true;
        }
    });

const currentCategory = ({ selectedUserData, connectedUserRole }: any) => {
    const item = categories(connectedUserRole).find(
        (category: any) => category?.value?.toLowerCase() === selectedUserData?.category?.toLowerCase(),
    );
    return selectedUserData?.id ? item?.id ?? 0 : 0;
};

const currentRole = ({ selectedUserData, rolesData, payload }: any) => {
    const item = rolesData.find((role: any) => role.value === payload.role);
    return item?.id || 0;
};

const isValideDate = (date: any) => {
    const currentDate: Date = new Date();
    const inputDate: Date = new Date(date);
    if (date === 'Invalid date' || inputDate > currentDate) {
        return false;
    }
    return true;
};

const canSubmit: any = ({ payload }: any) =>
    isNotBlank(payload?.firstName) &&
    isNotBlank(payload?.lastName) &&
    isValidMail(payload?.email) &&
    isNotBlank(payload?.category) &&
    isValideDate(payload.birthDate) &&
    isNotBlank(payload?.role) &&
    (payload?.category === CATEGORIES.PROFESSIONAL
        ? isValidRpps(payload?.rpps) && payload?.specialityTypeId > 0
        : payload?.category === CATEGORIES.OTHER
        ? isValidRpps(payload?.rpps)
        : true);

const operationSuccessfully = ({
    res,
    selectedUserData,
    payload,
    toggleModal,
    setUserInfos,
    clearInput,
    setUpdateList,
    setPayload,
    setProfIcon,
    setLoadingUserForm,
    setLockUserInput,
}: any) => {
    if (selectedUserData?.id > 0) {
        toast.success('Utilisateur modifié avec succès.');
        setUserInfos(payload);
        toggleModal();
        setProfIcon('');
        clearInput();
        setUpdateList((prev: any) => !prev);
    } else {
        if (res.data.code === 200) {
            toast.success('Utilisateur ajouté avec succès.');
            setPayload((old: any) => ({ ...old, firstName: '', lastName: '' }));
            setProfIcon('');
            clearInput();
            toggleModal();
            setUpdateList((prev: any) => !prev);
        } else if (res.data.code === 400) {
            if (res.data.status === 'USER_RPPS_ALREADY_EXIST') {
                toast.error('Numéro RPPS déjà utilisé.');
            } else if (res.data.status === 'USER_EMAIL_ALREADY_EXIST') {
                toast.error('E-mail  déjà utilisé.');
            } else {
                toast.error("Erreur d'ajout utilisateur.");
            }
        }
    }
    setLoadingUserForm(false);
    setLockUserInput(false);
};

const operationFailed = ({ selectedUserData, setLoadingUserForm, setLockUserInput, res }: any) => {
    if (selectedUserData?.id > 0) {
        if (res.data.status === 'USER_RPPS_ALREADY_EXIST') {
            toast.error('Numéro RPPS déjà utilisé.');
        }else if (res.data.status === 'USER_EMAIL_ALREADY_EXIST') {
            toast.error('E-mail  déjà utilisé.');
        } else {
            toast.error("Erreur de modification d'utilisateur");
        }
    } else {
        toast.error("Erreur d'ajout d'utilisateur");
    }
    setLoadingUserForm(false);
    setLockUserInput(false);
};

const handleFetchMailJetable = ({
    toggleModal,
    clearInput,
    payload,
    successfully,
    failed,
    setLoadingUserForm,
    setLockUserInput,
    selectedUserData,
}: any) => {
    MailJetableService.getMailJetable().then((response) => {
        let testEmail = payload.email;
        if (testEmail) {
            let afterArobase = testEmail.split('@')[1];
            let mailJetableArray: any[] = [];
            let responses = response?.data?.data;
            responses.map((element: any) => mailJetableArray.push(element.name.toLowerCase()));
            if (mailJetableArray.includes(afterArobase.toLowerCase())) {
                setLoadingUserForm(false);
                setLockUserInput(false);
                toast.error("Votre adresse email n' est pas une adresse professionnelle");
            } else {
                if (selectedUserData?.id > 0) {
                    updateUser(selectedUserData?.id, payload)
                        .then((res) => successfully(res))
                        .catch((error) => failed(error.response));
                } else {
                    postNewUser(payload)
                        .then((res) => successfully(res))
                        .catch(() => failed());
                }
            }
        }
    });
};

const performAddUser = ({
    selectedUserData,
    payload,
    setProfIcon,
    clearInput,
    setLoadingUserForm,
    setLockUserInput,
    toggleModal,
    setUserInfos,
    setUpdateList,
    setPayload,
}: any) => {
    setLoadingUserForm(true);
    setLockUserInput(true);
    setProfIcon(undefined);

    const successfully = (res: any) =>
        operationSuccessfully({
            res,
            selectedUserData,
            payload,
            toggleModal,
            setUserInfos,
            clearInput,
            setUpdateList,
            setPayload,
            setProfIcon,
            setLoadingUserForm,
            setLockUserInput,
        });

    const failed = (res: any) => operationFailed({ selectedUserData, setLoadingUserForm, setLockUserInput, res });

    handleFetchMailJetable({
        toggleModal,
        clearInput,
        payload,
        successfully,
        failed,
        setLoadingUserForm,
        setLockUserInput,
        selectedUserData,
    });
};

const handleChangeOptions =
    ({ items, optionKey, setPayload, defaultRole }: any) =>
    (value: number) => {
        const selectedValue = items.find((item: any) => item.id === value)?.value;
        // const additionalePayload = optionKey === 'category' ? { role: 0 } : { specialityTypeId: 0 };
        setPayload((old: any) => ({ ...old, [optionKey]: selectedValue ?? '' }));
        if (defaultRole) {
            setPayload((old: any) => ({ ...old, role: null }));
        }
    };

export {
    roles,
    handleChangeOptions,
    performAddUser,
    operationFailed,
    operationSuccessfully,
    canSubmit,
    currentCategory,
    currentRole,
    defaultUsersValue,
    tableHeaders,
    categories,
    isUserValid,
    filteredRoles,
    filteredCategories,
};
