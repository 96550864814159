import { useContext, useState } from 'react';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { getCustomLocationItems, getUpstairs } from 'src/services/Rooms';
import { baseColors, eventColorByRoom } from 'src/utils/constants/baseStyles';

const useFetchUpstairs = (setUpstairs?: Function) => {
    const { setAllRooms } = useContext(AgendaContext);
    const [fetchingLocation, setFetchingLocation] = useState<boolean>(false);
    const [customLocations, setCustomLocations] = useState<any[]>([]);

    const handleFecthLocations = () => {
        setFetchingLocation(true);
        getCustomLocationItems()
            .then((response) => {
                const data = response?.data?.data?.flat(1)?.map((room: any, i: number) => ({
                    id: room.id,
                    name: room.name,
                }));
                setCustomLocations([...data]);
                setFetchingLocation(false);
            })
            .catch(() => setFetchingLocation(false));
    };

    const handleFetchUpstairs = () => {
        getUpstairs().then((response: any) => {
            if (response) {
                const upstairData = response?.data?.data;
                setUpstairs?.(upstairData);
                const roomData = upstairData?.map((upstr: any) => upstr.rooms);
                setAllRooms(
                    roomData?.flat(1)?.map((room: any, i: number) => ({
                        id: room.id,
                        name: room.name,
                        color: eventColorByRoom[i] ?? baseColors.green.light,
                    })),
                );
            }
        });
    };

    return {
        fetchingLocation,
        customLocations,
        handleFetchUpstairs,
        handleFecthLocations,
    };
};

export default useFetchUpstairs;
