import { FC, useContext, useEffect, useState } from 'react';
import useStyles from './styles';
import { Box } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { ProgramFormHeader } from '../Form/Header/ProgramFormHeader';
import { ProgramContext } from 'src/providers/ProgramProvider';
import { Switch, useHistory, Route, useRouteMatch, useParams } from 'react-router-dom';
import IconObesite from 'src/components/CustomIcon/IconObesite';
import { ProgramNavBar } from '../Navbar/ProgramNavBar';
import { GlobalActivity } from '../Synthese/GlobalActivity/GlobalActivity';
import { Discussion } from './Discussion/Discussion';
import ProgramAgenda from './ProgramAgenda';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { programDetailsMenus } from '../ProgramLogic';
import { ProgramSkill } from './ProgramSkill/ProgramSkill';
import usePrograms from 'src/hooks/usePrograms';

export const ProgramDetail: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const params: any = useParams();
    const { url } = useRouteMatch();
    const { currentProgram } = useContext(ProgramContext);
    const [selectedMenu, setSelectedMenu] = useState<number>(1);
    const [currentId, setCurrentId] = useState<number | undefined>(undefined);
    const { fetching: loadingProgram, fetchProgramById, program, setProgram } = usePrograms({});

    useEffect(() => {
        if (!currentProgram) {
            const programId = +params?.id;
            setCurrentId(programId);
            fetchProgramById(programId);
        } else {
            setCurrentId(currentProgram.id);
            setProgram(currentProgram);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProgram?.id, params?.id]);

    useEffect(()=>{
        const subDetail = params?.subDetail;
        const currentPage = programDetailsMenus.find((menu:any)=>menu.route === subDetail)
        currentPage?.key && setSelectedMenu(currentPage?.key);
    },[])

    const onSelect = (menuItem: any) => {
        setSelectedMenu(menuItem.key);
        if (menuItem?.direct && currentId) {
            history.replace(`/programmes/${currentId}/synthese`);
        } else {
            history.push(`${menuItem.route}`);
        }
    };

    const getSubUrl = () => url.split('/').slice(0, 4).join('/');

    return (
        <Box className={classes.container}>
            {loadingProgram ? (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '25%' }}>
                    <CustomCircleLoader />
                </div>
            ) : (
                <div>
                    <CustomText
                        style={{ textAlign: 'center' }}
                        fontSize={22}
                        fontFamily={baseFontFamily.BreeSerif}
                        color={baseColors.black}
                    >
                        Programmes
                    </CustomText>

                    {program?.id && (
                        <>
                            <ProgramFormHeader onDetailsPage isSynthesis setProgram={setProgram} program={program} icon={<IconObesite />} />
                            <ProgramNavBar items={programDetailsMenus} selected={selectedMenu} onSelect={onSelect} />
                            <div className={classes.wrapper}>
                                <Switch>
                                    <Route path={`${getSubUrl()}/activity`}>
                                        <GlobalActivity />
                                    </Route>
                                    <Route path={`${getSubUrl()}/skill`}>
                                        <ProgramSkill loadingProgram={loadingProgram} program={program} />
                                    </Route>
                                    <Route path={`${getSubUrl()}/discussion`}>
                                        <Discussion programId={program?.id} />
                                    </Route>
                                    <Route path={`${getSubUrl()}/agenda`}>
                                        <ProgramAgenda programId={program.id} />
                                    </Route>
                                    <Route path={`${getSubUrl()}/synthese`}>
                                        <h1>Synthèse</h1>
                                    </Route>
                                </Switch>
                            </div>
                        </>
                    )}
                </div>
            )}
        </Box>
    );
};
