import { Grid } from '@mui/material';
import { FC } from 'react';
import { InputField } from 'src/components/InputField/InputField';
import { CustomText } from 'src/components/Text/CustomText';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { Spacer } from 'src/components/Spacer/Spacer';
import EventWorkshop from 'src/views/MyAgenda/EventModal/EventForms/EventWorkshop';
import { useFetchEventTypesRQHooks } from 'src/hooks/ReactQueryHooks/useEvenRQtHooks';

interface EventNameAndTypeProps {
    payload?: any;
    setEventPayload: any;
    loadingData?: any;
    eventData?: any;
    isWorkshop?: boolean;
    allWorkshopData?: any;
    setAllWorkshopData?: any;
    attendeeTypeId?: number;
    professionalIds?: any;
    setSelectedWorkshopData?: any;
}

const EventNameAndType: FC<EventNameAndTypeProps> = (props) => {
    const {
        payload,
        setEventPayload,
        loadingData,
        eventData,
        isWorkshop,
        allWorkshopData,
        setAllWorkshopData,
        attendeeTypeId,
        professionalIds,
        setSelectedWorkshopData,
    } = props;

    const defaultEventTypeId = eventData?.eventType?.id ?? undefined;

    const { data: eventTypeItems, isLoading: loading } = useFetchEventTypesRQHooks();

    const eventTypes = loading ? [] : eventTypeItems;

    const eventTypesData = [{ id: -1, name: 'Sélectionner un type' }, ...eventTypes];

    const handleChangeType = (value: string) => {
        setEventPayload((old: any) => ({ ...old, typeId: value }));
    };

    const handleChangeName = (value: any) => {
        setEventPayload((prev: any) => ({ ...prev, summary: value }));
    };

    return (
        <div style={{ marginLeft: -3 }}>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item>
                    <CustomText>Type de l' événement : </CustomText>
                </Grid>

                <Grid item style={{ width: '60%' }}>
                    {loading ? (
                        loadingData
                    ) : (
                        <div style={{}}>
                            <CustomSelect
                                onChange={handleChangeType}
                                label=""
                                labelKey={'name'}
                                items={eventTypesData}
                                defaultValue={defaultEventTypeId}
                                idSelect="selection-type-event"
                                width="100%"
                                maxWidth="340px"
                                
                            />
                        </div>
                    )}
                </Grid>
            </Grid>

            <Spacer />

            {isWorkshop && (
                <>
                    <EventWorkshop
                        isWorkshop={isWorkshop}
                        eventData={eventData}
                        loadingData={loadingData}
                        setEventPayload={setEventPayload}
                        allWorkshopData={allWorkshopData}
                        setAllWorkshopData={setAllWorkshopData}
                        setSelectedWorkshopData={setSelectedWorkshopData}
                        attendeeTypeId={attendeeTypeId}
                        professionalIds={professionalIds}
                        payload={payload}
                    />
                    <Spacer />
                </>
            )}

            <Grid container direction="row" alignItems={'center'} justifyContent="space-between">
                <Grid item>
                    <CustomText>Nom de l' événement :</CustomText>
                </Grid>

                <InputField
                    label=""
                    onChange={handleChangeName}
                    validator={isNotBlank}
                    errorMessage="Nom de l'événement obligatoire"
                    placeholder="Entrer un nom de l'événement ..."
                    width={338}
                    value={payload?.summary}
                />
            </Grid>

            <Spacer />
        </div>
    );
};

export default EventNameAndType;
