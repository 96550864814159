import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import UsersTable, { UsersActionButtons } from './UsersTable';
import { UserForm } from '../Form/UserForm';
import { Users } from 'src/interfaces/interfaces';
import UserService, { getRolesList } from 'src/services/User/user.service';
import { tableHeaders } from '../UserLogic';
import { sxStyles } from '../styles';
import { MenuAuthorizationContext } from 'src/providers/MenuAuthorizationProvider';
import { UserContext } from 'src/providers/UserProvider';
import { PlateformTypeContext } from '../../../../providers/PlateformTypeProvider';
import { CustomText } from 'src/components/Text/CustomText';
import SubMenusLoader from 'src/components/SettingsSubMenuBar/SubMenusLoader/SubMenusLoader';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { todayDate } from 'src/utils/utils';
import { URER_ROLES } from 'src/utils/constants/enums';

const defaultPayload = {
    civility: 1,
    firstName: '',
    lastName: '',
    email: '',
    rpps: '',
    sex: 1,
    phoneNumber: '',
    birthDate: todayDate(),
    birthPlace: '',
    profession: '',
    address: '',
    category: '',
    filecontent: '',
    hasEtp: 1,
};

const UsersPanel = () => {
    const [dataRows, setDataRows] = useState<any[]>([]);
    const [updateList, setUpdateList] = useState<boolean>(false);
    const [modalUserFormVisible, setModalUserFormVisible] = useState<boolean>(false);
    const [selectedUserData, setSelectedUserData] = useState<Users | null>(null);
    const toggleModal = () => {
        setModalUserFormVisible(!modalUserFormVisible);
        setSelectedUserData(null);
        clearInput();
    };
    const [rolesList, setRolesList] = useState<Object[]>([]);
    const [fetchingUsers, setFetchingUsers] = useState<boolean>(false);
    const { fetchingAuthorizedMenus } = useContext(MenuAuthorizationContext);
    const [payload, setPayload] = useState<any>(defaultPayload);
    const { isCoordinator } = useContext(UserContext);
    const { isCoordonatorFromPlateform } = useContext(PlateformTypeContext);

    const parseUsersData = (users: any[]) => {
        const rows: any[] = Array.from(users, (user) => ({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            roles: user.roles[0],
            sex: user.sex,
            category: user.category,
            email: user.email,
            rpps: user.rpps,
            password: user.password,
            isExternal: user.isExternal,
            action: (
                <UsersActionButtons
                    userData={user}
                    toggleModal={toggleModal}
                    setSelectedUserData={setSelectedUserData}
                />
            ),
        }));
        setDataRows(rows);
    };

    const parseRolesData = (roles: any[]) => {
        const rows: any[] = Array.from(roles, (role) => ({
            id: role.id,
            label: role.name,
            value: role.code,
        }));
        const index = rows.findIndex((object) => {
            return object.value === URER_ROLES.ADMIN;
        });
        if (isCoordinator === true) rows.splice(index, 1);

        setRolesList(rows);
    };

    const handleFetchUsers = () => {
        setFetchingUsers(true);
        UserService.getUsersLists(isCoordonatorFromPlateform ? undefined : URER_ROLES.ADMIN)
            .then((response) => {
                const users = response;
                if (users.length > 0) {
                    parseUsersData(users);
                }
                setFetchingUsers(false);
            })
            .catch((error) => console.log(error));
    };

    const handleFetchRoles = () => {
        getRolesList()
            .then((response) => {
                const roles = response;
                if (roles.items.length > 0) {
                    parseRolesData(roles.items);
                }
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        handleFetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateList, isCoordonatorFromPlateform]);

    useEffect(() => {
        handleFetchRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clearInput = () => {
        setPayload(defaultPayload);
    };

    if (fetchingAuthorizedMenus) {
        return (
            <Typography
                noWrap
                style={{ fontFamily: baseFontFamily.OpenSansRegular, fontSize: baseFontSize.sl, marginLeft: 10 }}
                color={baseColors.black}
            >
                Chargement en cours...
            </Typography>
        );
    }

    return (
        <Box sx={sxStyles.container}>
            <Box sx={sxStyles.content}>
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Liste des utilisateurs
                </CustomText>

                {!fetchingUsers && (
                    <CustomLoadingButton label="Créer un nouvel utilisateur" handleClick={toggleModal} />
                )}

                <UserForm
                    payload={payload}
                    setPayload={setPayload}
                    selectedUserData={selectedUserData}
                    modalOpen={modalUserFormVisible}
                    toggleModal={toggleModal}
                    setUpdateList={setUpdateList}
                    rolesList={rolesList}
                    clearInput={clearInput}
                />
            </Box>

            {fetchingUsers ? <SubMenusLoader /> : <UsersTable tableHeaders={tableHeaders} dataRows={dataRows} />}
        </Box>
    );
};

export default UsersPanel;
