import axios from './AxiosInstance';

export const getAttendeesSpeciality = () => {
    return axios.get('api/specialityTypes');
};

export const getAttendeesType = () => {
    return axios.get('api/attendeeTypes');
};

export const getAttendeesBySpeciality = (specialityId: number, professional?: boolean, hasEtp?: boolean) => {
    let additionalUrl: string = '';

    if (professional) additionalUrl += '&professional=true';
    
    if (hasEtp) additionalUrl += '&hasEtp=true';

    return axios.get('api/users?paginated=false&specialityTypeId=' + specialityId + additionalUrl);
};

export const getIntervenants = () => {
    return axios.get('api/users?role=doctor&paginated=false');
};

export const getCoordinator = () => {
    return axios.get('api/users?role=coordinator&paginated=false');
};
