/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect, useState } from 'react';
import { baseColors, baseFontFamily } from '../../../utils/constants/baseStyles';
import Drawer from '@mui/material/Drawer';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FichePatient from '../../../components/CustomIcon/FichePatient';
import DashboardInactif from '../../../components/CustomIcon/DashboardInactif';
import DashboardActif from '../../../components/CustomIcon/DashboardActif';
import Agenda from '../../../components/CustomIcon/Agenda';
import Programme from '../../../components/CustomIcon/Programme';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { ROUTES } from '../../../routes/routes';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CancelIcon from '@mui/icons-material/Cancel';
import { PatientContext } from 'src/providers/PatientProvider';
import IconSecurite from 'src/components/CustomIcon/IconSecurite';
import IconSettings from 'src/components/CustomIcon/IconSettings';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/stores/store';
import { retrieveAuthorizedSidebarMenus } from 'src/actions/authorization/authorization';
import { PermissionContext } from 'src/providers/PermissionProvider';
import { Menu } from 'src/interfaces/interfaces';
import { styles } from './styles';
import { PatientService } from 'src/services/Patients';
import { UserContext } from 'src/providers/UserProvider';
import { CustomText } from 'src/components/Text/CustomText';
import useProtectedPageRedirection from 'src/hooks/useProtectedPageRedirection';
import CustomPlayerVisio from 'src/components/CustomVisioIframe/CustomPlayerVisio';
import { VisioContentContext } from 'src/providers/VisioContentProvider';

interface MenuSideBarCptsProps {
    window?: () => Window;
}

const MenuSideBarCpts: FC<MenuSideBarCptsProps> = (props) => {
    const { window } = props;
    const history = useHistory();
    const { url } = useRouteMatch();
    const container = window !== undefined ? () => window().document.body : undefined;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [authorizedMenus, setAuthorizedMenus] = useState<Menu[]>([]);
    const { currentPatientId, setCurrentLocationPathname } = useContext(PatientContext);
    const sidebarMenuCodes = useSelector((state: RootState) => state.authorizations.listAuthorizedSidebarMenus);
    const { permissions } = useContext(PermissionContext);
    const { userInfos: userInfosFromContext } = useContext(UserContext);
    const { openIframe, visioContent } = useContext(VisioContentContext);
    const professionalId = userInfosFromContext?.id;
    const dispatch = useDispatch();
    const [defaultPatientId, setDefaultPatientId] = useState<any>();

    const handleFetchData = () => {
        if (professionalId > 0) {
            PatientService.getAcceptedByProfessional({ professionalId }).then((response) => {
                const responses = response?.data?.data;
                if (responses?.length) {
                    let patientsData = responses;
                    setDefaultPatientId(patientsData[0]?.patient?.id);
                } else {
                    setDefaultPatientId('');
                }
            });
        }
    };

    useEffect(() => {
        dispatch(retrieveAuthorizedSidebarMenus({}));
    }, [professionalId]);

    useEffect(() => {
        parseSideBarData(sidebarMenuCodes);
        handleFetchData();
    }, [sidebarMenuCodes, professionalId]);

    useProtectedPageRedirection();

    const sideBarData: Menu[] = [
        {
            id: 1,
            icon: <DashboardInactif height={20} width={20} />,
            iconActive: <DashboardActif height={20} width={20} />,
            label: 'Accueil',
            path: ROUTES.dashboard,
            featureCode: 'front_display_dashboard_sidebar_menu',
        },
        {
            id: 2,
            icon: <Agenda height={20} width={20} />,
            label: 'Mon agenda',
            path: ROUTES.agenda,
            featureCode: 'front_display_agenda_sidebar_menu',
        },
        {
            id: 3,
            icon: <FichePatient height={20} width={20} />,
            label: 'Patients',
            path: '/patients/list/' + currentPatientId + '/profile',
            featureCode: 'front_display_patient_sidebar_menu',
        },
        {
            id: 4,
            icon: <Programme height={20} width={20} />,
            label: 'Programmes',
            path: ROUTES.programmes,
            featureCode: 'front_display_program_sidebar_menu',
        },
        {
            id: 6,
            icon: <IconSecurite height={20} width={20} />,
            label: 'Sécurité',
            path: ROUTES.security,
            featureCode: 'front_display_security_sidebar_menu',
        },
    ];

    const settingsMenu = {
        id: 7,
        icon: <IconSettings height={20} width={20} />,
        label: 'Paramètres',
        path: ROUTES.settings,
        featureCode: 'front_display_parameter_angenda',
    };

    const parseSideBarData = (sidebarMenuCodes: string[]) => {
        let sideBarDataToUse = sideBarData;

        const allowOneParamsOnly = sidebarMenuCodes.find(
            (item: any) =>
                item === 'front_display_parameter_angenda' ||
                item === 'front_display_parameter_sidebar_menu' ||
                item === 'front_display_security_contact_sidebar_menu' ||
                item === 'front_display_parameter_users',
        );

        if (allowOneParamsOnly) {
            sideBarDataToUse.push({ ...settingsMenu, featureCode: allowOneParamsOnly });
        }

        const filteredAuthorizedMenu =
            sideBarDataToUse.filter((sidebarItem) => sidebarMenuCodes.includes(sidebarItem?.featureCode ?? '')) ?? [];

        setAuthorizedMenus(filteredAuthorizedMenu);
    };

    const handleClickMenu = (menuId: number, path: string) => {
        if (menuId === 3 && defaultPatientId > 0) {
            const pathname = `/patients/list/${defaultPatientId}/profile`;
            setCurrentLocationPathname(pathname);
            history.push(pathname);
        } else {
            history.push(path);
        }
    };

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    const drawer = (
        <MenuList>
            {authorizedMenus.map((menu: Menu) => {
                const isSelected = url.startsWith(menu.path.substring(0, 6));
                const selectedMenuContainerColor = isSelected ? baseColors.green.primary : undefined;
                const colorLabel = isSelected ? baseColors.white : baseColors.black;
                const iconBackground = isSelected ? baseColors.green.primary : baseColors.green.light;
                const labelFontFamily = isSelected ? baseFontFamily.OpenSansSemiBold : baseFontFamily.OpenSansRegular;
                const icon = isSelected ? (menu.id === 1 ? menu.iconActive : menu.icon) : menu.icon;
                const disableUntilPermissionsFetch =
                    permissions.length === 0 &&
                    ['front_display_parameter_sidebar_menu', 'front_display_security_sidebar_menu'].includes(
                        menu?.featureCode ?? '',
                    );

                return (
                    <MenuItem
                        key={menu.id.toString()}
                        disabled={disableUntilPermissionsFetch}
                        style={{
                            ...styles.menuContainer,
                            backgroundColor: selectedMenuContainerColor,
                            paddingRight: 10,
                        }}
                        onClick={() => handleClickMenu(menu.id, menu.path)}
                    >
                        <ListItemIcon style={{ ...styles.itemIcon, backgroundColor: iconBackground, marginRight: 5 }}>
                            {icon}
                        </ListItemIcon>

                        <CustomText
                            style={{ marginLeft: 10, marginRight: 10 }}
                            fontFamily={labelFontFamily}
                            color={colorLabel}
                        >
                            {menu.label}
                        </CustomText>
                    </MenuItem>
                );
            })}
        </MenuList>
    );

    return (
        <div style={{}}>
            {openIframe && <CustomPlayerVisio url={visioContent?.url} />}

            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                    mr: 2,
                    position: 'absolute',
                    left: '25px',
                    zIndex: {
                        md: '0',
                        lg: '0',
                        sm: '101',
                    },
                }}
            >
                <MenuIcon />
            </IconButton>

            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        position: 'relative',

                        paddingTop: '50px',
                    },
                }}
            >
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, position: 'absolute', right: '5px', top: '0' }}
                >
                    <CancelIcon />
                </IconButton>
                {drawer}
            </Drawer>

            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'block' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        position: 'relative',
                        zIndex: 0,
                    },
                }}
                open
            >
                {drawer}
            </Drawer>
        </div>
    );
};

export default MenuSideBarCpts;
