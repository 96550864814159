import { Box, Grid, IconButton, Typography } from '@mui/material';
import React, { FC, useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { ROUTES } from 'src/routes/routes';
import { CustomRadio } from '../../components/InputField/CustomRadio';
import { postNewProfessional } from '../../services/User/user.service';
import { ProfessionalCpts } from '../../interfaces/interfaces';
import { InsertDriveFile } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { InputFieldPassword } from '../../components/InputField/InputFieldPassword';
import {
    isNotBlank,
    isValidMail,
    isNumber,
    isPhoneNumber,
    isValidPassword,
    isSamePassword,
    isValidRpps,
} from 'src/utils/helpers/InputValidator';
import { allowedExtension, defaultValue, validPatientData, SignOutInputValidation } from './LoginLogic';
import { toast } from 'react-toastify';
import { SignUpMessage } from 'src/utils/constants/constants';
import IvehteCptsLogo from 'src/components/IvehteCptsLogo/IvehteCptsLogo';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { getAttendeesSpeciality } from 'src/services/Attendees';
import { UserContext } from 'src/providers/UserProvider';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import MailJetableService from 'src/services/MailJetable/mailJetable.service';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { Spacer } from 'src/components/Spacer/Spacer';
import RegistrationInput from './RegistrationInput/RegistrationInput';

export const ProfessionalForm: FC = () => {
    const history = useHistory();
    const [loadingProfessionalForm, setLoadingProfessionalForm] = useState(false);
    const [lockProfessionalInput, setLockProfessionalInput] = useState(false);
    const [professional, setProfessional] = useState<ProfessionalCpts>(defaultValue);
    const { isETP, setIsETP } = useContext(UserContext);
    const [file, setFile] = useState<string>('');
    const [docName, setDocName] = useState<string>('');
    const [fetchingSpeciality, setFetchingSpeciality] = useState<boolean>(false);
    const [specialites, setSpecialites] = useState<Array<any>>([]);
    const specialisteData = [{ id: 0, name: 'Sélectionner une spécialité' }, ...specialites];

    let docType = '';

    const setAllStateToFalse = () => {
        setLoadingProfessionalForm(false);
        setLockProfessionalInput(false);
    };

    const perfomNewProfessional = () => {
        setLoadingProfessionalForm(true);
        setLockProfessionalInput(true);
        MailJetableService.getMailJetable().then((response) => {
            let testEmail = professional.email;
            if (testEmail) {
                let afterArobase = testEmail.split('@')[1];
                let mailJetableArray: any[] = [];
                let responses = response?.data?.data;
                responses.map((element: any) => mailJetableArray.push(element.name.toLowerCase()));
                if (mailJetableArray.includes(afterArobase.toLowerCase())) {
                    setLoadingProfessionalForm(false);
                    setLockProfessionalInput(false);
                    toast.error("Votre adresse email n'est pas une adresse professionnelle");
                } else {
                    if (isSamePassword(professional?.password?.first, professional?.password?.second)) {
                        postNewProfessional(professional)
                            .then((response: any) => {
                                const responses = response?.data;
                                if (responses?.code === 400 && responses?.message === 'User rpps is already exist!') {
                                    setAllStateToFalse();
                                    toast.error('Le numéro RPPS est déjà utilisé.');
                                } else if (
                                    responses?.code === 400 &&
                                    responses?.message === 'User email is already exist!'
                                ) {
                                    setAllStateToFalse();
                                    toast.error('Le email est déjà utilisé.');
                                }else {
                                    setAllStateToFalse();
                                    toast.success(SignUpMessage.success);
                                    setProfessional(defaultValue);
                                    history.push(ROUTES.loginCpts);
                                }
                            })
                            .catch(() => {
                                toast.error(SignUpMessage.error);
                                setAllStateToFalse();
                                setProfessional((old) => ({ ...old, password: { first: '', second: '' } }));
                            });
                    } else {
                        toast.error('Les mots de passe ne sont pas identiques');
                        setProfessional((old) => ({ ...old, password: { first: '', second: '' } }));
                        setAllStateToFalse();
                    }
                }
            }
        });
    };

    const handleFecthData = async () => {
        setFetchingSpeciality(true);
        getAttendeesSpeciality().then((response: any) => {
            const responses = response?.data?.data?.items;
            setSpecialites(responses);
            setFetchingSpeciality(false);
        });
    };

    useEffect(() => {
        handleFecthData();
    }, []);

    const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.currentTarget.files;
        if (files && files?.length > 0) {
            let file = files[0];
            docType = file?.type;
            setDocName(file.name);

            if (!allowedExtension.includes(file.type)) {
                alert('fichier non supportée , veuillez importer des .word, .jpeg, .jpg, .png, .pdf, .csv, .txt');
                setDocName('');
            } else {
                const reader = new FileReader();
                reader.onload = _handleReaderLoaded;
                reader.readAsBinaryString(file);
            }
        }
    };

    const validPatientETP = (professional: SignOutInputValidation) =>
        isNotBlank(professional?.firstName) &&
        isNotBlank(professional?.lastName) &&
        isNumber(professional?.rpps) &&
        isValidMail(professional?.email) &&
        professional?.specialityTypeId !== 0 &&
        isPhoneNumber(professional?.phoneNumber) &&
        isValidRpps(professional?.rpps) &&
        isValidPassword(professional?.password?.first) &&
        isSamePassword(professional?.password?.first, professional?.password?.second) &&
        docName !== '';

    const _handleReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        if (docType.length > 0) {
            let fileContent = `data:${docType};base64,${btoa(binaryString)}`;
            setFile(`data:${docType};base64,${btoa(binaryString)}`);
            setProfessional((old) => ({ ...old, filecontent: fileContent, etpCertificateFileContent: fileContent }));
        }
    };

    return (
        <Grid p={1} paddingBottom={5}>
            <Link to={ROUTES.login}>
                <Box style={{ padding: 2 }}>
                    <IvehteCptsLogo width={80} height={80} />
                </Box>
            </Link>

            <Grid
                container
                justifyContent={'center'}
                justifyItems={'center'}
                alignItems={'center'}
                alignContent={'center'}
            >
                <Grid
                    flexGrow={1}
                    item
                    sm={8}
                    xs={8}
                    md={6}
                    lg={6}
                    style={{
                        borderRadius: 10,
                        padding: 20,
                        background: baseColors.green.light,
                    }}
                >
                    <CustomText
                        style={{ textAlign: 'center', marginBottom: 10, marginTop: 10 }}
                        fontFamily={baseFontFamily.BreeSerif}
                        fontSize={baseFontSize.title}
                        color={baseColors.green.primary}
                    >
                        Inscription professionnel de santé externe
                    </CustomText>

                    <RegistrationInput
                        label="Nom"
                        regexLabel="(obligatoire)"
                        keyValue="firstName"
                        placeholder="Entrer votre nom"
                        setProfessional={setProfessional}
                        lockProfessionalInput={lockProfessionalInput}
                    />

                    <RegistrationInput
                        label="Prénom"
                        regexLabel="(obligatoire)"
                        keyValue="lastName"
                        placeholder="Entrer votre prénom"
                        setProfessional={setProfessional}
                        lockProfessionalInput={lockProfessionalInput}
                    />

                    <RegistrationInput
                        label="Numéro RPPS"
                        maxLength={11}
                        regexLabel={
                            professional?.rpps?.length < 11 && professional?.rpps?.length !== 0
                                ? '( Le numéro RPPS doit avoir au moins 11 chiffres )'
                                : '(obligatoire)'
                        }
                        keyValue="rpps"
                        placeholder="Entrer votre numéro RPPS"
                        setProfessional={setProfessional}
                    />

                    <RegistrationInput
                        label="Adresse email"
                        regexLabel={
                            !isValidMail(professional?.email) && professional?.email.length > 0
                                ? "(Vérifier que l'email est bien correct)"
                                : '(obligatoire)'
                        }
                        keyValue="email"
                        placeholder="Entrer votre adresse email"
                        setProfessional={setProfessional}
                        validator={isValidMail}
                    />

                    <Grid item flexGrow={1} justifyContent={'center'} style={{ paddingLeft: '3%' }}>
                        <CustomText color={baseColors.black} fontSize={baseFontSize.sl}>
                            Téléphone :
                        </CustomText>
                        <PhoneInput
                            isValid={(inputNumber) => inputNumber?.length > 10}
                            country="fr"
                            inputStyle={{
                                backgroundColor: 'transparent',
                                width: '97%',
                                height: 45,
                            }}
                            countryCodeEditable={false}
                            value={professional.phoneNumber}
                            onChange={(phone) => setProfessional((old) => ({ ...old, phoneNumber: '+' + phone }))}
                        />
                        <Spacer />
                    </Grid>

                    <Grid item flexGrow={1} justifyContent={'center'} style={{ paddingLeft: '3%' }}>
                        <InputFieldPassword
                            width="97%"
                            type="password"
                            disabled={lockProfessionalInput}
                            regexLabel={
                                professional?.password?.first.trim().length > 0 &&
                                !isValidPassword(professional?.password?.first)
                                    ? '(Le mot de passe doit comporter au moins 8 caractères, une minuscule, une majuscule, un chiffre et un  caractère spécial.)'
                                    : '(obligatoire)'
                            }
                            onChange={(value: any) =>
                                setProfessional((old) => ({ ...old, password: { ...old.password, first: value } }))
                            }
                            label="Mot de passe :"
                            value={professional?.password?.first}
                            placeholder="Entrer votre mot de passe"
                        />
                    </Grid>

                    <Grid item flexGrow={1} justifyContent={'center'} style={{ paddingLeft: '3%' }}>
                        <InputFieldPassword
                            width="97%"
                            type="password"
                            disabled={lockProfessionalInput}
                            onChange={(value: any) =>
                                setProfessional((old) => ({ ...old, password: { ...old.password, second: value } }))
                            }
                            regexLabel={
                                professional?.password?.second.trim().length > 0 &&
                                !isSamePassword(professional?.password?.first, professional?.password?.second)
                                    ? '(Vérifier que les deux mots de passe sont identiques)'
                                    : '(obligatoire)'
                            }
                            label="Confirmer le mot de passe :"
                            value={professional?.password?.second}
                            placeholder="Confirmer votre mot de passe"
                        />
                    </Grid>

                    <Grid item flexGrow={1} justifyContent={'center'} style={{ paddingLeft: '3%' }}>
                        <Grid item>
                            <CustomText
                                style={{ marginBottom: 10 }}
                                color={baseColors.black}
                                fontSize={baseFontSize.sl}
                            >
                                Spécialité :
                            </CustomText>
                        </Grid>

                        <Grid item style={{ width: '97%' }}>
                            <CustomSelect
                                onChange={(value: any) => {
                                    setProfessional((old: any) => ({ ...old, specialityTypeId: value ?? 0 }));
                                }}
                                label=""
                                labelKey="name"
                                disabled={lockProfessionalInput}
                                items={specialisteData}
                                defaultValue={professional?.specialityTypeId ?? 0}
                                idSelect="role"
                                width="100%"
                            />
                        </Grid>
                    </Grid>

                    <Grid item flexGrow={1} justifyContent={'center'} style={{ paddingLeft: '3%', marginTop: 15 }}>
                        <CustomText
                            color={baseColors.black}
                            fontSize={baseFontSize.sm}
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                        >
                            Avez-vous suivi la formation ETP (Education Thérapeutique du Patient) ?
                        </CustomText>

                        <Grid container>
                            <Grid style={{ marginLeft: -10 }} container direction={'row'}>
                                <Grid item>
                                    <CustomRadio
                                        checked={isETP === 1}
                                        onClick={() => {
                                            setProfessional((old) => ({ ...old, hasEtp: false }));
                                            setProfessional((old) => ({ ...old, checkEtp: 1 }));
                                            setIsETP(1);
                                        }}
                                        label={'Oui'}
                                    ></CustomRadio>
                                </Grid>
                                <Grid item>
                                    <CustomRadio
                                        checked={isETP === 0}
                                        onClick={() => {
                                            setProfessional((old) => ({ ...old, hasEtp: false }));
                                            setProfessional((old) => ({ ...old, checkEtp: 0 }));
                                            setIsETP(0);
                                            setDocName('');
                                            setFile('');
                                            setProfessional((old) => ({
                                                ...old,
                                                filecontent: '',
                                                etpCertificateFileContent: '',
                                            }));
                                        }}
                                        label={'Non'}
                                    ></CustomRadio>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {isETP === 1 && (
                        <Grid
                            lg={12}
                            container
                            direction="row"
                            alignItems="center"
                            marginBottom="20px"
                            style={{ paddingLeft: '3%' }}
                        >
                            <Grid item>
                                <CustomText style={{ marginRight: 10 }} fontFamily={baseFontFamily.OpenSansSemiBold}>
                                    Veuillez nous fournir un justificatif de votre formation :
                                </CustomText>
                            </Grid>
                            <div>
                                <input
                                    accept="*/*"
                                    id="icon-button-file"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleChangeFile}
                                />
                                <label htmlFor="icon-button-file">
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        {file?.length !== 0 ? (
                                            <CustomText style={{ textAlign: 'center' }} fontSize={11}>
                                                {docName === '' ? 'Cliquer pour joindre un fichier' : docName}
                                            </CustomText>
                                        ) : (
                                            <InsertDriveFile />
                                        )}
                                    </IconButton>
                                </label>
                            </div>
                        </Grid>
                    )}

                    <Grid
                        direction={'row'}
                        container
                        justifyContent={'center'}
                        style={{ marginTop: 20, marginBottom: 10 }}
                    >
                        <CustomLoadingButton
                            width="80%"
                            disabled={isETP === 0 ? !validPatientData(professional) : !validPatientETP(professional)}
                            loading={loadingProfessionalForm}
                            handleClick={perfomNewProfessional}
                            label="Créer mon compte"
                        />
                    </Grid>

                    <Grid
                        direction={'row'}
                        container
                        justifyContent={'center'}
                        style={{ marginTop: 20, marginBottom: 10 }}
                    >
                        <Typography
                            style={{ fontSize: baseFontSize.sm, marginRight: 10 }}
                            color={baseColors.green.primary}
                        >
                            J'ai déjà un compte,
                        </Typography>
                        <Link to={ROUTES.login} style={{ textDecoration: 'none' }}>
                            <Typography
                                style={{
                                    fontSize: baseFontSize.sm,
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }}
                                color={baseColors.green.primary}
                            >
                                Se connecter
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
