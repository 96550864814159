import { Box } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import DashboardWeeklyDayPicker from 'src/components/DashboardWeeklyDayPicker';
import useWindowDimensions from 'src/components/GetWindowSize/GetWindowSize';
import { daysCounts, formatDateFormat } from 'src/utils/utils';
import PlannifiedWorkShops from './PlannifiedWorkShops';
import SelectedDayRdv from './SelectedDayRdv';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/stores/store';
import { retrieveAppointments, retrieveDailyPatientsByDate } from 'src/actions/dashboard/dashboard';
import { AgendaContext } from 'src/providers/AgendaProvider';
import InvitationList from './InvitationList/InvitationList';
import { UserContext } from 'src/providers/UserProvider';
import { CustomText } from 'src/components/Text/CustomText';
import { baseFontSize } from 'src/utils/constants/baseStyles';

const IncomingRdv: FC = () => {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState<string>(formatDateFormat(new Date()));
    const { width } = useWindowDimensions();
    const data = useSelector((state: RootState) => state.dashboard);
    const patientsOfDay = data?.appointments;
    const patientsOfDayByDate = data.dailyPatientsByDate;

    const [updateList, setUpdateList] = useState<boolean>(false);
    const [patientOfTheDayLoading, setPatientOfTheDayLoading] = useState<boolean>(false);
    const [loadingWorkshops, setLoadingWorkshops] = useState<boolean>(false);

    const dispatch = useDispatch();
    const { updateEventList } = useContext(AgendaContext);
    const { isDoctor, userInfos } = useContext(UserContext);

    useEffect(() => {
        dispatch(retrieveAppointments(selectedDate, setPatientOfTheDayLoading));
        dispatch(retrieveDailyPatientsByDate(selectedDate, setLoadingWorkshops));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate, updateList, updateEventList]);

    return (
        <Box className={classes.container}>
            <Box style={{ paddingTop: '20px' }} className={classes.header} paddingTop={1} paddingLeft={1}>
                <CustomText fontSize={baseFontSize.md} isBreefSerif>
                    Votre agenda des prochains jours
                </CustomText>
            </Box>

            <Box paddingRight={2} paddingLeft={2}>
                <DashboardWeeklyDayPicker daysCount={daysCounts(width)} setSelectedDate={setSelectedDate} />
            </Box>

            <Box
                style={{
                    paddingRight: 5,
                    paddingLeft: 10,
                    overflow: 'hidden',
                    overflowY: 'scroll',
                    marginTop: 25,
                }}
                className={classes.content}
            >
                {isDoctor && userInfos?.hasEtp === 1 && <InvitationList />}

                <Box
                    className={classes.header}
                    style={{
                        marginTop: 25,
                    }}
                >
                    <CustomText fontSize={baseFontSize.md} isBreefSerif>
                        Mes événements planifiés
                    </CustomText>
                </Box>

                <SelectedDayRdv loading={patientOfTheDayLoading} selectedDate={selectedDate} dataRdv={patientsOfDay} />

                <Box
                    className={classes.header}
                    style={{
                        marginTop: 25,
                    }}
                >
                    <CustomText fontSize={baseFontSize.md} isBreefSerif>
                        Mes ateliers planifiés
                    </CustomText>
                </Box>

                <PlannifiedWorkShops selectedDate={selectedDate} dataWorkshop={patientsOfDayByDate} />
            </Box>
        </Box>
    );
};

export default IncomingRdv;
