import { useContext, useState } from 'react';
import { ProgramItem } from 'src/interfaces/interfaces';
import { PatientContext } from 'src/providers/PatientProvider';
import ProgramService from 'src/services/Program/program.service';
import { ProgramContext } from 'src/providers/ProgramProvider';
import { programsWithoutDraft } from 'src/views/Patients/PatientsDetails/Programs/AddProgam/AddProgramLogic';
import {
    useFetchPathologies,
    useFetchPatientPrograms,
    useFetchPreselctedPatientPrograms,
} from './ReactQueryHooks/useProgramRQHooks';

const optimizedProgramData = (programes: Array<any>) =>
    Array.from(programes, (program: ProgramItem) => ({
        id: program?.id,
        name: program?.title,
    }));

export const useFetchUserPrograms = ({ userId }: { userId?: number }) => {
    const [fetchingAllPrograms, setFetchingAllPrograms] = useState(false);
    const [allPrograms, setAllPrograms] = useState<any>([]);
    const { setCurrentPatientProgram } = useContext(PatientContext);
    const { selectedDEProgramId } = useContext(ProgramContext);

    const { data: userPrograms, isLoading: fetchingUserPrograms } = useFetchPatientPrograms({ patientId: userId ?? 0 });

    const {
        data: preSelectedPatientProgram,
        isLoading: fetchingPreselectedProgram,
        refetch: refetchPreselectedProgram,
    } = useFetchPreselctedPatientPrograms({ patientId: userId ?? 0 });

    const { data: pathologies, isLoading: fetchingPathologies } = useFetchPathologies();

    if (userPrograms?.length) {
        setCurrentPatientProgram(userPrograms);
    }

    const [selectedProgramAxis, setSelectedProgramAxis] = useState<any>([]);
    const [fetchingProgramAxis, setFetchingProgramAxis] = useState(false);

    const fetchUserPrograms = () => {};

    const fetchPreselectedProgram = () => {};

    const fetchAllPrograms = () => {
        setFetchingAllPrograms(true);
        ProgramService.getPrograms()
            .then((responses: any) => {
                const items = responses?.data?.data?.items;
                const data = programsWithoutDraft(items);
                setAllPrograms(optimizedProgramData(data));
                setFetchingAllPrograms(false);
            })
            .catch((err: any) => {
                setFetchingAllPrograms(false);
            });
    };

    const fetchProgramAxis = () => {
        setFetchingProgramAxis(true);
        ProgramService.getProgramAxis(userId ?? 0)
            .then((responses: any) => {
                const data = responses?.data?.data;
                if (selectedDEProgramId > 0) {
                    const program = data?.find((item: any) => item.id === selectedDEProgramId);
                    const programAxis = program?.axis ?? [];
                    setSelectedProgramAxis(programAxis);
                }
                setFetchingProgramAxis(false);
            })
            .catch((err: any) => {
                setFetchingProgramAxis(false);
            });
    };

    const disableMenu = fetchingUserPrograms;

    return {
        fetchUserPrograms,
        disableMenu,
        fetchingUserPrograms,
        userPrograms,

        fetchPreselectedProgram,
        refetchPreselectedProgram,
        fetchingPreselectedProgram,
        preSelectedPatientProgram,

        fetchAllPrograms,
        allPrograms,
        fetchingAllPrograms,

        selectedProgramAxis,
        fetchingProgramAxis,
        fetchProgramAxis,

        pathologies,
        fetchingPathologies,
    };
};
