import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState, useContext } from 'react';
import AddIntervenants from 'src/components/CustomModal/AddIntervenants';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { CustomText } from 'src/components/Text/CustomText';
import { getAttendeesBySpeciality } from 'src/services/Attendees';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { fullName } from 'src/utils/utils';
import { sxStyles } from '../styles';
import { UserContext } from 'src/providers/UserProvider';
import { Spacer } from 'src/components/Spacer/Spacer';
import { useFetchSpecialitiesRQHooks } from 'src/hooks/ReactQueryHooks/useEvenRQtHooks';

interface EventIntervenantProps {
    setEventPayload?: any;
    defaultSpecialityId?: number;
    loadingData?: any;
    eventData?: any;
    createEventFromBtnAction?: boolean;
    editFromDnd?: boolean;
}

const EventIntervenant: FC<EventIntervenantProps> = (props) => {
    const { setEventPayload, loadingData, defaultSpecialityId, eventData, createEventFromBtnAction, editFromDnd } =
        props;
    const [loadingIntervenants, setLoadingIntervenants] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { userInfos } = useContext(UserContext);
    const { isDoctor } = useContext(UserContext);
    const [selectedIntervenants, setSelectedIntervenants] = useState<Array<any>>([]);
    const [attendes, setAttendes] = useState<any>([]);
    const [loadingAttendes, setLoadingAttendes] = useState<boolean>(false);
    // const [specialities, setSpecialities] = useState<Array<any>>([]);
    const [patientsIds, setPatientsIds] = useState<Array<any>>([]);
    const [validated, setValidated] = useState<boolean>(false);
    const specialityIdDefaultValues = createEventFromBtnAction
        ? -1
        : eventData?.specialityType?.id ?? defaultSpecialityId ?? -1;
    const [specialityId, setSpecialityId] = useState<number>(specialityIdDefaultValues);

    const { data: specialityData, isFetching: loadingSpeciality } = useFetchSpecialitiesRQHooks();

    const specialities = loadingSpeciality ? [] : specialityData;

    // useEffect(() => {
    //     if (specialityData?.length > 0) {
    //         setSpecialities(specialityData);
    //     }
    // }, [specialityData]);

    const handleGetAttendeesBySpeciality = (spcId: number) => {
        setLoadingAttendes(true);
        getAttendeesBySpeciality(spcId, true, true).then((response) => {
            const responses = response?.data?.data;
            setAttendes(responses ?? []);
            setLoadingAttendes(false);
        });
    };

    const handleSetIntervenantIds = () => {
        const precheckedIntervenants =
            eventData?.professionals?.length > 0 &&
            eventData?.professionals?.map((intervenent: any) => intervenent?.attendeeUser?.id);

        const precheckedIntervenantsInfos =
            eventData?.professionals?.length > 0 &&
            eventData?.professionals?.map((intervenent: any) => intervenent?.attendeeUser);

        precheckedIntervenants?.length > 0 && setPatientsIds(precheckedIntervenants);
        precheckedIntervenantsInfos?.length > 0 && setSelectedIntervenants(precheckedIntervenantsInfos);
    };

    const handleSetSpeciality = (eventData: number) => {
        handleGetAttendeesBySpeciality(eventData);
        setPatientsIds([]);
        setSelectedIntervenants([]);
        setEventPayload((prev: any) => ({
            ...prev,
            specialityTypeId: eventData,
            attendees: [],
        }));
    };

    useEffect(() => {
        // handleFecthData();
        return () => setSpecialityId(-1);
    }, []);

    useEffect(() => {
        if (!editFromDnd) handleSetIntervenantIds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventData, editFromDnd]);

    useEffect(() => {
        handleGetAttendeesBySpeciality(specialityId);
    }, [specialityId]);

    useEffect(() => {
        if (isDoctor) {
            const specialityId = userInfos?.specialityType?.id;
            setEventPayload((prev: any) => ({
                ...prev,
                specialityTypeId: userInfos?.specialityType?.id || specialityId,
                attendees: [{ id: userInfos.id, type: 2 }],
            }));
        }
    }, [userInfos, isDoctor]);

    useEffect(() => {
        if (eventData) setValidated(true);
    }, []);

    const toggleModal = () => setModalOpen(!modalOpen);
    const specialisteData = [{ id: -1, name: 'Sélectionner une spécialité' }, ...specialities];

    return (
        <>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item>
                    <CustomText>Spécialité de l'intervenant :</CustomText>
                </Grid>

                <Grid item style={{ width: '60%' }}>
                    {loadingSpeciality ? (
                        loadingData
                    ) : (
                        <CustomSelect
                            onChange={(value: any) => handleSetSpeciality(value)}
                            label=""
                            disabled={isDoctor}
                            labelKey="name"
                            items={specialisteData}
                            defaultValue={isDoctor ? userInfos?.specialityType?.id : specialityId}
                            idSelect="specialite-intervenant"
                            width="100%"
                        />
                    )}
                </Grid>
            </Grid>

            <Spacer />

            <Grid container direction="row" alignItems={'center'} justifyContent="space-between">
                <AddIntervenants
                    toggleModal={toggleModal}
                    modalOpen={modalOpen}
                    setEventPayload={setEventPayload}
                    intervenants={isDoctor ? userInfos : attendes}
                    setSelectedIntervenants={setSelectedIntervenants}
                    patientsIds={patientsIds}
                    setPatientsIds={setPatientsIds}
                    loadingAttendees={loadingAttendes}
                    setValidated={setValidated}
                />

                <Grid item>
                    <CustomText>Intervenant(s) :</CustomText>
                </Grid>

                {isDoctor ? (
                    <Grid item style={{ width: '60%' }}>
                        {loadingIntervenants ? (
                            loadingData
                        ) : (
                            <Box style={sxStyles.intervenantContainer}>
                                <CustomText
                                    fontFamily={baseFontFamily.OpenSansSemiBold}
                                    color={baseColors.green.primary}
                                    fontSize={baseFontSize.mini}
                                >
                                    {fullName(userInfos?.firstName, userInfos?.lastName)}
                                </CustomText>
                            </Box>
                        )}
                    </Grid>
                ) : (
                    <Grid item style={{ width: '60%' }}>
                        {loadingIntervenants ? (
                            loadingData
                        ) : (
                            <Box
                                onClick={() => {
                                    toggleModal();
                                    setValidated(false);
                                }}
                                style={sxStyles.intervenantContainer}
                            >
                                {validated && selectedIntervenants?.length > 0 ? (
                                    selectedIntervenants?.map((item: any) => (
                                        <CustomText
                                            key={item?.id}
                                            fontFamily={baseFontFamily.OpenSansSemiBold}
                                            color={baseColors.green.primary}
                                            fontSize={baseFontSize.mini}
                                        >
                                            {fullName(item?.firstName, item?.lastName)}
                                        </CustomText>
                                    ))
                                ) : (
                                    <CustomText
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                        color={baseColors.green.primary}
                                        fontSize={baseFontSize.mini}
                                    >
                                        Sélectionner un ou plusieurs intervenant(s)
                                    </CustomText>
                                )}
                            </Box>
                        )}
                    </Grid>
                )}
            </Grid>

            <Spacer />
        </>
    );
};

export default EventIntervenant;
