const baseColors = {
    green: {
        primary: '#0087CB',
        secondary: '#92bbe1',
        dark: '#015E8D',
        pastel: '#92BBE1',
        light: 'rgb(146 ,187 ,225 ,0.3)',
    },
    orange: {
        primary: '#CA5353',
        secondary: '#424242',
        flash: '#FF821F',
        dark: '#8D3A3A',
        light: '#FFF9F5',
    },
    white: '#FFFFFF',
    black: '#424242',
    grey: '#BFC8C9',
};

const basePadding = {
    paddingHorizontal: 20,
    paddingVertical: 18,
};

const baseMarging = {
    marginVertical: 50,
    marginHorizontal: 50,
    marginBottom: 18,
};

const baseFontSize = {
    mini: '0.775rem', //12px
    sm: '0.875rem', //14px
    sl: '1rem', //16px
    md: '1.125rem', //18px
    title: '1.375rem', //22px
    smTitle: '1.5rem', //24px
    lg: '1.75rem', //28px
};

const baseFontFamily = {
    OpenSansRegular: 'OpenSans Regular',
    OpenSansSemiBold: 'OpenSans SemiBold',
    OpenSansBold: 'OpenSans Bold',
    BreeSerif: "'Bree Serif', serif",
};

const baseScreenHeight = {
    vh: '88vh',
    defaultHeight: {
        height: `calc(100vh - 80px)`,
    },
    tableContainerHeight: 'calc(100vh - 150px)',
    patientDetailsHeight: 'calc(100vh - 350px)',
    securitySubMenusHeight: 'calc(100vh - 180px)',
    newEventModal: 'calc(100vh - 200px)',
    programSynthese: `calc(100vh - 320px)`,
    modalForm: 'calc(100vh - 250px)',
    checkBoxItems: 'calc(90vh - 460px)',
};

const baseFontWeight = {
    thin: 100,
    extraLight: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
};

const planningEventColors = {
    color: {
        orange: '#F0730F',
    },
    backgroundColor: {
        orange: '#FDCFAA',
    },
};

const screenSize = {
    minScreenSize: 1110,
};

const border = 7;

const baseBorderRadius = {
    borderRadius: border,
    rightOnly: {
        borderTopRightRadius: border,
        borderBottomRightRadius: border,
    },
    leftOnly: {
        borderTopLeftRadius: border,
        borderBottomLeftRadius: border,
    },
    allSide: {
        borderTopRightRadius: border,
        borderBottomRightRadius: border,
        borderTopLeftRadius: border,
        borderBottomLeftRadius: border,
    },
};

const baseRgbColors = {
    white: 'rgba(255, 255, 255)',
};

const eventColorByRoom = [
    {
        color: baseColors.white,
        backgroundColor: baseColors.green.primary,
    },
    {
        color: baseColors.white,
        backgroundColor: baseColors.green.secondary,
    },
    {
        color: baseColors.white,
        backgroundColor: baseColors.green.dark,
    },
    {
        color: baseColors.white,
        backgroundColor: baseColors.green.pastel,
    },
    {
        color: baseColors.green.primary,
        backgroundColor: baseColors.green.light,
    },
    {
        color: baseColors.white,
        backgroundColor: baseColors.orange.primary,
    },
    {
        color: baseColors.white,
        backgroundColor: baseColors.orange.secondary,
    },
    {
        color: baseColors.white,
        backgroundColor: baseColors.orange.flash,
    },
    {
        color: baseColors.white,
        backgroundColor: baseColors.orange.dark,
    },
    {
        color: baseColors.green.primary,
        backgroundColor: baseColors.green.light,
    },
];

const Buttons = {
    width: '80%',
    margin: 10,
};

export {
    Buttons,
    baseColors,
    basePadding,
    baseMarging,
    baseFontSize,
    baseFontFamily,
    baseScreenHeight,
    baseFontWeight,
    planningEventColors,
    screenSize,
    baseRgbColors,
    baseBorderRadius,
    eventColorByRoom,
};
