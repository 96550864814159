import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
        <rect width={40} height={40} fill="#fff" rx={10} />
        <path
            fill="#CA5353"
            fillRule="evenodd"
            d="M13.379 14.879c-.879.878-.879 2.293-.879 5.121 0 2.828 0 4.243.879 5.121.878.879 2.293.879 5.121.879h3c2.828 0 4.243 0 5.121-.879.879-.878.879-2.293.879-5.121 0-2.828 0-4.243-.879-5.121C25.743 14 24.328 14 21.5 14h-3c-2.828 0-4.243 0-5.121.879Zm11.553 1.76a.562.562 0 0 1-.072.793l-1.647 1.373c-.665.554-1.204 1.003-1.68 1.309-.495.318-.977.52-1.533.52-.556 0-1.038-.202-1.534-.52-.475-.306-1.014-.755-1.679-1.31l-1.647-1.372a.562.562 0 1 1 .72-.864l1.62 1.35c.699.582 1.185.986 1.595 1.25.397.255.666.34.925.34s.528-.085.925-.34c.41-.264.896-.668 1.596-1.25l1.619-1.35a.562.562 0 0 1 .792.072Z"
            clipRule="evenodd"
        />
    </svg>
);
export { SvgComponent as ContactProfilIcon };
