import { NewHDJ, LinkToAnotherHdjPayload } from 'src/interfaces/interfaces';
import axios from '../AxiosInstance';
import qs from 'qs';

const getAll = () => axios.get('api/dayHospitals?limit=50');

const hdjFilter = (keywords: string, filterByName: string, specialityTypeId: number) => {
    let additionalUrl: string = '';

    if (keywords !== '' && filterByName !== '') {
        additionalUrl = `?keywords=${keywords}&sortName=${filterByName}`;
    }
    if (keywords !== '' && filterByName === '') {
        additionalUrl = `?keywords=${keywords}`;
    }
    if (keywords === '' && filterByName !== '') {
        additionalUrl = `?sortName=${filterByName}`;
    }
    if (specialityTypeId > 0) {
        additionalUrl = `?specialityTypeId=${specialityTypeId}`;
    }

    return axios.get('api/dayHospitals' + additionalUrl);
};

const getPlannig = (
    planningBy: 'speciality' | 'room',
    checkedDate: string,
    hdjIds: number[],
    filterIds: {
        filterId?: number;
        consultationEventTypeId?: number;
    },
) => {
    let additionalUrl: string = '';

    if (filterIds?.filterId && filterIds?.filterId > 0) {
        if (planningBy === 'speciality'){
            additionalUrl += `&specialityId=${filterIds.filterId}`;
        }
        if (planningBy === 'room') {
            additionalUrl += `&upstairsId=${filterIds.filterId}`;
        }
    }

    if (filterIds?.consultationEventTypeId && filterIds?.consultationEventTypeId > 0) {
        additionalUrl += `&consultationEventTypeId=${filterIds.consultationEventTypeId}`;
    }

    const dayHospitalIds = hdjIds;

    return axios.get(`api/events/dayHospital?filterBy=${planningBy}&date=${checkedDate + additionalUrl}`, {
        params: {
            dayHospitalIds: dayHospitalIds?.length > 0 ? dayHospitalIds : [0],
        },
        paramsSerializer: (params) => {
            return qs.stringify(params);
        },
    });
};

const getDayHospitalById = (id: number) => axios.get(`api/dayHospitals/${id}`);

const getAllConsultationFilters = (status: number) => axios.get(`api/eventTypes?isConsultation=${status}`);

const create = (payload: NewHDJ) => axios.post('api/dayHospitals', payload);

const update = (hdjId: number, payload: NewHDJ) => axios.put(`api/dayHospitals/${hdjId}`, payload);

const deleteById = (id: string) => axios.delete(`api/dayHospitals/${id}`);

const getHdjSpecialities = () => axios.get(`api/specialityTypes`);

const cloneHDJ = (id: any, name: any, date: Date) =>
    axios.post('api/dayHospitals/clone', { dayHospitalId: id, name: name, date: date });

const linkToAnotherHdj = (payload: LinkToAnotherHdjPayload) => axios.put(`api/dayHospitals/${payload.hdjId}`, payload.data);

const HdjDataService = {
    getAll,
    getPlannig,
    getDayHospitalById,
    getAllConsultationFilters,
    create,
    update,
    deleteById,
    hdjFilter,
    getHdjSpecialities,
    cloneHDJ,
    linkToAnotherHdj
};

export default HdjDataService;
