import { Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/components/Button/CustomButton';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { ROUTES } from 'src/routes/routes';
import { activateUserByToken } from 'src/services/User/user.service';
import { GppGood } from '@mui/icons-material';
import IvehteCptsLogo from 'src/components/IvehteCptsLogo/IvehteCptsLogo';

const UserActivation = () => {
    useEffect(() => {
        const queryParameter = new URLSearchParams(window.location.search);
        var tokenInput = queryParameter.get('token');
        activateUserByToken(tokenInput);
    }, []);

    return (
        <Grid>
            <Link to={ROUTES.login}>
                <Box style={{ padding: 2 }}>
                    <IvehteCptsLogo width={80} height={80} />
                </Box>
            </Link>
            <Grid
                container
                justifyContent={'center'}
                justifyItems={'center'}
                alignItems={'center'}
                alignContent={'center'}
            >
                <Grid
                    flexGrow={1}
                    item
                    sm={8}
                    xs={8}
                    md={4}
                    lg={4}
                    style={{
                        borderRadius: 10,
                        padding: 20,
                        background: baseColors.green.light,
                        marginTop: '3%',
                    }}
                >
                    <Grid>
                        <Grid container justifyContent={'center'}>
                            <GppGood
                                style={{ alignSelf: 'center', marginBottom: 10 }}
                                htmlColor={baseColors.green.primary}
                                fontSize={'large'}
                            ></GppGood>
                        </Grid>
                        <CustomText
                            fontFamily={baseFontFamily.BreeSerif}
                            style={{ textAlign: 'center', marginBottom: 20 }}
                        >
                            Votre compte a été activé avec succès.
                        </CustomText>
                        <Grid container justifyContent={'center'}>
                            <Link to={ROUTES.login} style={{ textDecoration: 'none' }}>
                                <CustomButton
                                    size={'large'}
                                    lower
                                    width={'100%'}
                                    onClick={() => {}}
                                    label={'Se connecter'}
                                ></CustomButton>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UserActivation;
