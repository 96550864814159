import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { DefaultObservationPayload, UserAxisItem } from 'src/interfaces/interfaces';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import useStyles from './styles';
import { InputField } from 'src/components/InputField/InputField';
import AnswerContent from './AnswerContent';
import { handleSetDEObservation, responseTypeValue } from '../Conclusion/Logic';
import CheckboxAnswerContent from './CheckboxAnswerContent';
import { SALUTANCE } from 'src/utils/constants/constants';

interface DEQuestionResponsesProps {
    content: UserAxisItem;
    questionNumber: number;
    posting: boolean;
    setPayload: Dispatch<SetStateAction<DefaultObservationPayload>>;
    setPosting: Dispatch<SetStateAction<boolean>>;
    canValidate: boolean;
}

const DEQuestionResponses: FC<DEQuestionResponsesProps> = ({
    canValidate,
    posting,
    questionNumber,
    setPayload,
    content,
}) => {
    const classes = useStyles();
    const { survey, response, precision, userSurveyId, valueTrame } = content;
    const [observation, setObservation] = useState(valueTrame);
    const { text, hasPrecision, responseType } = survey;
    const { name } = responseType;
    const showPrecision = Boolean(hasPrecision) && precision !== '' && precision !== null;
    const fileInfos = content?.file;
    const isSalutance = SALUTANCE;
    const isChecbox = content?.survey?.widget?.name === 'Checkbox' || content?.survey?.widget?.name === 'CheckboxRadio';
    const isParent = content?.survey?.hasChild === 1;

    if (isParent) {
        return null;
    }

    const salutanceQuestion = (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <CustomText
                style={{ textAlign: 'left' }}
                fontSize={baseFontSize.sm}
                color={baseColors.orange.primary}
                fontFamily={baseFontFamily.OpenSansSemiBold}
            >
                {isSalutance ? 'QUESTION : ' : `QUESTION N° ${questionNumber}`}
            </CustomText>

            <CustomText
                style={{ textAlign: 'left', marginLeft: 20, whiteSpace: 'break-spaces' }}
                fontSize={baseFontSize.sm}
                fontFamily={baseFontFamily.OpenSansSemiBold}
            >
                {text}
            </CustomText>
        </div>
    );

    const simpleQuestion = (
        <div style={{}}>
            <CustomText
                style={{ textAlign: 'left' }}
                fontSize={baseFontSize.sm}
                color={baseColors.orange.primary}
                fontFamily={baseFontFamily.OpenSansSemiBold}
            >
                QUESTION N° {questionNumber}
            </CustomText>

            <CustomText
                style={{ textAlign: 'left' }}
                fontSize={baseFontSize.sm}
                fontFamily={baseFontFamily.OpenSansSemiBold}
            >
                {text}
            </CustomText>
        </div>
    );

    return (
        <div style={{}}>
            {isSalutance ? salutanceQuestion : simpleQuestion}

            {isChecbox && <CheckboxAnswerContent label="Réponse" content={content} />}

            {!fileInfos && !isChecbox && (
                <AnswerContent label="Réponse" value={responseTypeValue({ name, response })} />
            )}

            {showPrecision ? <AnswerContent label="Précision" value={precision} /> : null}

            {fileInfos && (
                <AnswerContent isFile label="Fichier correspondant" value={fileInfos?.name} fileLink={fileInfos?.url} />
            )}

            <Spacer height={5} />

            <div className={classes.row} style={{ alignItems: 'center' }}>
                <CustomText
                    style={{ color: baseColors.green.primary, width: 150, marginTop: -20 }}
                    fontSize={baseFontSize.sm}
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                >
                    Observation :
                </CustomText>

                <InputField
                    multiline
                    label=""
                    placeholder="Taper votre texte"
                    disabled={posting || !canValidate}
                    height={100}
                    width="100%"
                    defaultValue={valueTrame}
                    value={observation}
                    onChange={handleSetDEObservation({ userSurveyId, setPayload, setObservation })}
                />
            </div>

            <Spacer />
        </div>
    );
};

export default DEQuestionResponses;
