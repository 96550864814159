import { FC, useContext, useEffect, useState } from 'react';
import CustomModal from '../CustomModal';
import { baseColors, baseFontSize, baseFontWeight } from 'src/utils/constants/baseStyles';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import EventService from 'src/services/Calendar/events.service';
import EventModal from 'src/views/MyAgenda/EventModal';
import { EventPlanningViewType } from 'src/interfaces/interfaces';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { toast } from 'react-toastify';
import { OperationMessage } from 'src/utils/constants/constants';
import { UserContext } from 'src/providers/UserProvider';
import { CustomText } from 'src/components/Text/CustomText';
import { fullName, formatDateDiag, remoVeAdditionalTimeZone } from 'src/utils/utils';
import { Spacer } from 'src/components/Spacer/Spacer';
import { IconButton } from '@mui/material';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import CustomVisioFramModal from '../CustomVisioFramModal';
import { EducationalDiagnosisVisio } from 'src/interfaces/types';
import { defaultVisioValue } from 'src/hooks/useEducationalDiagnosis';
import CustomVisioIframe from 'src/components/CustomVisioIframe/CustomVisioIframe';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { PatientContext } from 'src/providers/PatientProvider';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import { isConnectedUserIntervenant } from 'src/views/MyAgenda/Planning/EventUtils';
import CustomPlayerVisio from 'src/components/CustomVisioIframe/CustomPlayerVisio';
import { VisioContentContext } from 'src/providers/VisioContentProvider';

interface DiagnosticModalProps {
    toggleModal: any;
    open: boolean;
    data?: any;
    updateEventList?: any;
    dataForWeeklyEvent?: any;
    viewType?: EventPlanningViewType['viewType'];
    readOnly?: boolean;
    eventId?: number;
    isExternalDoctor?: boolean;
    isCoordinator?: boolean;
}

const DiagnosticModal: FC<DiagnosticModalProps> = (props) => {
    const {
        open: defaultOpen,
        eventId,
        toggleModal,
        data,
        updateEventList,
        dataForWeeklyEvent,
        viewType,
        readOnly,
        isExternalDoctor,
        isCoordinator,
    } = props;
    const { userInfos } = useContext(UserContext);
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [loadingEvent, setLoadingEvent] = useState<boolean>(false);
    const [openDeleteEventModal, setOpenDeleteEventModal] = useState<boolean>(false);
    const toggleEditEventModal = () => setOpen(!open);
    const toggleRemoveEventModal = () => setOpenDeleteEventModal(!openDeleteEventModal);
    const { eventData, setEventData } = useContext(AgendaContext);
    const { setCurrentPatientId } = useContext(PatientContext);
    const patientId = data?.patientId ? data?.patientId : eventData?.patient?.attendeeUser?.id;
    const handleNavigateToPatientDetails = () => {
        setCurrentPatientId(patientId);
        const patientDetailsUrl = `/patients/list/${patientId}/profile`;
        history.push(patientDetailsUrl);
    };
    const [iframeContent, setIframeContent] = useState<EducationalDiagnosisVisio>(defaultVisioValue);
    const [openIframModal, setOpenIframModal] = useState<boolean>(false);
    const [creatingLink, setCreatingLink] = useState<boolean>(false);

    const selectedEventId: number = eventId || 0;

    const handleFetchData = () => {
        setLoadingEvent(true);
        EventService.getEvent(selectedEventId)
            .then((res: any) => {
                const response = res?.data?.data;
                if (response?.visioconference?.id > 0) {
                    setIframeContent(response?.visioconference);
                }
                setEventData(response);
                setLoadingEvent(false);
            })
            .catch(() => setLoadingEvent(false));
    };

    const event = data?.eventData ?? data;

    useEffect(() => {
        if (selectedEventId > 0) {
            handleFetchData();
        } else {
            setEventData(event);

            if (event?.visioconference?.id > 0) {
                setIframeContent(event?.visioconference);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event, selectedEventId]);

    const toggleIframModal = () => {
        setCreatingLink(true);
        setOpenIframModal(!openIframModal);

        setTimeout(() => {
            setCreatingLink(false);
        }, 5000);
    };

    const handleDeleteEvent = () => {
        setDeleting(true);
        EventService.deleteEvent(data?.eventId ?? data?.id)
            .then((res: any) => {
                toast.success(OperationMessage.success);
                setDeleting(false);
                updateEventList((prev: any) => !prev);
                toggleRemoveEventModal();
                toggleModal();
            })
            .catch(() => {
                setDeleting(false);
                toast.error(OperationMessage.error);
            });
    };
    const eventToCheck = selectedEventId > 0 ? eventData : event;

    const patientName = fullName(
        eventToCheck?.patient?.attendeeUser?.firstName,
        eventToCheck?.patient?.attendeeUser?.lastName,
    );
    const isVisio = eventToCheck?.consultationType?.code === 'VISIO';

    const intervenants = eventToCheck?.attendees?.filter((intervenant: any) => intervenant.attendeeType.id === 2);
    const hasNoIntervenants = intervenants?.length === 0;
    const { openIframe, setOpenIframe, setVisionContent } = useContext(VisioContentContext);

    const eventToUse = eventData?.id > 0 ? eventData : event;

    const isMyEvent =
        eventData?.ownerId === userInfos?.id ||
        eventData?.organizerId === userInfos?.id ||
        isCoordinator ||
        isConnectedUserIntervenant({ event: eventToUse, myId: userInfos?.id ?? 0 });
    const disableBtn = loadingEvent || readOnly || deleting || !isMyEvent || isExternalDoctor;

    const visioModal = (
        <CustomVisioFramModal toggleModal={toggleIframModal} open={openIframModal}>
            <CustomVisioIframe
                title="Démarrage de l'atelier en direct en cours..."
                loading={creatingLink}
                url={iframeContent?.url}
            />
        </CustomVisioFramModal>
    );

    const toggleVisio = () => {
        setOpenIframe(!openIframe);
        setVisionContent(iframeContent);
    };

    return (
        <CustomModal
            open={defaultOpen}
            toggleModal={toggleModal}
            title="Événement patient"
            footer={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CustomLoadingButton
                        orange
                        disabled={disableBtn}
                        handleClick={toggleRemoveEventModal}
                        loading={deleting}
                        marginRight={10}
                        label="Supprimer"
                    />

                    <CustomLoadingButton
                        disabled={disableBtn}
                        handleClick={toggleEditEventModal}
                        loading={false}
                        marginLeft={10}
                        label="Modifier"
                    />
                </div>
            }
        >
            {loadingEvent ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '50px 0 50px 200px',
                    }}
                >
                    <CustomCircleLoader />
                </div>
            ) : (
                <>
                    <Box style={{ marginTop: 30 }}>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                cursor: patientName && 'pointer',
                                marginBottom: 10,
                            }}
                            onClick={patientName ? handleNavigateToPatientDetails : undefined}
                        >
                            <CustomText
                                style={{
                                    fontSize: baseFontSize.sm,
                                }}
                            >
                                Nom du patient:
                            </CustomText>

                            <CustomText
                                style={{
                                    textDecorationLine: patientName && 'underline',
                                    fontSize: baseFontSize.md,
                                    color: patientName && baseColors.orange.primary,
                                    fontWeight: patientName && baseFontWeight.bold,
                                    marginLeft: 10,
                                }}
                            >
                                {patientName || '(Non renseigné)'}
                            </CustomText>
                        </Box>

                        <Spacer />

                        <CustomText
                            style={{
                                fontSize: baseFontSize.sm,
                            }}
                        >
                            Type d'événement: {eventToCheck?.eventType?.name}
                        </CustomText>

                        <Spacer />

                        <CustomText
                            style={{
                                fontSize: baseFontSize.sm,
                            }}
                        >
                            Programme: {eventToCheck?.program?.title ?? '(Non renseigné)'}
                        </CustomText>

                        <Spacer />

                        <CustomText
                            style={{
                                fontSize: baseFontSize.sm,
                            }}
                        >
                            Date: {formatDateDiag(remoVeAdditionalTimeZone(eventToCheck?.startDate))}
                        </CustomText>

                        <Spacer />

                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <CustomText
                                style={{
                                    fontSize: baseFontSize.sm,
                                }}
                            >
                                {isVisio ? 'Participer au rendez-vous à distance' : 'Lieu'}:
                            </CustomText>

                            <div style={{ cursor: 'pointer' }}>
                                {isVisio ? (
                                    <IconButton aria-label="Démarer l'atelier en direct" onClick={toggleVisio}>
                                        <VideoChatIcon sx={{ fontSize: 35, color: baseColors.orange.primary }} />
                                    </IconButton>
                                ) : (
                                    <CustomText
                                        style={{
                                            fontSize: baseFontSize.sm,
                                            marginLeft: 10,
                                        }}
                                    >
                                        {eventToCheck?.customLocation ?? ''}
                                    </CustomText>
                                )}
                            </div>
                        </Box>

                        <Spacer />

                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <CustomText
                                style={{
                                    fontSize: baseFontSize.sm,
                                }}
                            >
                                Intervenants :
                            </CustomText>

                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    marginLeft: 10,
                                }}
                            >
                                {hasNoIntervenants ? (
                                    <CustomText
                                        style={{
                                            fontSize: baseFontSize.sm,
                                        }}
                                    >
                                        (Non renseigné)
                                    </CustomText>
                                ) : (
                                    intervenants?.map((intervenant: any, i: any) => (
                                        <>
                                            <CustomText
                                                style={{
                                                    fontSize: baseFontSize.sm,
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                {fullName(
                                                    intervenant?.attendeeUser?.firstName,
                                                    intervenant?.attendeeUser?.lastName,
                                                )}
                                            </CustomText>
                                            {i !== Object.keys(intervenants).length - 1 ? ',' : ''}
                                        </>
                                    ))
                                )}
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: 30,
                            marginBottom: 10,
                        }}
                    >
                        <CustomConfirm
                            title="Supprimer l'événement"
                            defaultLoading={deleting}
                            yesLabel="Supprimer"
                            noLabel="ANNULER"
                            modalVisible={openDeleteEventModal}
                            updateModalVisible={setOpenDeleteEventModal}
                            message={
                                deleting ? 'Suppression en cours...' : "Voulez-vous vraiment supprimer l'événement ?"
                            }
                            callBackconfirm={handleDeleteEvent}
                            disable={disableBtn}
                        />
                    </Box>
                </>
            )}

            {visioModal}

            <EventModal
                setUpdateList={updateEventList}
                modalOpen={open}
                modalToggle={toggleEditEventModal}
                eventData={eventToUse}
                toggleEventModal={toggleModal}
                dataForWeeklyEvent={dataForWeeklyEvent}
                viewType={viewType}
                edition
            />
        </CustomModal>
    );
};

export default DiagnosticModal;
