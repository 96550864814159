import { useEffect, useState } from 'react';
import { getCustomLocationItems } from 'src/services/Rooms';

const useLocations = () => {
    const [fetchingLocations, setFetchingLocations] = useState(true);
    const [locations, setLocations] = useState<any[]>([]);

    const handleFecthLocations = () => {
        setFetchingLocations(true);
        getCustomLocationItems().then((response) => {
            const data = response?.data?.data?.flat(1)?.map((room: any, i: number) => ({
                id: room.id,
                name: room.name,
            }));
            setLocations([{ id: 0, name: 'Sélectionner un lieu' }, ...data]);
            setFetchingLocations(false);
        });
    };

    function customLocation(locationName: string) {
        const location = locations?.find((item: any) => item?.name?.toLowerCase() === locationName?.toLowerCase());
        return location;
    }

    function getLocationIdByName(locationName: string, data: any[]) {
        const location = data?.find((item: any) => item?.name?.toLowerCase() === locationName?.toLowerCase());
        return location;
    }

    function handleOptimizeLocationData(locationData: any[]) {
        const data = locationData?.flat(1)?.map((room: any, i: number) => ({
            id: room.id,
            name: room.name,
        }));
        return [{ id: 0, name: 'Sélectionner un lieu' }, ...data];
    }

    useEffect(() => {
        handleFecthLocations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        locations,
        fetchingLocations,
        customLocation,
        handleFecthLocations,
        handleOptimizeLocationData,
        getLocationIdByName
    };
};

export default useLocations;
