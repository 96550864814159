import { Dispatch, FC, SetStateAction, memo, useEffect, useState } from "react"
import QuestionText from "src/components/QuestionDE/QuestionText";
import { UpdateUserSurvayPayload, UserAxisItem } from "src/interfaces/interfaces";
import QuestionSlider from "src/components/QuestionDE/QuestionSlider";
import QuestionRadio from "src/components/QuestionDE/QuestionRadio";
import QuestionTitle from "src/components/QuestionDE/QuestionTitle";
import QuestionCard from "src/components/QuestionDE/QuestionCard";
import QuestionFile from "src/components/QuestionDE/QuestionFile";
import QuestionCheckboxRadio from "src/components/QuestionDE/QuestionCheckboxRadio";
import QuestionCheckbox from "src/components/QuestionDE/QuestionCheckbox";
import QuestionTextarea from "src/components/QuestionDE/QuestionTextarea";

interface EditResponseContainerProps {
    axisId: number,
    userDEResponses: UserAxisItem[],
    payload: UpdateUserSurvayPayload,
    setPayload: Dispatch<SetStateAction<UpdateUserSurvayPayload>>
}

const EditResponseContainer : FC<EditResponseContainerProps> = memo(({axisId, payload, setPayload, userDEResponses}) => {
    const initPayload = () => {
        const responsesMap = new Map(); // Utilise une Map pour faciliter les remplacements
    
        userDEResponses.forEach(item => {
            const response = {
                axisId: axisId,
                hasPrecision: item.survey.hasPrecision,
                precisionAnswer: item.precision,
                responseTypeId: item.survey.responseType.id,
                responseValue: item.response,
                responseIds: Array.from(item.responseItems,(i : any) => i.id),
                surveyId: item.survey.id,
                userSurveyId : item.id,
                filecontent: item?.file?.base64
            };
    
            const key = item.id;
            responsesMap.set(key, response); 
        });
    
        setPayload(prev => ({
            ...prev,
            responses: Array.from(responsesMap.values())
        }));
    }

    const optimizeUserDEResponses = (element : any ) => {
       delete element?.user;
       delete element?.survey?.axis;
       delete element?.survey?.parent;
       delete element?.survey?.surveyParent;
       delete element?.educationalDiagnosis;
       return element;
    }

    useEffect(() => {
        initPayload();
    },[])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',padding:'15px', gap:'30px' }}>
            {
                userDEResponses.map((el,i )=> {
                    const userDEResponse = optimizeUserDEResponses(el);
                    const questionTitle = <QuestionTitle questionNumber={i+1} questionTitle={el.survey.text}/>;
                    const index = payload.responses.findIndex((payloadResponse : any) => payloadResponse.userSurveyId === userDEResponse.id);
                    const responseIds = payload.responses && payload.responses.length > index && Array.isArray(payload.responses[index]?.responseIds) ? payload.responses[index]?.responseIds : [];
                    const responseValue = payload.responses && payload.responses.length > index ? payload.responses[index]?.responseValue : '';
                    const precisionAnswer = payload.responses && payload.responses.length > index ? payload.responses[index]?.precisionAnswer : ''; 
                    if(userDEResponse.survey.id == 49) console.log(userDEResponse, responseValue ,"responseValue")

                    switch (el.survey.widget.name) {
                        case 'InputField':
                            return <QuestionText
                                    key={i}
                                    index={index}
                                    responseValue={responseValue}
                                    questionTitle={questionTitle}
                                    setPayload={setPayload} 
                                />
                        case 'TextArea':
                            return <QuestionTextarea
                                    key={i}
                                    index={index}
                                    responseValue={responseValue}
                                    questionTitle={questionTitle}
                                    setPayload={setPayload} 
                                />
                            
                        case 'Slider':
                            return <QuestionSlider
                                key={i}
                                index={index}
                                questionTitle={questionTitle}
                                responseValue={responseValue}
                                precisionAnswer={precisionAnswer}
                                setPayload={setPayload}
                                userDEResponse={userDEResponse}
                            />
                        case 'Radio':
                            return <QuestionRadio
                                    key={i}
                                    questionTitle={questionTitle}
                                    index={index}
                                    responseValue={responseValue}
                                    precisionAnswer={precisionAnswer}
                                    setPayload={setPayload} 
                                    userDEResponse={userDEResponse}
                                />
                        case 'Card':
                            return <QuestionCard
                                    key={i}
                                    index={index}
                                    questionTitle={questionTitle}
                                    responseValue={responseValue}
                                    precisionAnswer={precisionAnswer}
                                    setPayload={setPayload} 
                                    userDEResponse={userDEResponse}
                                />
                        case 'TextArea':
                            return ''
                        case 'DragAndDropFile':
                                return ''
                        case 'CheckboxRadio':
                            return <QuestionCheckboxRadio
                                key={i}
                                questionTitle={questionTitle} 
                                responseIds={responseIds}
                                precisionAnswer={precisionAnswer}
                                index={index}
                                setPayload={setPayload} 
                                userDEResponse={userDEResponse}
                            />
                        case 'Checkbox':
                            return <QuestionCheckbox
                                key={i}
                                questionTitle={questionTitle} 
                                responseIds={responseIds}
                                precisionAnswer={precisionAnswer}
                                index={index}
                                setPayload={setPayload} 
                                userDEResponse={userDEResponse}
                            />
                        case 'File':
                                return <QuestionFile
                                    key={i}
                                    index={index}
                                    questionTitle={questionTitle}
                                    setPayload={setPayload} 
                                    userDEResponse={userDEResponse}
                                />
                        default:
                          return '';
                    }
                })
            }
        </div>
    )
})

export default EditResponseContainer;