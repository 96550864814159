import { Box } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { sxStyles } from './styles';
import { baseColors } from 'src/utils/constants/baseStyles';
import { ETFTable, ETFActionButtons } from './ETFTable';
import { Users } from 'src/interfaces/interfaces';
import { PatientService } from 'src/services/Patients';
import { CustomText } from 'src/components/Text/CustomText';
import SubMenusLoader from 'src/components/SettingsSubMenuBar/SubMenusLoader/SubMenusLoader';
import { URER_ROLES } from 'src/utils/constants/enums';
import { EtpListContext } from 'src/providers/EtpProvider';

const ETFList = () => {
    const [dataRows, setDataRows] = useState<any[]>([]);
    //const [updateList, setUpdateList] = useState<boolean>(false);
    const [selectedUserData, setSelectedUserData] = useState<Users | null>(null);
    const [fetchingUsers, setFetchingUsers] = useState<boolean>(false);
    const { updateList } = useContext(EtpListContext);

    const parseUsersData = (users: any[]) => {
        const rows: any[] = Array.from(users, (user) => ({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            name: user?.etpCertificate?.name,
            content: user?.etpCertificate?.base64,
            isExternal: user?.isExternal,
            action: (
                <ETFActionButtons
                    userData={user}
                    setSelectedUserData={setSelectedUserData}
                    setFetchingUsers={setFetchingUsers}
                />
            ),
        }));
        setDataRows(rows);
    };

    const handleFetchUsers = () => {
        setFetchingUsers(true);
        PatientService.getProfessionalsIsEtps(URER_ROLES.DOCTOR)
            .then((response) => {
                const users = response.data.data;
                setFetchingUsers(false);
                if (users.length > 0) {
                    parseUsersData(users);
                }
            })
            .catch((error) => setFetchingUsers(false));
    };

    const tableHeaders = [
        {
            name: '',
        },
        {
            name: 'Nom',
        },
        {
            name: 'Prénom',
        },
        {
            name: 'Actif',
        },
    ];

    useEffect(() => {
        handleFetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateList]);

    return (
        <Box sx={sxStyles.container}>
            <CustomText
                isBreefSerif
                isTitle
                color={baseColors.green.primary}
                style={{ textAlign: 'center', marginBottom: 20 }}
            >
                Equipe ETP
            </CustomText>

            {fetchingUsers ? (
                <SubMenusLoader />
            ) : (
                <ETFTable tableHeaders={tableHeaders} dataRows={dataRows} onListUsers={handleFetchUsers} />
            )}
        </Box>
    );
};

export default ETFList;
