import { Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CustomText } from 'src/components/Text/CustomText';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { Spacer } from 'src/components/Spacer/Spacer';
import WorkshopService from 'src/services/Workshop/workshop.service';
import { sortByIds } from 'src/utils/utils';

interface EventWorkshopProps {
    setEventPayload: any;
    loadingData?: any;
    eventData?: any;
    isWorkshop?: boolean;
    allWorkshopData?: any;
    setAllWorkshopData?: any;
    attendeeTypeId?: number;
    professionalIds?: any;
    payload?: any;
    setSelectedWorkshopData?: any;
}

const EventWorkshop: FC<EventWorkshopProps> = (props) => {
    const { setEventPayload, loadingData, eventData, setAllWorkshopData, allWorkshopData, setSelectedWorkshopData } =
        props;
    const defaultWorkshopId = eventData?.workshop?.id ?? undefined;
    const [loading, setLoading] = useState<boolean>(false);

    const fetchWorkshops = () => {
        setLoading(true);
        WorkshopService.getWorkshops({ isDraft: false })
            .then((response) => {
                const data = response?.data?.data;
                if (data) {
                    setAllWorkshopData(sortByIds(data));
                    setLoading(false);
                }
            })
            .catch((e) => {
                toast.error('Une erreur sur la récupération des ateliers');
            });
    };

    useEffect(() => {
        fetchWorkshops();
    }, []);

    useEffect(() => {
        const workshopId = eventData?.workshop?.id;
        const workshopSummary = eventData?.summary;

        if (workshopId > 0) {
            setEventPayload((old: any) => ({
                ...old,
                workshopId: workshopId,
                summary: workshopSummary,
            }));

            const selectedWorkshop = allWorkshopData.find((item: any) => item?.id === workshopId);
            setSelectedWorkshopData(selectedWorkshop);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allWorkshopData]);

    const eventworkshopData = [{ id: -1, name: 'Sélectionner un atelier' }, ...allWorkshopData];

    const handleChangeWorkshop = (value: string) => {
        const selectedWorkshop = allWorkshopData.find((item: any) => item?.id === value);
        const eventName = selectedWorkshop?.name;
        setSelectedWorkshopData(selectedWorkshop);
        setEventPayload((old: any) => ({
            ...old,
            workshopId: value,
            summary: eventName,
        }));
    };



    return (
        <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item>
                <CustomText>Choisir un atelier : </CustomText>
            </Grid>

            <Grid item style={{ width: '60%' }}>
                {loading ? (
                    loadingData
                ) : (
                    <div style={{}}>
                        <CustomSelect
                            onChange={handleChangeWorkshop}
                            label=""
                            labelKey={'name'}
                            items={eventworkshopData}
                            defaultValue={defaultWorkshopId}
                            idSelect="selection-workshop-event"
                            width="100%"
                        />
                    </div>
                )}
            </Grid>
        </Grid>
    );
};

export default EventWorkshop;
