import { Box } from "@mui/material"
import styles from "./styles"
import { FC } from "react"
import SurveyResponse from "./content/SurveyResponse"
import { CustomText } from "src/components/Text/CustomText"
import { baseColors } from "src/utils/constants/baseStyles"

const ExportPanel : FC = () => {
    return (
        <Box sx={styles.container}>
            <Box sx={styles.header}>
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Exportation de données
                </CustomText>
            </Box>

            <Box sx={{ marginTop: 2 }}>
                <SurveyResponse/>
            </Box>
        </Box>
    )
}

export default ExportPanel