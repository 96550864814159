import { FC, useContext, useEffect, useState } from 'react';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import CustomLoader from 'src/components/CustomLoader/CustomLoader';
import { ObservationItem } from 'src/components/ObservationItem/ObservationsItem';
import { Spacer } from 'src/components/Spacer/Spacer';
import { getPatientObservations, getObsrvationById } from 'src/services/Patients';
import { renderTitle } from 'src/views/Programme/Synthese/utils';
import { ObservationForm } from './Form/ObservationForm';
import useStyles from './styles';
import { PlateformTypeContext } from 'src/providers/PlateformTypeProvider';
import { UserContext } from 'src/providers/UserProvider';
import { CustomText } from 'src/components/Text/CustomText';
import { PatientContext } from 'src/providers/PatientProvider';
import CustomEmptyData from 'src/components/CustomEmptyData';

interface ObservationsProps {
    patientId: number;
    patientName?: string;
    defaultPatient?: any;
    isActor?: any;
    setCurrentPatientId?: any;
}
export const Observations: FC<ObservationsProps> = (props) => {
    const classes = useStyles();
    const { patientId, defaultPatient, isActor } = props;
    const { isPlateformCpts } = useContext(PlateformTypeContext);
    const { setCurrentPatientId, read } = useContext(PatientContext);
    const [open, setOpen] = useState(false);
    const [view, setView] = useState('details');
    const [observations, setObservations] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [updateObservationsList, setUpdateObservationsList] = useState<boolean>(false);
    const [observationData, setObservationData] = useState(null);
    const [patientDefaultStatus, setPatientDefaultStatus] = useState();
    const { observationId } = useContext(PatientContext);
    const { userInfos: hasEtp } = useContext(UserContext);
    const { isDoctor, isCoordinator } = useContext(UserContext);
    const disabledModf = hasEtp && (isCoordinator || isActor?.isAcceptedByProfessional);

    const fetchObservation = () => {
        setLoading(true);
        getPatientObservations(patientId)
            .then((response) => {
                const observationsData = response.data.data;
                if (observations) {
                    setObservations(observationsData);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const edit = (data: any) => {
        setObservationData(data);
        setOpen(true);
        setView('details');
    };

    useEffect(() => {
        if (isPlateformCpts && isDoctor) {
            if (isActor.length == 0) {
                setPatientDefaultStatus(defaultPatient);
            } else {
                setPatientDefaultStatus(isActor?.isAcceptedByProfessional);
            }
        }
        setCurrentPatientId(patientId);
    }, [patientId]);

    useEffect(() => {
        fetchObservation();
        if (observationId !== 0) {
            getObsrvationById(observationId).then((res: any) => {
                const response = res?.data?.data;
                if (response) {
                    edit(response);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateObservationsList]);

    const isEmpty = !loading && observations?.length === 0;

    return (
        <div className={classes.container}>
            <div className={classes.flexCenter}>{renderTitle("Observations de suivi et d'entretien")}</div>
            {isEmpty ? (
                <div style={{ height: 'calc(100vh - 375px)', overflowY: 'scroll' }}>
                    <Spacer height={50} />
                    <CustomEmptyData />
                    <Spacer height={50} />
                </div>
            ) : (
                <div style={{ marginTop: 30, height: 'calc(100vh - 400px)', overflowY: 'scroll' }}>
                    {loading ? (
                        <div style={{ width: '100%', alignContent: 'center' }}>
                            <CustomLoader IsclassicList />
                        </div>
                    ) : observations?.length !== 0 ? (
                        observations?.map((item) => (
                            <ObservationItem
                                onPress={() => edit(item)}
                                title={item?.title?.length > 0 ? item?.title : 'Observation ' + item.id}
                                key={item.id}
                                type={item?.type?.name}
                                date={item?.date}
                                program={item?.program}
                            />
                        ))
                    ) : (
                        <CustomText style={{ textAlign: 'center' }}> Observation vide.</CustomText>
                    )}
                </div>
            )}
            <Spacer height={20} />

            <div className={classes.flexCenter}>
                <CustomLoadingButton
                    label="Ajouter une Observation"
                    handleClick={() => {
                        setObservationData(null);
                        setOpen(true);
                    }}
                    disabled={loading}
                />
            </div>

            <ObservationForm
                triggerCallback={() => fetchObservation()}
                patientId={patientId}
                open={open}
                view={view}
                observationData={observationData}
                setOpen={setOpen}
                setView={setView}
                setUpdateObservationsList={setUpdateObservationsList}
                readStatus={disabledModf}
            />
        </div>
    );
};
