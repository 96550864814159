import { Grid } from "@mui/material"
import { FC } from "react"
import { CustomText } from "../Text/CustomText"
import { baseColors, baseFontFamily, baseFontSize } from "src/utils/constants/baseStyles"
import { SALUTANCE } from "src/utils/constants/constants"

interface QuestionTitleProps {
    questionNumber: number,
    questionTitle: string,
}
const QuestionTitle : FC<QuestionTitleProps> = ({questionNumber, questionTitle}) => {

    const isSalutance = SALUTANCE;
    return (
        <Grid
                container
                direction="row"
                alignItems={'center'}
                justifyContent="space-between"
            >
            <Grid item style={{}}>
                {!isSalutance && 
                    <CustomText
                        style={{ textAlign: 'left'}}
                        fontSize={baseFontSize.sm}
                        color={baseColors.orange.primary}
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                    >
                    {`QUESTION N° ${questionNumber}`}
                    </CustomText>
                }
                
                <CustomText
                    style={{ color: baseColors.green.primary, width: '100%', whiteSpace : 'break-spaces'}}
                    fontSize={baseFontSize.sm}
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                >{questionTitle}</CustomText>
            </Grid>
        </Grid>
    )
}

export default QuestionTitle