import moment from 'moment';
import { toast } from 'react-toastify';
import { EventNotifData } from 'src/interfaces/interfaces';
import EventService from 'src/services/Calendar/events.service';
import { baseColors } from 'src/utils/constants/baseStyles';
import { fullName, remoVeAdditionalTimeZone } from 'src/utils/utils';

const handleDateSelect = ({
    selectedInfos,
    toggleCreateEventModal,
    setNewEventDate,
    setNewEventEndDate,
    viewType,
    calendarComponentRef,
    cantCreateEvent,
}: any) => {
    const startAt = selectedInfos.start;
    const startStr = selectedInfos.startStr;
    const endAt = selectedInfos.end;

    if (startAt) {
        if (viewType === 'monthly') {
            gotoSeletedDate({ calendarComponentRef, date: startStr });
        } else {
            if (!cantCreateEvent) {
                setNewEventDate(startAt);
                toggleCreateEventModal();
            }
        }
    }

    if (endAt) {
        if (!cantCreateEvent) {
            setNewEventEndDate?.(endAt);
        }
    }
};

const handleEventClick = ({ eventInfo, setSelectedEvent, toggleModal }: any) => {
    const splited = eventInfo?.event?.id?.split('-');
    const eventId = splited?.[0];
    const eventType = splited?.[1];
    setSelectedEvent({
        id: Number(eventId),
        eventType: eventType,
    });
    toggleModal();
};

const handleEvents = (events: any) => {};

const handleWeekendsToggle = () => {};

const initialView = (viewType: any) => {
    switch (viewType) {
        case 'daily':
            return 'timeGridDay';

        case 'monthly':
            return 'dayGridMonth';

        default:
            return 'timeGridWeek';
    }
};

const eventName = (eventData: any) => {
    const isConsultation = eventData?.eventType?.isConsulation;

    let eventName = eventData?.summary;
    const patientInfos = eventData?.patient?.attendeeUser;

    if (isConsultation) {
        eventName =
            eventData?.eventType?.isConsulation && eventData?.patients?.length === 0
                ? eventData.summary
                : (patientInfos?.civility === 0 ? 'Mme ' : 'Mr ') +
                  fullName(patientInfos?.firstName, patientInfos?.lastName);
    }
    return eventName || '';
};

const eventTypeAndId = (eventData: any) => {
    const isConsultation = eventData?.eventType?.isConsulation;
    let eventId = eventData?.id;

    if (isConsultation) {
        return `${eventId}-consultation`;
    }
    return `${eventId}-workshop`;
};

const handleSetEventData = ({ events, setCurrentEvents, allRooms }: any) => {
    const roomColor = (eventData: any) =>
        allRooms?.find((room: any) => room.id === eventData?.location?.id) ?? {
            color: { color: baseColors.black, backgroundColor: baseColors.green.light },
        };

    const eventSources =
        Array.from(events, (event: any) => {
            return {
                id: eventTypeAndId(event),
                title: eventName(event) ?? 'Aucun',
                start: remoVeAdditionalTimeZone(event?.startDate),
                end: remoVeAdditionalTimeZone(event?.endDate),
                backgroundColor: roomColor(event)?.color?.backgroundColor || baseColors.green.light,
                borderColor: roomColor(event)?.color?.backgroundColor || baseColors.green.light,
                textColor: roomColor(event)?.color?.color || baseColors.green.primary,
            };
        }) ?? [];

    setCurrentEvents(eventSources);
};

const handleFetchEventData = async (
    eventNotifId: any,
    setOpenNotifModal: Function,
    setSelectedEvent: Function,
    setOpen: Function,
) => {
    setOpenNotifModal(true);
    await EventService.getEvent(eventNotifId)
        .then((response) => {
            const status = response?.data?.status;
            if(status === 'EVENT_ALREADY_CANCELED'){
                toast.error('Cet événement a déjà été annulé')
            }else if(status === 'EVENT_ALREADY_DELETED'){
                toast.error('Cet événement a déjà été supprimé')
            }else{
                const data = response?.data?.data;
                const formatData: EventNotifData = {
                    dayHopitalId: data.dayHospital,
                    duration: data.duration,
                    endDate: data.endDate,
                    eventData: data,
                    eventId: data.id,
                    freeEvent: false,
                    informations: {
                        duration: data.duration,
                        eventId: data.id,
                        intervening: 1,
                        location: data.location,
                        name: data.summary,
                        patientCount: 0,
                    },
                    isConsultation: data.eventType.isConsultation,
                    name: data.summary,
                    patientId: 0,
                    specialityTypeId: data?.specialityType?.id,
                    startDate: data.startDate,
                    timeEnd: '',
                    type: {
                        label: data.eventType.name,
                        typeName: data.eventType.name,
                    },
                };
                setSelectedEvent(formatData);
                setOpen(true);
            }
            setOpenNotifModal(false);
        })
        .catch((err: any) => {
            console.log(err);
        });
};

const handleEventDrop = ({
    eventInfos,
    toggleCreateEventModal,
    setDroppedEventDateTime,
    setDroppedEventId,
}: {
    eventInfos: any;
    toggleCreateEventModal: any;
    setDroppedEventId: any;
    setDroppedEventDateTime: any;
}) => {
    const { start } = eventInfos.oldEvent._instance.range;
    const { startStr: newStart, endStr: newEnd } = eventInfos.event;
    const eventId = eventInfos.event.id.split('-')[0];

    setDroppedEventId(Number(eventId));
    setDroppedEventDateTime({ start: remoVeAdditionalTimeZone(newStart), end: remoVeAdditionalTimeZone(newEnd) });
    toggleCreateEventModal();
    if (new Date(start).getDate()) {
        eventInfos.revert();
    }
};

const slotDurationValue = (key: number) => {
    const timeSlot = Number(key) > 0 ? key : 15;

    switch (timeSlot) {
        case 60:
            return '01:00:00';

        default:
            return `00:${timeSlot}:00`;
    }
};

const handleDatesSet = ({ event, setViewType }: { event: any; setViewType: any }) => {
    const viewType = event?.view?.type;
    switch (viewType) {
        case 'timeGridDay':
            return setViewType('daily');

        case 'dayGridMonth':
            return setViewType('monthly');

        default:
            return setViewType('weekly');
    }
};

const defaultEventDate = (date?: string) => moment(date ?? new Date()).format('YYYY-MM');

const gotoSeletedDate = ({ date, calendarComponentRef }: { date: any; calendarComponentRef: any }) => {
    let calendarApi = calendarComponentRef?.current?.getApi();
    calendarApi?.changeView('timeGridDay', date);
};

export {
    gotoSeletedDate,
    defaultEventDate,
    handleDatesSet,
    slotDurationValue,
    handleEventDrop,
    eventTypeAndId,
    handleSetEventData,
    eventName,
    initialView,
    handleDateSelect,
    handleEventClick,
    handleEvents,
    handleWeekendsToggle,
    handleFetchEventData,
};
