import Table from '@mui/material/Table';
import { useContext } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';
import { FC, useState } from 'react';
import Switch from '@mui/material/Switch';
import { activationUser } from '../../../../services/User/user.service';
import { CustomConfirm } from '../../../../components/CustomConfirm/CustomConfirm';
import { UserContext } from 'src/providers/UserProvider';
import { tableCellHeadText, tableCellText } from 'src/views/Security/SecurityManagement/SecurityManagement';
import { userCategory, userRole } from 'src/utils/utils';
import { toast } from 'react-toastify';

interface UsersTableHeader {
    name: string;
}

interface UsersDataRow {
    id: number;
    firstName: string;
    lastName: string;
    roles: string;
    activated: boolean;
    category: string;
    action: any;
}

interface UsersTableProps {
    tableHeaders: UsersTableHeader[];
    dataRows: UsersDataRow[];
}

interface UsersTableActionButtonsProps {
    toggleModal?: any;
    setSelectedUserData: any;
    userData: any;
}

export const UsersActionButtons: FC<UsersTableActionButtonsProps> = (props) => {
    const { toggleModal, setSelectedUserData, userData } = props;
    const { isCoordinator, isDoctor } = useContext(UserContext);
    const [modalConfirmationVisible, setModalConfirmationVisible] = useState<boolean>(false);
    const toggleModalConfirmation = () => setModalConfirmationVisible(!modalConfirmationVisible);

    const openEdit = (e: any) => {
        toggleModal();
        setSelectedUserData(userData);
    };

    const [checked, setChecked] = useState<boolean>(userData?.activated);

    const handleChange = () => {
        activationUser(userData?.id, { activated: !userData?.activated })
            .then((res) => {
                if(res?.data?.code === 401){
                    const userInfos = res?.data?.message?.split('|')?.[1] ? `(${res?.data?.message?.split('|')?.[1]})` : '';
                    toast.error(`Un utilisateur ${userInfos?.toUpperCase()} ayant le même email existe déjà. Vous ne pouvez plus activer ce compte.`)
                    setModalConfirmationVisible(false);
                }else{
                    setChecked(!userData?.activated);
                    let user = userData;
                    user.activated = !userData?.activated;
                    setModalConfirmationVisible(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <EditOutlinedIcon
                sx={{ fontSize: 'small', color: baseColors.green.primary, marginRight: '10px', cursor: 'pointer' }}
                onClick={openEdit}
            />
            <Switch
                className='switch-user-action'
                size="small"
                checked={userData?.activated}
                onClick={toggleModalConfirmation}
                sx={{ color: baseColors.green.primary }}
                disabled={isDoctor ? true : false}
            />
            {checked ? (
                <CustomConfirm
                    modalVisible={modalConfirmationVisible}
                    updateModalVisible={setModalConfirmationVisible}
                    callBackconfirm={() => handleChange()}
                    message={'Voulez-vous vraiment désactiver cet utilisateur ?'}
                />
            ) : (
                <CustomConfirm
                    modalVisible={modalConfirmationVisible}
                    updateModalVisible={setModalConfirmationVisible}
                    callBackconfirm={() => handleChange()}
                    message={'Voulez-vous vraiment activer cet utilisateur ?'}
                />
            )}
        </>
    );
};

const UsersTable = (props: UsersTableProps = { tableHeaders: [], dataRows: [] }) => {
    const tableHeaders = props.tableHeaders;
    const dataRows = props.dataRows;

    return (
        <>
            <TableContainer
                component={Paper}
                style={{ overflow: 'auto', maxHeight: baseScreenHeight.tableContainerHeight }}
            >
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="role table">
                    <TableHead>
                        <TableRow>
                            {tableHeaders.map((header, index) => {
                                return (
                                    <TableCell key={index} sx={{ color: baseColors.green.primary }}>
                                        {tableCellHeadText(header.name)}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataRows.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    '& .MuiTableCell-root': {
                                        padding: '5px 15px 5px 15px',
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {tableCellText(row.firstName)}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {tableCellText(row.lastName)}
                                </TableCell>
                                <TableCell>{tableCellText(userRole(row.roles))}</TableCell>
                                <TableCell>{tableCellText(userCategory(row.category))}</TableCell>
                                <TableCell>{row.action}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <Pagination style={{ display: 'flex', justifyContent: 'flex-end' }} count={10} color="primary" /> */}
        </>
    );
};

export default UsersTable;
