import { FC, useEffect, createRef, useState, useContext } from 'react';
import CustomModal from 'src/components/CustomModal';
import { Grid, Avatar, IconButton, Box } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { CustomText } from 'src/components/Text/CustomText';
import { InputField } from 'src/components/InputField/InputField';
import { isNotBlank, isPhoneNumber, isValidMail } from 'src/utils/helpers/InputValidator';
import { UserContext } from 'src/providers/UserProvider';
import { baseColors, baseFontFamily, baseScreenHeight } from 'src/utils/constants/baseStyles';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { PatientService } from 'src/services/Patients';
import UserService from 'src/services/User/user.service';
import { putProfilePicture, addProfilePictureSoignant, getProfilePicture } from 'src/services/Patients';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OperationMessage } from 'src/utils/constants/constants';
import PhoneInput from 'react-phone-input-2';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';

interface EditSoignantProps {
    open: boolean;
    setOpen: Function;
    setUpdate?: Function;
}

export const EditSoignant: FC<EditSoignantProps> = (props) => {
    const { open, setOpen, setUpdate } = props;
    const [lockInput, setLoackInput] = useState(false);
    const { userInfos: userInfosFromContext, setUserInfos } = useContext(UserContext);
    const [payload, setPayload] = useState<any>(userInfosFromContext);
    const userId = userInfosFromContext?.id;
    const [profIcon, setProfIcon] = useState<string>('');
    const [isLoadingProfIcon, setIsLoadingProfIcon] = useState<boolean>(false);
    const [pictureId, setPictureId] = useState<any>();
    const iconRef = createRef<HTMLInputElement>();
    const history = useHistory();
    const baseUrl = process.env.REACT_APP_BASE_URL

    const validSoignantData = (payload: any) => isValidMail(payload?.email) && isPhoneNumber(payload?.phoneNumber);

    const _handleReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        let fileContent = `data:image/jpeg;base64,${btoa(binaryString)}`;
        let userId = userInfosFromContext?.id;
        let id = pictureId;
        setProfIcon(`data:image/jpeg;base64,${btoa(binaryString)}`);
        if (pictureId) {
            putProfilePicture({ id, userId, fileContent }).then(() => {
                setUpdate?.((prev: any) => !prev);
                toast.success(OperationMessage.success);
            });
        } else {
            addProfilePictureSoignant({ userId, fileContent }).then(() => {
                setUpdate?.((prev: any) => !prev);
                toast.success(OperationMessage.success);
            });
        }
    };

    const handleIconChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.currentTarget.files;
        if (files && files?.length > 0) {
            let file = files[0];

            let allowedExtension = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!allowedExtension.includes(file.type)) {
                alert('images non supporté (veuiller importe des .jpeg, .jpg, .png');
            } else {
                const reader = new FileReader();
                reader.onload = _handleReaderLoaded;
                reader.readAsBinaryString(file);
            }
        }
    };

    useEffect(() => {
        if (!payload) {
            UserService.connectedUser().then((response) => {
                const data = response.data.data;
                if (data) {
                    setPayload(data);
                }
            });
        }
        setIsLoadingProfIcon(true);
        getProfilePicture(userId).then((res) => {
            const profilData = res?.data?.data;
            setProfIcon(profilData?.[0]?.imagepath ?? '');
            setPictureId(profilData[0]?.id);
            setIsLoadingProfIcon(false);
        });
    }, [userId]);

    const editProfilSoignant = () => {
        setLoackInput(true);
        const finalPayload = {
            email: payload?.email,
            firstName: payload?.firstName,
            lastName: payload?.lastName,
            phoneNumber: payload?.phoneNumber,
            rpps: payload?.rpps,
        };

        if (payload?.id) {
            PatientService.putSoignant({ userId: payload.id, payload: finalPayload })
                .then((response) => {
                    if (response.data.code === 200) {
                        setUserInfos(payload);
                        setOpen(false);
                        history.goBack();
                        toast.success('Modification du profil avec succès');
                    }
                    setLoackInput(false);
                })
                .catch((error) => {
                    setLoackInput(false);
                    if (error?.response?.data?.status === 'USER_RPPS_ALREADY_EXIST') {
                        toast.error('Numéro RPPS déjà utilisé.');
                    }else if (error?.response?.data?.status === 'USER_EMAIL_ALREADY_EXIST') {
                        toast.error('E-mail  déjà utilisé.');
                    } else {
                        toast.error("Erreur de modification d'utilisateur");
                    }
                });
        } else {
            toast.error('User id undefined');
        }
    };

    const profilImage = profIcon.includes('data:image/') ? profIcon :  baseUrl+'/'+profIcon;

    return (
        <CustomModal
            width={600}
            height={baseScreenHeight.newEventModal}
            title="Modification du détail profil"
            open={open}
            toggleModal={setOpen}
            footer={
                <Grid container style={{ marginTop: 30 }} justifyContent="center">
                    <CustomLoadingButton
                        width="80%"
                        disabled={!validSoignantData(payload)}
                        loading={lockInput}
                        label="Modifier le profil"
                        handleClick={editProfilSoignant}
                    />
                </Grid>
            }
        >
            <Grid item>
                <IconButton
                    onClick={() => iconRef.current?.click()}
                    style={{
                        background: baseColors.grey,
                        padding: profIcon === '' ? 20 : 0,
                        marginLeft: '38%',
                        marginTop: '30px',
                        marginBottom: '30px',
                    }}
                >
                    {
                        isLoadingProfIcon ? 
                            <Box height={150} width={150} style={{display : 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <CustomCircleLoader height={25} width={25} />
                            </Box> :
                        profIcon?.length !== 0 ? (
                            <Avatar src={`${profilImage}`} sx={{ width: 150, height: 150 }} />
                        ) : (
                            <PhotoCamera sx={{ width: 150, height: 150 }} />
                        )
                    }
                    <input ref={iconRef} id="icon_input" type="file" hidden onChange={handleIconChange} />
                </IconButton>
            </Grid>
            <Grid style={{ paddingLeft: 30, paddingRight: 30 }}>
                <Grid
                    style={{ marginTop: 20, marginBottom: 10 }}
                    container
                    direction="row"
                    alignItems={'center'}
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomText fontFamily={baseFontFamily.OpenSansSemiBold}>Nom :</CustomText>
                    </Grid>

                    <InputField
                        label=""
                        disabled={lockInput}
                        validator={isNotBlank}
                        errorMessage="Numéro téléphone invalide"
                        placeholder="Entrer le numéro téléphone"
                        onChange={(value: any) => setPayload((old: any) => ({ ...old, lastName: value.toString() }))}
                        value={payload?.lastName}
                        width={'60%'}
                    />
                </Grid>
                <Grid
                    style={{ marginTop: 20 }}
                    container
                    direction="row"
                    alignItems={'center'}
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomText fontFamily={baseFontFamily.OpenSansSemiBold}>Prénom :</CustomText>
                    </Grid>
                    <InputField
                        label={''}
                        single
                        disabled={lockInput}
                        validator={isNotBlank}
                        errorMessage="Nom Obligatoire"
                        placeholder="Taper le prenom"
                        value={payload?.firstName}
                        onChange={(value: any) => setPayload((old: any) => ({ ...old, firstName: value.toString() }))}
                        width={'60%'}
                    />
                </Grid>
                <Grid
                    style={{ marginTop: 20 }}
                    lg={12}
                    container
                    direction="row"
                    alignItems={'center'}
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomText fontFamily={baseFontFamily.OpenSansSemiBold}>email :</CustomText>
                    </Grid>
                    <InputField
                        label={''}
                        single
                        disabled={lockInput}
                        validator={isValidMail}
                        errorMessage="Nom Obligatoire"
                        placeholder="Taper votre email"
                        value={payload?.email}
                        onChange={(value: any) => setPayload((old: any) => ({ ...old, email: value.toString() }))}
                        width={'60%'}
                    />
                </Grid>

                <Grid
                    id="edit-soignant-phone-number"
                    style={{ marginTop: 20 }}
                    lg={12}
                    container
                    direction="row"
                    alignItems={'center'}
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomText fontFamily={baseFontFamily.OpenSansSemiBold}>Téléphone :</CustomText>
                    </Grid>

                    <PhoneInput
                        isValid={(inputNumber) => inputNumber?.length > 10}
                        country="fr"
                        inputStyle={{
                            backgroundColor: 'transparent',
                            width: '100%',
                            height: 40,
                        }}
                        countryCodeEditable={false}
                        value={payload?.phoneNumber}
                        onChange={
                            (phone: any) => setPayload((old: any) => ({ ...old, phoneNumber: '+' + phone }))
                        }
                    />
                </Grid>

                <Grid
                    style={{ marginTop: 20 }}
                    lg={12}
                    container
                    direction="row"
                    alignItems={'center'}
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomText fontFamily={baseFontFamily.OpenSansSemiBold}>Numéro RPPS :</CustomText>
                    </Grid>
                    <InputField
                        single
                        label={''}
                        disabled={lockInput}
                        validator={isNotBlank}
                        errorMessage="Nom Obligatoire"
                        placeholder="Taper votre numéro RPPS"
                        value={payload?.rpps}
                        onChange={(value: any) => setPayload((old: any) => ({ ...old, rpps: value.toString() }))}
                        width={'60%'}
                    />
                </Grid>
            </Grid>
        </CustomModal>
    );
};
