import moment from 'moment';
import { toast } from 'react-toastify';
import EventService from 'src/services/Calendar/events.service';
import { EventMessage, ServerError, isDivisibleByDuration } from 'src/utils/constants/constants';
import { dbDateFormat, dbDateTimeFormat, isNull } from 'src/utils/utils';

interface HDJItem {
    id: number;
    name: string;
}

const getMinutes: any = (dateTime: string, minutesOnly?: boolean) => {
    const splitedTime = dateTime?.split(' ')?.[1];
    const splittedTime = splitedTime?.split(':');
    const time = {
        hour: splittedTime?.[0],
        minutes: splittedTime?.[1],
    };
    if (minutesOnly) {
        return +time.minutes;
    }

    return +`${time.hour}${time.minutes}`;
};

const finalAttendees = (values: Array<any>) => {
    if (values?.length > 0) {
        return values.filter((item: any) => item !== null && item?.id > 0);
    }
    return values;
};

const manageLocationId = (isVisio: boolean, finalPayload: any, eventPayload: any) => {
    if (!isVisio) {
        finalPayload = { ...finalPayload, locationId: eventPayload.locationId };
    } else {
        delete finalPayload.locationId;
    }
};

const manageEventDate = (finalPayload: any) => {
    return finalPayload;
};

const checkEventFormValues = ({
    eventPayload,
    choosedPatient,
    isWorkshop,
    isSlotDE,
    inCorrectStartTime,
    validatedDate,
    canDivisibleByDuration
}: {
    eventPayload: any;
    choosedPatient: any;
    isWorkshop: boolean;
    isSlotDE: boolean;
    inCorrectStartTime: boolean;
    validatedDate?: boolean;
    canDivisibleByDuration?: boolean
}) => {
    const isInSituAndHasLocation = eventPayload?.isInSitu && eventPayload?.customLocation === 0;
    const slotDuration = eventPayload?.duration ? eventPayload?.duration : '';
    const {isDivisible} = isDivisibleByDuration(eventPayload?.startDate, eventPayload?.endDate, slotDuration);

    const disabled: boolean =
        inCorrectStartTime ||
        validatedDate ||
        eventPayload?.summary?.trim() === '' ||
        (isWorkshop && (eventPayload?.summary?.trim() === '' || eventPayload?.workshopId === -1)) ||
        !eventPayload?.typeId ||
        eventPayload?.typeId === -1 ||
        (!isWorkshop && eventPayload?.attendees?.length === 0) ||
        eventPayload?.consultationTypeId === -1 ||
        eventPayload?.isInSitu === -1 ||
        (eventPayload?.isInSitu === 1 &&
            (eventPayload?.locationId === -1 ||
                isNull(eventPayload?.locationId) ||
                isNull(eventPayload?.customLocation))) ||
        (!isWorkshop && !isSlotDE && eventPayload?.specialityTypeId === 0) ||
        (!isWorkshop && !isSlotDE && isNull(choosedPatient)) ||
        isSlotDE && !canDivisibleByDuration ||
        eventPayload?.isPublic === -1 ||
        isInSituAndHasLocation;

    return disabled;
};

const checkEventEditFormValues = ({ event, eventPayload }: { event: any; eventPayload: any }) => {
    return (
        eventPayload?.typeId === -1 ||
        eventPayload?.summary === '' ||
        eventPayload?.specialityTypeId === -1 ||
        eventPayload?.isPublic === -1 ||
        eventPayload?.specialityTypeId === 0
    );
};

const addFifteenMinutes = (minutes: any) => {
    const currentMinutes = minutes.getMinutes();
    let minuteToUse: number = 0;

    if (currentMinutes <= 15) {
        minuteToUse = 15;
    }
    if (currentMinutes > 15 && currentMinutes <= 30) {
        minuteToUse = 30;
    }
    if (currentMinutes > 30 && currentMinutes <= 45) {
        minuteToUse = 45;
    }
    if (currentMinutes === 0 || currentMinutes === 30) {
        minuteToUse = currentMinutes;
    }

    return minutes.setMinutes(minuteToUse);
};

const minutesOfDay = (m: Date) => {
    return m.getMinutes() + m.getHours() * 60;
};

const isTwoTimeEqual = (start: string, end: string) => {
    const begin = new Date(start);
    const finish = new Date(end);

    return minutesOfDay(begin) === minutesOfDay(finish);
};

const setTimeMinutes = (start: Date, end: Date) => end?.setMinutes(start?.getMinutes() + 30);

const addThirtyMinutesToDroppedTime = (droppedEventHour: { timeStart: Date; timeEnd: Date }) => {
    let endTime: any = null;
    const compareTime = getMinutes(droppedEventHour?.timeStart) === getMinutes(droppedEventHour?.timeEnd);
    // if (compareTime) {
    endTime = setTimeMinutes(new Date(droppedEventHour?.timeStart), new Date(droppedEventHour?.timeEnd));
    // }else{
    //     endTime = droppedEventHour?.timeEnd
    // }

    return new Date(endTime);
};

const checkDefaultAttendees = (event: any, fromDnD?: boolean) => {
    let attendees: any[] =
        event?.professionals?.map((intervenant: any) => ({
            id: intervenant?.attendeeUser?.id,
            type: intervenant?.attendeeType?.id,
        })) ?? [];

    if (fromDnD) {
        attendees = attendees?.filter((attendee: any) => attendee.type !== 2);
    }

    return attendees;
};

const DnDEventRequest = ({
    defaultEventValue,
    event,
    finalPayload,
    modalToggle,
    setCreating,
    setSpecialityId,
    setUpdateList,
    setEventPayload,
    toggleEventModal,
    clearFormData,
}: any) => {
    const successfull = (message: string) => {
        modalToggle();
        toast.success(message);
        setCreating(false);
    };

    const finalAction = () => {
        setCreating(false);
        setSpecialityId?.(0);
        setUpdateList((prev: any) => !prev);
        clearFormData();
        setEventPayload?.(defaultEventValue);
    };

    const CreateNewEvent = () =>
        EventService.createNewEvent(finalPayload)
            .then((response) => {
                if (response) {
                    successfull(EventMessage.success);
                    finalAction();
                    finalPayload = null;
                }
            })
            .catch((error) => {
                let errorMessage = ServerError.dndOverlappedError;
                if (error?.response?.data?.status === 'DATE_OVERLAP') {
                    errorMessage = EventMessage.overlap;
                }
                toast.error(errorMessage);
                setCreating(false);
            });

    const UpdateEvent = () =>
        EventService.updateEventViaDnd(event?.id, finalPayload)
            .then((response) => {
                if (response?.data?.status === 'DATE_OVERLAP') {
                    toast.error(EventMessage.updateOverlap);
                } else {
                    successfull(EventMessage.update);
                    finalAction();
                    finalPayload = null;
                    toggleEventModal?.();
                }
            })
            .catch((error) => {
                toast.error(ServerError.dndOverlappedError);
                setCreating(false);
            });

    return {
        CreateNewEvent,
        UpdateEvent,
    };
};

const setEventPayloadValues = ({ setEventPayload, event, newSpecialityInfos, defaultAttendees, presentiel }: any) => {
    setEventPayload((prev: any) => ({
        ...prev,
        typeId: event?.eventType?.id,
        specialityTypeId:
            newSpecialityInfos?.specialityTypeId > 0 ? newSpecialityInfos?.specialityTypeId : event?.specialityType?.id,
        summary: event?.summary,
        description: event?.description,
        attendees: defaultAttendees,
        consultationTypeId: presentiel,
        isInSitu: presentiel,
        duration: event?.duration
    }));
};

const monthlyDateValue = ({ editEventData, newEventDate, currentDate, createEventFromBtnAction }: any) => {
    const convertToMomentDate = (date: any) => moment(date).format(dbDateFormat);

    if (createEventFromBtnAction) {
        if (newEventDate) return convertToMomentDate(newEventDate);
        return convertToMomentDate(currentDate);
    }
    if (editEventData) return convertToMomentDate(editEventData?.startDate);
};

const manageEventEndingTime = ({
    dateTime,
    eventTypeId,
    duration,
}: {
    dateTime: Date | string | null;
    eventTypeId: number;
    duration?: number;
}) => {
    let timeToAdd: number = 1;
    let timeUnit: 'h' | 'm' = 'h';

    switch (eventTypeId) {
        case 2: //DE
            timeToAdd = 2;
            timeUnit = 'h';
            break;

        default:
            if (duration) {
                timeToAdd = duration;
                timeUnit = 'h';
            } else timeToAdd = 2;
            timeUnit = 'h';
            break;
    }

    let timeValue = null;
    if (timeToAdd?.toString()?.includes('.')) {
        const h = Number(timeToAdd.toString()?.split('.')[0]);
        let m = Number(timeToAdd.toString()?.split('.')[1]);

        if (m.toString().length === 1) m = Number(m + '0');
        timeValue = moment(dateTime).add(h, 'h').add(m, 'm').format(dbDateTimeFormat);
    } else {
        timeValue = moment(dateTime).add(timeToAdd, timeUnit).format(dbDateTimeFormat);
    }

    return timeValue;
};

const timesChecker = ({ startTime, endTime }: { startTime: Date; endTime: Date }) =>
    moment(startTime).format('HH:mm') >= moment(endTime).format('HH:mm');

const optimezedHdjData = (data: { items: HDJItem[] }) => {
    let res: HDJItem[] | [] = [];
    if (data?.items?.length > 0) {
        res =
            data?.items?.map((item: HDJItem) => ({
                id: item.id,
                name: item.name,
            })) ?? [];
    }

    return res;
};

const handleSetHdjAndProgramChoice = ({ setChoiceBetwenHdjAndProgram, event }: any) =>
    setChoiceBetwenHdjAndProgram(() => {
        const hasProgram = event?.program?.id > 0;
        const hasHdj = event?.hdj?.id > 0;
        if (hasProgram) {
            return 2;
        }
        if (hasHdj) {
            return 1;
        }
        return 0;
    });

const checkIfCorrectTime = ({
    eventPayload,
    startTime,
    currentDate,
    newEventDate,
    createEventFromBtnAction,
    eventData,
}: {
    eventPayload: any;
    startTime?: any;
    currentDate?: any;
    newEventDate?: any;
    createEventFromBtnAction?: boolean;
    eventData: any;
}) => {
    const eventDate = !createEventFromBtnAction ? newEventDate : currentDate;
    const res = moment(eventPayload.startDate).format('HH:mm') >= moment(eventPayload.endDate).format('HH:mm');
    const currentDateTime = moment(new Date(eventDate)).format('HH:mm');
    const eventStartTime = moment(new Date(eventPayload?.startDate)).format('HH:mm');
    const resStartTime = eventStartTime < currentDateTime;

    return startTime ? resStartTime : res;
};

const checkIfCorrectStartTime = ({
    eventPayload,
    eventDate,
    createEventFromBtnAction,
}: {
    eventPayload: any;
    createEventFromBtnAction?: boolean;
    eventDate?: any;
}) => {
    const startDateValue = createEventFromBtnAction
        ? eventPayload?.startDate
        : moment(eventPayload?.startDate).format('YYYY-MM-DD HH:mm');
    const startTime = startDateValue?.split(' ')[1];
    const endDateValue = createEventFromBtnAction
        ? eventPayload?.endDate
        : moment(eventPayload?.endDate).format('YYYY-MM-DD HH:mm');
    const endTime = endDateValue?.split(' ')[1];
    const startDate = moment(eventDate ?? eventPayload?.startDate).format('YYYY-MM-DD');
    const concatedStartDateTime = `${startDate} ${startTime}`;
    const concatedEndDateTime = `${startDate} ${endTime}`;

    // Start >= End
    const valideDateTime =
        moment(concatedStartDateTime).format('YYYY-MM-DD HH:mm') <
        moment(concatedEndDateTime).format('YYYY-MM-DD HH:mm');

    // CurrentDateTime
    const valideStartDateTime =
        moment(concatedStartDateTime).format('YYYY-MM-DD HH:mm') > moment().format('YYYY-MM-DD HH:mm');

    const isValide = valideDateTime && valideStartDateTime;

    return isValide;
};

const checkIfValidDate = ({ eventDate }: { eventDate: any; currentDate?: any }) => {
    // EventDate >= Today
    const valideDate = moment(eventDate).format('YYYY-MM-DD') >= moment(new Date()).format('YYYY-MM-DD');

    return valideDate;
};

const calculateDuration = (startDateTime: string, endDateTime: string) => {
    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime);
    const durationInMilliseconds = Math.abs(endDate.getTime() - startDate.getTime());

    const hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

    const padZero = (number: number) => number.toString().padStart(2, '0');
    const formattedDuration = `${padZero(hours)}:${padZero(minutes)}`;

    return formattedDuration;
};

export {
    checkIfValidDate,
    checkIfCorrectStartTime,
    checkIfCorrectTime,
    handleSetHdjAndProgramChoice,
    optimezedHdjData,
    timesChecker,
    manageEventEndingTime,
    monthlyDateValue,
    finalAttendees,
    manageLocationId,
    checkEventFormValues,
    checkEventEditFormValues,
    addFifteenMinutes,
    isTwoTimeEqual,
    setTimeMinutes,
    checkDefaultAttendees,
    DnDEventRequest,
    setEventPayloadValues,
    addThirtyMinutesToDroppedTime,
    manageEventDate,
    getMinutes,
    calculateDuration,
};
