import { Typography, Box } from '@mui/material';
import { FC, useState } from 'react';
import IconFilter from 'src/components/CustomIcon/IconFilter';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import EventFilterContent from './EventFilterContent';
import ProgramContent from './ProgramContent';
import RoomFilterContent from './RoomFilterContent';
import useStyles from './styles';
import DurationContent from './DurationFilterContent/DurationContent';

const FilterContent: FC = () => {
    const classes = useStyles();
    const [selectedProgram, setSelectedProgram] = useState<Array<number>>([]);
    const selectedProgramItems = selectedProgram.filter((id: number) => id !== -1);

    const [selectedEvent, setSelectedEvent] = useState<Array<number>>([]);
    const selectedEventItems = selectedEvent.filter((id: number) => id !== -1);

    const [selectedRoom, setSelectedRoom] = useState<Array<number>>([]);
    const selectedRoomItems = selectedRoom.filter((id: number) => id !== -1);

    const items = [
        {
            id: 1,
            title: 'Filtrer par programme',
            component: (
                <ProgramContent
                    selectedItems={selectedProgramItems}
                    selected={selectedProgram}
                    setSelected={setSelectedProgram}
                />
            ),
            selectedItems: selectedProgramItems?.length,
        },
        {
            id: 2,
            title: "Filtrer par type d'événements",
            component: (
                <EventFilterContent
                    selectedItems={selectedEventItems}
                    selected={selectedEvent}
                    setSelected={setSelectedEvent}
                />
            ),
            selectedItems: selectedEventItems?.length,
        },
        {
            id: 3,
            title: 'Filtrer par lieu',
            component: (
                <RoomFilterContent
                    selectedItems={selectedRoomItems}
                    selected={selectedRoom}
                    setSelected={setSelectedRoom}
                />
            ),
            selectedItems: selectedRoomItems?.length,
        },
        {
            id: 4,
            title: "Filtrer par durée d'évènement",
            component: <DurationContent />,
            selectedItems: -1,
        },
    ];

    return (
        <Box className={classes.container}>
            {items.map((item: any, i: number) => (
                <Box key={item.id} style={{ marginBottom: 10 }}>
                    <Box className={classes.header}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                                height: 50,
                            }}
                        >
                            <IconFilter width={18} height={18} />
                            <Typography
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    fontFamily: baseFontFamily.OpenSansSemiBold,
                                }}
                                fontSize={baseFontSize.mini}
                                color={baseColors.green.primary}
                                className={classes.filterLabel}
                            >
                                {item.title}
                            </Typography>
                        </div>

                        {item?.selectedItems >= 0 && (
                            <p
                                style={{
                                    color: baseColors.white,
                                    padding: '4px 14px 4px 14px',
                                    backgroundColor: baseColors.green.primary,
                                    borderRadius: 5,
                                    fontSize: baseFontSize.mini,
                                    fontFamily: baseFontFamily.OpenSansSemiBold,
                                }}
                            >
                                {item?.selectedItems}
                            </p>
                        )}
                    </Box>

                    <Box
                        sx={{
                            paddingRight: 5,
                            overflow: 'hidden',
                            overflowY: 'scroll',
                            height: 200,
                        }}
                        className={classes.content}
                    >
                        {item.component}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default FilterContent;
