import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import CustomModal from 'src/components/CustomModal';
import { InputField } from 'src/components/InputField/InputField';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import { GoalItem, PostGoalPayload } from 'src/interfaces/interfaces';
import { sxStyles } from '../styles';
import { CustomText } from 'src/components/Text/CustomText';
import { baseFontFamily, baseScreenHeight } from 'src/utils/constants/baseStyles';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { Spacer } from 'src/components/Spacer/Spacer';
import { GoalAxis } from '../GoalForms/GoalAxis';
import { GoalFormFooter } from '../GoalForms/GoalFormFooter';
import { defaultGoalPaylopad, handleChangeGoalName, handleSetGoalPayload } from '../Goals.utils';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import useEducationalDiagnosis from 'src/hooks/useEducationalDiagnosis';
import { Grid } from '@mui/material';
import { CustomCheckBox } from 'src/components/InputField/CustomCheckBox';

type IProps = {
    goal: GoalItem;
    setGoal: Dispatch<SetStateAction<GoalItem>>;
    open: boolean;
    toggleModal: any;
    setJustAddedGoalIds?: Dispatch<SetStateAction<number[]>>;
    customTitle?: string;
    callBack?: Function;
    optionalWorkshopAndArticle?: boolean;
};

export const GoalModal: FC<IProps> = (props) => {
    const { setGoal, open, toggleModal, goal, customTitle, optionalWorkshopAndArticle, setJustAddedGoalIds, callBack } = props;
    const [payload, setPayload] = useState<PostGoalPayload>(defaultGoalPaylopad);
    const [hideDuration, setHideDuration] = useState(false);
    const [isImpactPatient, setIsImpactPatient] = useState(true);
    const [loading, setLoading] = useState(false);
    const clearFormData = () => setPayload(defaultGoalPaylopad);
    const isModification = goal?.id > 0;
    useEffect(() => {
        if (isModification) {
            handleSetGoalPayload({ setPayload, goal });
        }
        goal?.duration === 1 ? setHideDuration(true) : setHideDuration(false);
    }, [goal]);

    const title = customTitle ? customTitle : isModification ? "Modifier l'objectif" : 'Créer nouvel objectif';
    const defaultType = goal?.duration > 0 ? (goal?.duration === 1 ? 2 : 1) : 0;

    const { fetchAllAxis, fetchingAxis, slicedAxis: axis } = useEducationalDiagnosis({} as any);
    const axisData = [{ id: 0, label: "Choisir l'axe", value: '' }, ...axis];
    useEffect(() => {
        fetchAllAxis();
    }, []);

    useEffect(() => {
        fetchingAxis ? setLoading(true) : setLoading(false);
    }, [fetchingAxis]);

    useEffect(() => {
        isModification && setPayload((prev) => ({ ...prev, isImpactPatient: isImpactPatient ? 1 : 0 }));
    }, [isImpactPatient]);

    return (
        <CustomModal
            disableBackdropClick
            title={title}
            open={open}
            toggleModal={toggleModal}
            footer={
                <GoalFormFooter
                    loading={loading}
                    goal={goal}
                    setPayload={setPayload}
                    setGoal={setGoal}
                    payload={payload}
                    toggleModal={toggleModal}
                    setJustAddedGoalIds={setJustAddedGoalIds}
                    callBack={callBack}
                    optionalWorkshopAndArticle={optionalWorkshopAndArticle}
                />
            }
            width={1000}
            height={baseScreenHeight.modalForm}
            clearFormData={clearFormData}
        >
            <Spacer />

            <div
                style={{ ...sxStyles.row, alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}
            >
                <div style={sxStyles.left}>
                    <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} style={{ width: 80 }}>
                        Objectif :
                    </CustomText>

                    <div style={{ width: '100%', marginBottom: -20 }}>
                        <InputField
                            label=""
                            width="100%"
                            value={payload.goal}
                            onChange={(text: any) => handleChangeGoalName({ setPayload, value: text })}
                            placeholder="Intitulé de l'objectif..."
                            validator={isNotBlank}
                        />
                    </div>
                </div>

                <div style={{ ...sxStyles.right }}>
                    <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} style={{ width: 80 }}>
                        Axe :
                    </CustomText>
                    {!fetchingAxis ? (
                        <CustomSelect
                            defaultValue={payload.axisId}
                            onChange={(value: any) => setPayload((old: any) => ({ ...old, axisId: value }))}
                            label=""
                            labelKey="label"
                            items={axisData}
                            idSelect="axe"
                            width="100%"
                        />
                    ) : (
                        <CustomCircleLoader height={25} width={25} />
                    )}
                </div>
            </div>

            <div style={{ paddingRight: '10px' }}>
                <GoalAxis
                    payload={payload}
                    setPayload={setPayload}
                    hideDuration={hideDuration}
                    setLoading={setLoading}
                />
            </div>

            <Spacer />

            {isModification && !loading && (
                <Grid container direction="row" spacing={2}>
                    <Grid item>
                        <CustomCheckBox
                            fontSize={14}
                            onClick={() => setIsImpactPatient((prev) => !prev)}
                            checked={payload?.isImpactPatient === 1}
                            label="Tous les contenus de cet objectif seront accessibles par les patients ayant déjà cet objectif dans son programme en cours."
                        />
                    </Grid>
                    <Grid item style={{ flex: 1 }} />
                </Grid>
            )}
        </CustomModal>
    );
};
