import { Paper } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { InputField } from 'src/components/InputField/InputField';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { ConclusionPayloadType } from 'src/interfaces/types';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import useStyles from './styles';
import useKeyboardHandler from 'src/hooks/useKeyboardHandler';
import { DraftConclusion, OptimizedItem, ProgramGoal, ProgramSkill, SkillItem } from 'src/interfaces/interfaces';
import ConclusionCheckBoxForm from '../ConclusionCheckBoxForm/ConclusionCheckBoxForm';
import {
    GoalWorkshopsArticles,
    ProgramGoals,
    SetDefaultSkillsPayload,
} from '../ConclusionCheckBoxForm/ConclusionCheckBoxForm.utils';
import { uniqByKeyValues } from 'src/utils/utils';
import { SkillModal } from 'src/views/Settings/Skills/SkillModal';
import useCheckUserRole from 'src/hooks/useCheckUserRole';

interface SkillsFormContainerProps {
    draftConclusion: DraftConclusion;
    label: string;
    keyValue: string;
    payload: ConclusionPayloadType;
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
    isGoals?: boolean;
    isWorkshops?: boolean;
    isArticles?: boolean;
    isSkills?: boolean;
    withFooterPadding?: boolean;
    setJustAddedSkills: Dispatch<SetStateAction<Array<ProgramSkill>>>;
    skills: ProgramSkill[];
    isEditMode?: boolean;
    disabledDefaultPrechecked?: boolean;
    setDisabledDefaultPrechecked?: Dispatch<SetStateAction<boolean>>;
    updateGoalIdsInSkills?: boolean;
    setUpdateGoalIdsInSkills?: Dispatch<SetStateAction<boolean>>;
}

export type ConclusionRowItem = {
    id: number;
    value: string;
};

const SkillsFormContainer: FC<SkillsFormContainerProps> = ({
    draftConclusion,
    label,
    keyValue,
    setPayload,
    payload,
    isGoals,
    isWorkshops,
    isArticles,
    isSkills,
    withFooterPadding,
    setJustAddedSkills,
    skills,
    isEditMode,
    disabledDefaultPrechecked,
    setDisabledDefaultPrechecked,
    updateGoalIdsInSkills,
    setUpdateGoalIdsInSkills,
}) => {
    const classes = useStyles();

    const { roles } = useCheckUserRole();
    const isCoordinator = roles?.isCoordinator ?? false;
    const [patientGoals, setPatientGoals] = useState<Array<ProgramGoal>>([]);
    const [newSkillLabel, setNewSkillLabel] = useState<string>('');
    const disableValidBtn = newSkillLabel?.trim()?.length === 0;
    const [updateList, setUpdateList] = useState(false);
    const [modalFormOpen, setModalFormOpen] = useState(false);
    const toggleModal = () => setModalFormOpen((prev: boolean) => !prev);
    const [skill, setSkill] = useState<SkillItem>({} as SkillItem);

    useKeyboardHandler({ handler: !disableValidBtn ? toggleModal : undefined });

    useEffect(() => {
        // if (disabledDefaultPrechecked === false && skills?.length > 0) {
        SetDefaultSkillsPayload({ skills, setPayload });
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skills, disabledDefaultPrechecked]);

    useEffect(() => {
        setPatientGoals(ProgramGoals({ payload, skills }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skills, payload?.skills]);

    const patientArtcilesWorkshops: OptimizedItem[] | undefined = useMemo(() => {
        return GoalWorkshopsArticles({ patientGoals, isArticles });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientGoals, isArticles]);

    const dataItems = () => {
        if (isGoals) {
            return uniqByKeyValues({ items: patientGoals, key: 'id' });
        }

        if ((isWorkshops || isArticles) && !draftConclusion?.id) {
            return uniqByKeyValues({ items: patientArtcilesWorkshops, key: 'id' });
        }

        if (draftConclusion?.id) {
            if (isWorkshops) {
                const workshopFromDraft =
                    draftConclusion?.conclusionWorkshops?.map((workshopItem: any) => workshopItem?.workshop) ?? [];
                return uniqByKeyValues({ items: workshopFromDraft, key: 'id' });
            }
            if (isArticles) {
                const articleFromDraft =
                    draftConclusion?.conclusionVideos?.map((articleItem: any) => articleItem?.article) ?? [];
                return uniqByKeyValues({ items: articleFromDraft, key: 'id' });
            }
        }

        return skills;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    return (
        <Paper
            elevation={3}
            style={{
                padding: '10px 30px 0 30px',
                margin: '5px 10px',
            }}
        >
            <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} style={{ fontSize: baseFontSize.sm }}>
                {label}
            </CustomText>

            <Spacer height={10} />

            <ConclusionCheckBoxForm
                updateGoalIdsInSkills={updateGoalIdsInSkills}
                setUpdateGoalIdsInSkills={setUpdateGoalIdsInSkills}
                setDisabledDefaultPrechecked={setDisabledDefaultPrechecked}
                skills={skills}
                isGoals={isGoals}
                isWorkshops={isWorkshops}
                isArticles={isArticles}
                dataItems={dataItems()}
                keyValue={keyValue}
                payload={payload}
                setPayload={setPayload}
                isEditMode={isEditMode}
                draftConclusion={draftConclusion}
            />

            {isSkills && (
                <div className={classes.row}>
                    <InputField
                        label=""
                        placeholder="Écrire un texte ..."
                        height={50}
                        width="100%"
                        onChange={(text: string) => setNewSkillLabel(text)}
                        value={newSkillLabel}
                        disabled={isEditMode}
                    />

                    <div
                        style={{
                            cursor: disableValidBtn ? 'not-allowed' : 'pointer',
                            opacity: disableValidBtn ? 0.3 : undefined,
                        }}
                        onClick={disableValidBtn ? undefined : toggleModal}
                        className={classes.valideBtn}
                    >
                        <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} color={baseColors.white}>
                            Ajouter
                        </CustomText>
                    </div>
                </div>
            )}

            <SkillModal
                defaultSkillName={newSkillLabel}
                open={modalFormOpen}
                skill={skill}
                setSkill={setSkill}
                toggleModal={toggleModal}
                setUpdateList={setUpdateList}
                setJustAddedSkills={setJustAddedSkills}
                setNewSkillLabel={setNewSkillLabel}
            />

            {withFooterPadding && <Spacer />}
        </Paper>
    );
};

export default SkillsFormContainer;
