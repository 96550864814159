import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Grid, InputBase, MenuItem, Select, styled } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import CustomCircleLoader from '../CustomLoader/CustomCircleLoader';
import { CustomText } from '../Text/CustomText';

interface CustomSelectProps {
    items: Array<any>;
    label: string;
    title?: string;
    onChange?: Function;
    labelKey?: any;
    disabled?: boolean;
    rerender?: boolean;
    direction?: string;
    isPlanning?: boolean;
    defaultValue?: number | undefined | null;
    width?: string | number;
    maxWidth?: string | number;
    marginLeft?: number;
    fontSize?: number | string;
    consultationType?: boolean;
    programId?: number;
    idSelect?: string;
    onItemClick?: Function;
    height?: number;
    isLoading?: boolean;
    dontUseBoldFont?: boolean;
    required?: boolean;
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    borderRadius: 10,
    'label + &': {
        marginTop: theme.spacing(3),
        borderRadius: 10,
    },
    '& .MuiInputBase-input': {
        borderRadius: 10,
        position: 'relative',
        color: baseColors.green.primary,
        // backgroundColor: baseColors.green.light,
        border: '0px solid ' + baseColors.green.light,
        fontSize: 16,
        padding: '12px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:after ': {
            borderRadius: 10,
        },
        '&:active ': {
            borderRadius: 10,
        },
    },
}));

export const CustomSelect: FC<CustomSelectProps> = (props) => {
    const {
        disabled,
        items,
        title,
        onChange,
        labelKey,
        isPlanning,
        defaultValue,
        width,
        maxWidth,
        marginLeft,
        fontSize,
        consultationType,
        programId,
        idSelect,
        height,
        isLoading,
        dontUseBoldFont,
        required,
    } = props;
    const coordinatorName = items.find((item) => item.id === defaultValue);
    const reorderedItems = items;
    const defaultSelectedValue =
        coordinatorName?.id > 0 || (consultationType && coordinatorName?.id >= 0)
            ? coordinatorName.id
            : reorderedItems.length > 0
            ? reorderedItems[0].id
            : null;
    const [open, setOpen] = useState<boolean>(false);
    const [value, setValue] = useState(defaultSelectedValue);
    const [isValid, setIsValid] = useState(true);
    const handleChange = (event: any) => {
        setValue(event.target.value);
        onChange?.(event.target.value);
    };

    useEffect(() => {
        reorderedItems?.length > 0 &&
            reorderedItems.filter((item) => item.id === value).length === 0 &&
            setValue(reorderedItems[0].id);
    }, [items, value, reorderedItems]);

    useEffect(() => {
        if (reorderedItems.filter((item: any) => item.id === defaultValue).length > 0) setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        if (required && value !== 0) {
            setIsValid(true);
        } else if (required && value === 0) {
            setIsValid(false);
        }
    }, [value]);

    const toggleSelect = () => setOpen(!open);

    if (isLoading) {
        return <CustomCircleLoader height={25} width={25} />;
    }

    return (
        <Grid
            container
            alignItems={isPlanning ? undefined : 'center'}
            direction={isPlanning ? 'column' : 'row'}
            justifyContent={'space-between'}
            style={{ marginTop: title ? 20 : 0 }}
        >
            {title && title.length > 0 && (
                <Grid item style={{ marginBottom: 20 }}>
                    <CustomText
                        fontSize={isPlanning ? 13 : fontSize ? fontSize : undefined}
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                    >
                        {title}
                    </CustomText>
                </Grid>
            )}
            <Grid item style={{ marginBottom: title ? 20 : 0, marginLeft: marginLeft ?? undefined, width: '100%' }}>
                <Select
                    defaultValue={defaultSelectedValue}
                    open={open}
                    disabled={disabled ?? false}
                    onOpen={toggleSelect}
                    onClose={toggleSelect}
                    style={{
                        background: baseColors.green.light,
                        borderRadius: 10,
                        width: width ?? undefined,
                        height: height ?? undefined,
                        border: isValid ? 'none' : `1px solid ${baseColors.orange.primary}`,
                    }}
                    input={<BootstrapInput />}
                    IconComponent={() =>
                        !open ? (
                            <KeyboardArrowDown
                                onClick={!disabled ? toggleSelect : undefined}
                                style={{ marginRight: 5, cursor: 'pointer' }}
                                htmlColor={disabled ? baseColors.grey : baseColors.green.primary}
                            />
                        ) : (
                            <KeyboardArrowUp
                                onClick={toggleSelect}
                                style={{ marginRight: 5, cursor: 'pointer' }}
                                htmlColor={baseColors.green.primary}
                            />
                        )
                    }
                    value={programId ?? value}
                    placeholder=""
                    onChange={handleChange}
                    inputProps={{ id: idSelect }}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 'calc(100vh - 300px)' } } }}
                >
                    {reorderedItems.map((item: any, key: number) => {
                        return (
                            <MenuItem
                                key={key}
                                style={{ 
                                    width: maxWidth ? maxWidth : '100%',
                                    whiteSpace: "break-spaces",
                                }}
                                color={baseColors.green.primary}
                                value={item.id}
                            >
                                <CustomText
                                    fontFamily={
                                        dontUseBoldFont
                                            ? baseFontFamily.OpenSansRegular
                                            : baseFontFamily.OpenSansSemiBold
                                    }
                                    color={
                                        item.id === 0 || item.id === -1 ? baseColors.green.primary : baseColors.black
                                    }
                                    fontSize={fontSize ?? baseFontSize.mini}
                                    style={{
                                        wordWrap: "break-word",
                                        overflowWrap: "break-word"
                                    }}
                                >
                                    {labelKey ? item[labelKey] : item.label}
                                </CustomText>
                            </MenuItem>
                        );
                    })}
                </Select>
            </Grid>
        </Grid>
    );
};
