import moment from 'moment';
import { uniqBy, chain } from 'lodash';
import { CATEGORIES, URER_ROLES } from './constants/enums';
import { OptimizedItem } from 'src/interfaces/interfaces';
export const dbDateFormat = 'YYYY-MM-DD';
export const dbDateTimeFormat = 'YYYY-MM-DD HH:mm';
export const dbDateTimeFormatLocal = 'DD/MM/YYYY HH:mm';
export const localDateFormat = 'DD/MM/YYYY';
export const fullCalendarTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
export const clientDateTimeFormat = 'DD  MMMM YYYY à HH:mm';

const transformDbDate = (date: Date) => moment(date).format(dbDateFormat);
const transformDbDateTime = (date: Date) => moment(date).format(dbDateTimeFormat);
const transformDbDateTimeLocal = (date: Date) => moment(date).format(dbDateTimeFormatLocal);

const addZeroBeforeNumber = (value: number) => (value < 10 ? `0${value}` : value);

const checkIncludedValue = (start: number, end: number, value: number) => Boolean(value >= start && value <= end);

const transpose = (matrix: any) => {
    let [row] = matrix;
    return row.map((value: any, column: any) => matrix.map((row: any) => row[column]));
};

const checkIfEmpty = (itemCount: number) => {
    return itemCount === 0;
};

const isEven = (n: number) => {
    return n % 2 === 0;
};

const isOdd = (n: number) => {
    return Math.abs(n % 2) === 1;
};

const formatDateFormat = (date: Date) => {
    const covertedDate = new Date(date);
    if (covertedDate) {
        return `${covertedDate.getFullYear()}-${addZeroBeforeNumber(covertedDate.getMonth() + 1)}-${addZeroBeforeNumber(
            covertedDate.getDate(),
        )}`;
    }
    return '';
};

const timeWithoutTimeZone = (date: string | number, withSeparator?: boolean) => {
    if (withSeparator) {
        return moment(date).utcOffset(date).format('HH:mm');
    }
    return moment(date).utcOffset(date).format('HHmm');
};

const dateWithoutTimeZone = (date: string | number) => {
    const dataValue = date ?? new Date().toISOString();
    return moment(dataValue).utcOffset(dataValue).format('YYYY-MM-DD  HH:mm');
};

const dateTimeWithoutTimeZone = (date: string | number) => {
    const dataValue = date ?? new Date().toISOString();
    return moment(dataValue).utcOffset(dataValue).format('dddd  MMMM YYYY  HH:mm');
};

const fullName = (firstName: string | null, lastName: string | null) => {
    if (!firstName || !lastName) {
        return '';
    }
    if (firstName && !lastName) {
        return firstName;
    }
    if (!firstName && lastName) {
        return lastName;
    }
    if (firstName && lastName) {
        return `${lastName} ${firstName}`;
    }

    return '';
};

const eventDateTimeFormat = (date: Date, time: Date, isAgenda?: boolean) => {
    const dateValue = date && formatDateFormat(date);
    const timeValue =
        time &&
        moment(time)
            .utcOffset(time.toString())
            .format(isAgenda ? 'HH:mm' : 'HH:mm:ss');
    const finalResult = `${dateValue} ${timeValue}`;

    return finalResult;
};

const frenchDateFormat = (date: Date) => {
    let newDate = date.toLocaleDateString('fr-FR', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
    });
    newDate = newDate.charAt(0).toUpperCase() + newDate.slice(1);

    return newDate;
};

const currentUrlOrigin = window.location.origin.toString();

const daysCounts = (screenWidth: number) => {
    if (screenWidth <= 1250) {
        return 3;
    }

    if (screenWidth > 1250 && screenWidth <= 1485) {
        return 4;
    }

    return 5;
};

const transformDayNameToFrench = (date: string) => {
    const eventDate = moment(date).format('dddd Do MMMM YYYY');
    return eventDate;
};

const differenceBetweenTwoDates = (d1: Date, d2: Date) => {
    const start = moment(d1);
    const end = moment(d2);
    const result = end.diff(start, 'days');

    return result + 1;
};

const reversedDateFormat = (date: string, separator?: string) => {
    const dateAsArray = date.split('/').map((d) => d.trim());

    return dateAsArray.reverse().join('-').trim();
};

const checkIfEditableWorkshop = (workshopDate: string, currentDate?: Date) => {
    const workShopDate = reversedDateFormat(workshopDate.split('-')[0]);
    const editable = differenceBetweenTwoDates(currentDate ?? new Date(), new Date(workShopDate)) > 30;

    return editable;
};

const separateHoursAndMinutes = (times: string) => {
    const splitedTime = times.split(':');
    const hours = Number(splitedTime?.[0]);
    const minutes = Number(splitedTime?.[1]);

    return {
        hours,
        minutes,
    };
};

function randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}

const transformTimeToLocaleTimeString = (date: Date) => date.toLocaleTimeString('fr-FR');

const transformDateToLocaleDate = (date: string) => {
    const dateAsArray = date.split('-').map((d) => d.trim());
    return dateAsArray.reverse().join('/').trim();
};

const specialCharToUnderscore = (str: string) => str.replace(/[^a-zA-Z0-9]/g, '_');

const getMonthAndYear = (date: string) => moment(date).utcOffset(date).format('DD-MM');

const monthAndYear = (month: number, year: number) => {
    switch (month) {
        case 2:
            return `Fevrier ${year}`;

        case 3:
            return `Mars ${year}`;

        case 4:
            return `Avril ${year}`;

        case 5:
            return `Mai ${year}`;

        case 6:
            return `Juin ${year}`;

        case 7:
            return `Juillet ${year}`;

        case 8:
            return `Août ${year}`;

        case 9:
            return `Septembre ${year}`;

        case 10:
            return `Octobre ${year}`;

        case 11:
            return `Novembre ${year}`;

        case 12:
            return `Décembre ${year}`;

        default:
            return `Janvier ${year}`;
    }
};

const getDayAndMonth = (date: string) => moment(new Date(date)).format('Do MMM');

const createEventDate = (payloadDate: string, newEventDate: Date) => {
    const getPayloadHours = new Date(payloadDate).getHours();
    const getPayloadMinutes = new Date(payloadDate).getMinutes();
    const dateReformated = `${transformDbDate(newEventDate)} ${addZeroBeforeNumber(
        getPayloadHours,
    )}:${addZeroBeforeNumber(getPayloadMinutes)}`;
    return dateReformated;
};

const convertToZeroIfArrayEmpty = (value: any) => {
    if (value instanceof Array || undefined) {
        return 0;
    }
    return value;
};

const notificationDate = (date: string) => {
    const splitedData = date.split('T');
    const splitedTime = splitedData[1]?.split(':');
    const dateValue = splitedData[0];
    const timeValue = `${splitedTime[0]}h${splitedTime[1]}`;
    const res = `${dateValue.toString()} à ${timeValue}`;

    return res;
};

function convertToTimeZoneDate(date: string) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const addTimeZoneToDate = moment.tz(date, timezone).format();
    return addTimeZoneToDate;
}

function convertDateToClientTimezone(dateString: string, sourceTimezone:string = 'Europe/Paris') {
    const sourceDate = moment.tz(dateString, "YYYY-MM-DD HH:mm:ss", sourceTimezone);
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const clientDate = sourceDate.clone().tz(clientTimezone);
    return clientDate.format('YYYY-MM-DD HH:mm:ss');
}

const remoVeAdditionalTimeZone = (dateTime: string) => {
    const splitedTime = dateTime?.split(':');
    const dateValue = `${splitedTime?.[0]}:${splitedTime?.[1]}:00`;
    return dateValue;
};

const combineStartAndEndTime = (start: string, end: string) => {
    const startTime = moment(start).format('HH:mm');
    const endTime = moment(end).format('HH:mm');
    const res = `${startTime} à ${endTime}`;
    return res;
};

const searchElementByName = (array: any[], searchkey: string, keyName?: string) => {
    const res = array.filter((obj) => JSON.stringify(obj).toLowerCase().includes(searchkey.toLowerCase()));
    if (keyName) {
        const withSpecificKey = array.filter((obj) => obj[keyName]?.toLowerCase()?.includes(searchkey?.toLowerCase()));
        return withSpecificKey ?? [];
    }

    return res ?? [];
};

function SearchElementByBirthDate(array: any[], searchDate: Date) {
    const res = array?.filter((obj) => {
        const equal = EqualDates(obj?.birthDate, searchDate);
        return equal;
    });

    return res ?? [];
}

const monthlyTimeWithoutTimeZone = (date: any) => {
    const dataValue = date ?? new Date().toISOString();
    return moment(dataValue).utcOffset(dataValue).format('HH:mm');
};

const stringDateToZeroMinute = (date: string) => {
    const dateString = date;
    const [datePart, timeAndTimeZonePart] = dateString.split('T');
    const [timePart, timeZonePart] = timeAndTimeZonePart.split('+');
    const [hour, minute, second] = timePart.split(':');
    const newDateString = `${datePart}T${hour}:00:00+${timeZonePart}`;
    return newDateString;
}

const formatDateDiag = (dates: string) => {
    const formated = moment(dates).format('dddd DD  MMMM YYYY à HH:mm');
    return formated;
};

const getElapsedTimeFromDateToNow = (date: string) => {
    const startDate = moment(date, 'YYYY-MM-DD hh:mm');
    const today = moment(new Date());
    return moment.duration(today.diff(startDate));
};

const checkIfCorrectProgrammeDateTime = (start: any, end: any) => {
    let isEndTimeLower = moment(start).format(fullCalendarTimeFormat) >= moment(end).format(fullCalendarTimeFormat);
    return isEndTimeLower;
};

const addThirtyMinutes = () => {
    let now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    now = new Date(now);
    return now;
};

const userStoryDateTime = (date: string) => {
    const splitedDate = date.split(':');
    const dateTime1 = splitedDate[0];
    const dateTime2 = splitedDate[1];
    const res = `${dateTime1}:${dateTime2}`;
    return res ?? '---';
};

const todayDate = () => moment(new Date()).format(dbDateFormat);

const userRole = (role: string) => {
    switch (role) {
        case URER_ROLES.COORDINATOR:
            return 'Coordinateur';

        case URER_ROLES.ADMIN:
            return 'Administrateur';

        case URER_ROLES.DOCTOR:
            return 'Soignant';

        case URER_ROLES.PATIENT:
            return 'Patient';

        default:
            return 'Utilisateur';
    }
};

const userCategory = (category: string) => {
    switch (category) {
        case CATEGORIES.PROFESSIONAL:
            return 'Professionnel';

        case CATEGORIES.PATIENT:
            return 'Patient';

        case CATEGORIES.ASSISTANT:
            return 'Assistant';

        default:
            return 'Autre';
    }
};

const getProfessionalDoctorOnly = (data: any) => {
    const doctors = data?.filter(
        (item: any) => item.professional.roles.includes(URER_ROLES.DOCTOR) && item.isAcceptedByPatient === 1,
    );
    return doctors ?? [];
};

const isIntervenant = (doctor: any, fromHooks?: boolean) => {
    const hasEtpAndIsInternal = Boolean(doctor.hasEtp) && Boolean(doctor.isExternal === 0);
    const res = fromHooks ? hasEtpAndIsInternal : doctor?.roles?.includes(URER_ROLES.DOCTOR) && hasEtpAndIsInternal;

    return res;
};

const isHealthProfessional = (doctor: any) => {
    const res = doctor;
    return res;
};

const uniqArrayNumberValues = (items: any) => {
    const ids = new Set(items);
    return Array.from(ids) ?? [];
};

const uniqByKeyValues = ({ items, key }: { items: any; key?: string }) => {
    const values = uniqBy(items, key || 'id');
    return values ?? [];
};

const sortByIds = (data: any[]) => data?.sort((a: any, b: any) => b.id - a.id);

const ucfirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

function pathologyIds(pathologies: OptimizedItem[]) {
    const ids = pathologies.map((path) => path.id);
    return uniqArrayNumberValues(ids) ?? [];
}

function GroupeByKeyValue({ key, data }: { data: any[]; key: string }) {
    const grouped = chain(data)
        .groupBy(key ?? 'id')
        .map((value, key) => ({ key: key, items: value }))
        .value();

    return grouped ?? [];
}

function isNull(value: any) {
    return value === null;
}

function isFalse(value: any) {
    return value === false;
}

function EqualDates(d1: Date | string, d2: Date | string) {
    let date1 = new Date(d1).getTime();
    let date2 = new Date(d2).getTime();

    if (date1 < date2) {
        return false;
    } else if (date1 > date2) {
        return false;
    } else {
        return true;
    }
}

function RandomNumber() {
    const crypto = window.crypto;
    let array = new Uint32Array(1);
    return crypto.getRandomValues(array);
}

function intersectArray(array1: Array<number>,array2: Array<number>){
    return array1.filter(element => array2.includes(element));
}


export {
    isFalse,
    RandomNumber,
    EqualDates,
    SearchElementByBirthDate,
    GroupeByKeyValue,
    pathologyIds,
    sortByIds,
    uniqByKeyValues,
    uniqArrayNumberValues,
    isHealthProfessional,
    isIntervenant,
    getProfessionalDoctorOnly,
    userCategory,
    userRole,
    todayDate,
    userStoryDateTime,
    addThirtyMinutes,
    checkIfCorrectProgrammeDateTime,
    formatDateDiag,
    notificationDate,
    getElapsedTimeFromDateToNow,
    monthlyTimeWithoutTimeZone,
    convertToZeroIfArrayEmpty,
    convertToTimeZoneDate,
    remoVeAdditionalTimeZone,
    combineStartAndEndTime,
    searchElementByName,
    createEventDate,
    currentUrlOrigin,
    isNull,
    checkIncludedValue,
    transpose,
    checkIfEmpty,
    isEven,
    formatDateFormat,
    isOdd,
    timeWithoutTimeZone,
    dateWithoutTimeZone,
    fullName,
    dateTimeWithoutTimeZone,
    eventDateTimeFormat,
    frenchDateFormat,
    daysCounts,
    transformDayNameToFrench,
    checkIfEditableWorkshop,
    separateHoursAndMinutes,
    transformTimeToLocaleTimeString,
    randomIntFromInterval,
    differenceBetweenTwoDates,
    reversedDateFormat,
    transformDateToLocaleDate,
    specialCharToUnderscore,
    getMonthAndYear,
    addZeroBeforeNumber,
    monthAndYear,
    getDayAndMonth,
    transformDbDate,
    transformDbDateTime,
    transformDbDateTimeLocal,
    ucfirst,
    intersectArray,
    stringDateToZeroMinute,
    convertDateToClientTimezone
};
